import { useState } from 'react';

const useFileUpload = () => {
  const [isFileUploading, setIsFileUploading] = useState(false);

  const uploadFile = async (url: string, payload: File | string) => {
    try {
      setIsFileUploading(true);
      const response = await fetch(url, {
        method: 'PUT',
        credentials: 'omit',
        mode: 'cors',
        body: payload,
      });
      setIsFileUploading(false);
      return !!response && response.status === 200;
    } catch (error) {
      setIsFileUploading(false);
      return false;
    }
  };

  return {
    isFileUploading,
    uploadFile,
  };
};

export default useFileUpload;
