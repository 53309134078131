import { TagTypes } from '@constants/common';
import {
  CreateRole,
  CreateRoleInput,
  GlobalEntity,
  ProjectEntity,
  Role,
  RolePermissionsSummary,
  UpdateRolePermissionResponse,
  UpdateRolePermissionsInput,
} from '@customTypes/members';

import api from '@services/api';

import {
  createGlobalRoleMutation,
  createProjectRoleMutation,
  deleteRoleMutation,
  getAllGlobalRolesQuery,
  getAllProjectRolesQuery,
  getGlobalEntitiesQuery,
  getProjectEntitiesQuery,
  getRolePermissionsSummaryQuery,
  updateRolePermissionsMutation,
} from './queries';

const projectsApis = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllProjectRoles: builder.query<Role[], void>({
      query: () => ({
        document: getAllProjectRolesQuery,
      }),
      transformResponse: (response: { getAllProjectRoles: Role[] }) =>
        response?.getAllProjectRoles,
    }),
    getAllGlobalRoles: builder.query<Role[], void>({
      query: () => ({
        document: getAllGlobalRolesQuery,
      }),
      transformResponse: (response: { getAllGlobalRoles: Role[] }) =>
        response?.getAllGlobalRoles,
    }),
    getRolePermissionsSummary: builder.query<RolePermissionsSummary[], string>({
      query: (roleId) => ({
        document: getRolePermissionsSummaryQuery,
        variables: {
          roleId,
        },
      }),
      transformResponse: (response: {
        getRolePermissionsSummary: RolePermissionsSummary[];
      }) => response?.getRolePermissionsSummary,
    }),
    getProjectEntities: builder.query<ProjectEntity[], void>({
      query: (roleId) => ({
        document: getProjectEntitiesQuery,
        variables: {
          roleId,
        },
      }),
      transformResponse: (response: { getProjectEntities: ProjectEntity[] }) =>
        response?.getProjectEntities,
    }),
    createProjectRole: builder.mutation<CreateRole, CreateRoleInput>({
      query: (createRoleInput) => ({
        document: createProjectRoleMutation,
        variables: {
          createRoleInput,
        },
      }),
      transformResponse: (response: { createProjectRole: CreateRole }) =>
        response?.createProjectRole,
      extraOptions: {
        successMessageKey: 'members/roles:addRoleSuccess',
      },
    }),
    createGlobalRole: builder.mutation<CreateRole, CreateRoleInput>({
      query: (createRoleInput) => ({
        document: createGlobalRoleMutation,
        variables: {
          createRoleInput,
        },
      }),
      transformResponse: (response: { createGlobalRole: CreateRole }) =>
        response?.createGlobalRole,
      extraOptions: {
        successMessageKey: 'members/roles:addGlobalRoleSuccess',
      },
    }),
    getGlobalEntities: builder.query<GlobalEntity[], void>({
      query: (roleId) => ({
        document: getGlobalEntitiesQuery,
        variables: {
          roleId,
        },
      }),
      transformResponse: (response: { getGlobalEntities: GlobalEntity[] }) =>
        response?.getGlobalEntities,
    }),
    updateRolePermission: builder.mutation<
      Partial<RolePermissionsSummary>[],
      UpdateRolePermissionsInput
    >({
      query: (variables) => ({
        document: updateRolePermissionsMutation,
        variables,
      }),
      transformResponse: (response: UpdateRolePermissionResponse) =>
        response?.updateRolePermissions,
    }),
    deleteRole: builder.mutation<boolean, { id: string }>({
      query: (variables) => ({
        document: deleteRoleMutation,
        variables,
      }),
      transformResponse: (response: { deleteRole: boolean }) =>
        response?.deleteRole,
    }),
  }),
});

export const {
  useGetAllProjectRolesQuery,
  useLazyGetRolePermissionsSummaryQuery,
  useCreateProjectRoleMutation,
  useUpdateRolePermissionMutation,
  useGetProjectEntitiesQuery,
  useGetGlobalEntitiesQuery,
  useGetAllGlobalRolesQuery,
  useCreateGlobalRoleMutation,
  useDeleteRoleMutation,
} = projectsApis;
