import { ReactNode } from 'react';
import { Trigger, Root, Content, List } from '@radix-ui/react-tabs';

interface TabData {
  title: string;
  value: string;
  content: ReactNode;
}

interface TabProps {
  tabs: TabData[];
  selectedTab: string;
  onSelectedTabChange: (selectedTab: string) => void;
}

const Tabs = ({ tabs, selectedTab, onSelectedTabChange }: TabProps) => (
  <Root
    onValueChange={onSelectedTabChange}
    className='w-full'
    value={selectedTab}
  >
    <List className='flex divide-x'>
      {tabs.map(({ title, value }) => (
        <Trigger
          key={value}
          value={value}
          className={`flex-1 font-medium p-2.5 border-b outline-none ${
            value === selectedTab
              ? 'text-accent-primary bg-light-purple border-b-accent-primary'
              : 'text-blue-magenta-faded border-grey-border hover:bg-light-purple/60'
          }`}
        >
          {title}
        </Trigger>
      ))}
    </List>
    {tabs.map(({ value, content }) => (
      <Content key={value} value={value}>
        {content}
      </Content>
    ))}
  </Root>
);

export default Tabs;
