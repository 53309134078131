import { Fragment } from 'react';
import colors from '@styles/colors';
import { useI18n } from '@hooks';
import Tooltip from '../tooltip/Tooltip';

type ProgressbarProps = {
  totalPercentage: number;
  progress: Array<{
    percentage: number;
    color: string;
    tooltipMessage?: string;
  }>;
};

const BaseProgressbar = ({ progress, totalPercentage }: ProgressbarProps) => (
  <div className='flex overflow-visible flex-row my-0 mx-auto w-full h-[18px] whitespace-nowrap bg-light-gray rounded'>
    {progress.map((item, index) => (
      <Fragment key={`${item.color}-${item.percentage}`}>
        <Tooltip
          content={item.tooltipMessage || ''}
          child={
            <div
              className={
                totalPercentage === 100 && index === progress.length - 1
                  ? 'relative rounded-r first:rounded-l'
                  : 'relative first:rounded-l'
              }
              style={{
                height: '100%',
                width: `${item.percentage}%`,
                backgroundColor: item.color,
              }}
            />
          }
        />
      </Fragment>
    ))}
  </div>
);
type DefaultProgressbarProps = {
  progress: {
    untested?: number;
    inprogress?: number;
    passed?: number;
    failed?: number;
  };
  count: {
    untested?: number;
    inprogress?: number;
    passed?: number;
    failed?: number;
  };
};

export const DefaultProgressbar = ({
  progress,
  count,
}: DefaultProgressbarProps) => {
  const { t } = useI18n(['common']);
  const progressArray = [];
  if (progress.untested) {
    progressArray.push({
      color: colors['dark-gray'],
      percentage: progress.untested,
      tooltipMessage: t('progressBarMessage.untestedCount', {
        count: count.untested,
      }),
    });
  }
  if (progress.inprogress) {
    progressArray.push({
      color: colors['dark-yellow'],
      percentage: progress.inprogress,
      tooltipMessage: t('progressBarMessage.retest', {
        count: count.inprogress,
      }),
    });
  }
  if (progress.passed) {
    progressArray.push({
      color: colors['light-green'],
      percentage: progress.passed,
      tooltipMessage: t('progressBarMessage.passed', { count: count.passed }),
    });
  }
  if (progress.failed) {
    progressArray.push({
      color: colors['dark-peach'],
      percentage: progress.failed,
      tooltipMessage: t('progressBarMessage.failed', { count: count.failed }),
    });
  }

  const totalPercentage = Math.round(
    Object.values(progress).reduce((sum, percentage) => sum + percentage)
  );

  return (
    <BaseProgressbar
      progress={progressArray}
      totalPercentage={totalPercentage}
    />
  );
};

export default DefaultProgressbar;

type MilestoneProgressBarProps = {
  progress: {
    inProgressCount?: number;
    notStartedCount?: number;
    completedCount?: number;
  };
  count: {
    inProgressCount?: number;
    notStartedCount?: number;
    completedCount?: number;
  };
};

export const MilestoneProgressBar = ({
  progress,
  count,
}: MilestoneProgressBarProps) => {
  const { t } = useI18n(['common']);
  const progressArray = [];
  if (progress.notStartedCount) {
    progressArray.push({
      color: colors['dark-gray'],
      percentage: progress.notStartedCount,
      tooltipMessage: t('progressBarMessage.notStartedCount', {
        count: count.notStartedCount,
      }),
    });
  }
  if (progress.inProgressCount) {
    progressArray.push({
      color: colors['dark-yellow'],
      percentage: progress.inProgressCount,
      tooltipMessage: t('progressBarMessage.inProgressCount', {
        count: count.inProgressCount,
      }),
    });
  }
  if (progress.completedCount) {
    progressArray.push({
      color: colors['light-green'],
      percentage: progress.completedCount,
      tooltipMessage: t('progressBarMessage.completedCount', {
        count: count.completedCount,
      }),
    });
  }

  const totalPercentage = Math.round(
    Object.values(progress).reduce((sum, percentage) => sum + percentage)
  );

  return (
    <BaseProgressbar
      progress={progressArray}
      totalPercentage={totalPercentage}
    />
  );
};
