import { createApi, BaseQueryFn } from '@reduxjs/toolkit/dist/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { QueryReturnValue } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes';
import { DocumentNode } from 'graphql';
import { ClientError, GraphQLClient } from 'graphql-request';
import { GraphQLError } from 'graphql-request/dist/types';

import { logoutUser } from '@utils/authentication';
import { NotificationTypes } from '@constants/components';
import { showNotifier } from '@containers/notification/reducer';
import { translate } from '@utils/locale';
import { TagTypes } from '@constants/common';
import { ErrorCode } from '@customTypes/common';

interface ExtraOptions {
  failureMessageKey?: string;
  successMessageKey?: string;
  failureType?: NotificationTypes;
  successType?: NotificationTypes;
}

interface GraphQLArgs {
  document: string | DocumentNode;
  variables?: unknown;
}

type CustomFetchBaseQuery = BaseQueryFn<
  GraphQLArgs,
  unknown,
  unknown,
  ExtraOptions & Partial<Pick<ClientError, 'request' | 'response'>>,
  object
>;

const client = new GraphQLClient(
  `${process.env.REACT_APP_API_ENDPOINT}/graphql`,
  {
    credentials: 'include',
    mode: 'cors',
  }
);

const baseQuery = graphqlRequestBaseQuery({
  client,
  customErrors: ({ response }) => response.errors,
});

const refreshToken = async () => {
  try {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/v1/auth/refresh-auth-tokens`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
    });
    return !!response && response.status === 200;
  } catch (error) {
    return false;
  }
};

const customFetchBaseQuery: CustomFetchBaseQuery = async (
  args,
  api,
  extraOptions
): Promise<
  QueryReturnValue<unknown, GraphQLError[] | undefined, Record<string, unknown>>
> => {
  let result = await baseQuery(args, api, extraOptions);
  const { dispatch } = api;
  const {
    failureMessageKey,
    successMessageKey,
    failureType = NotificationTypes.ERROR,
    successType = NotificationTypes.SUCCESS,
  } = extraOptions ?? {};
  const { data, error } = result;
  if (error?.length && error[0].extensions?.status === 401) {
    if (error[0].extensions?.code === ErrorCode.UNAUTHENTICATED) {
      const isTokenRefreshed = await refreshToken();
      if (isTokenRefreshed) {
        result = await baseQuery(args, api, extraOptions);
      } else {
        logoutUser();
      }
    } else if (error[0].extensions?.code === ErrorCode.INVALID_USER) {
      logoutUser();
    }
  }
  if (error && failureMessageKey) {
    dispatch(
      showNotifier({
        message: translate(failureMessageKey),
        type: failureType,
      })
    );
  } else if (data && successMessageKey) {
    dispatch(
      showNotifier({
        message: translate(successMessageKey),
        type: successType,
      })
    );
  }
  return result;
};

const getTagTypes = () => {
  const containerWiseTags = TagTypes as Record<string, Record<string, string>>;

  const tagTypes = Object.keys(containerWiseTags).reduce<string[]>(
    (tagList, containerName) =>
      tagList.concat(Object.values(containerWiseTags[containerName])),
    []
  );
  return tagTypes;
};

const api = createApi({
  reducerPath: 'api',
  tagTypes: getTagTypes(),
  baseQuery: customFetchBaseQuery,
  refetchOnMountOrArgChange: 60,
  endpoints: () => ({}),
});

export default api;
