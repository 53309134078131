import { lazy } from 'react';

import { LocalStorageKeys, RoleName } from '@constants/common';
import {
  PermissionsForUser,
  ProjectPermissionType,
} from '@customTypes/permission';
import { User } from '@customTypes/authentication';
import RoutePaths from '@routes/RoutesPath';
import { NotificationTypes } from '@constants/components';

import { getLocalStorageItem, setLocalStorageItem } from './storage';
import showNotification from './showNotification';

export const injectParamsToRoute = (
  route: string,
  params: Record<string, string>
) => {
  const routeParts = route.split('/');
  const injectedRoute = routeParts.map((part) => {
    if (part.startsWith(':')) {
      const requiredParam = part.substring(1, part.length);
      if (params[requiredParam]) return params[requiredParam];
    }
    return part;
  });
  return injectedRoute.join('/');
};

export const getProjectIdFromProjectSubPaths = (path: string): string => {
  const projectPath = RoutePaths.PROJECTS.ROOT.split('/')[1];
  const pathParts = path.split('/');
  const isPathProjectSubPath = projectPath === pathParts[1];
  if (isPathProjectSubPath) return pathParts[2];
  return '';
};

export const getPermittedProjectRoutePath = (
  {
    testRunPermissions,
    milestonePermissions,
    testSuitePermissions,
  }: ProjectPermissionType,
  projectId: string,
  errorMessage: string
): string => {
  let routePath;
  if (milestonePermissions.entityRead && testRunPermissions.entityRead) {
    routePath = RoutePaths.PROJECTS.OVERVIEW;
  } else if (milestonePermissions.entityRead) {
    routePath = RoutePaths.MILESTONES.ROOT;
  } else if (testRunPermissions.entityRead) {
    routePath = RoutePaths.TEST_RUNS.ROOT;
  } else if (testSuitePermissions.entityRead) {
    routePath = RoutePaths.TEST_SUITE.ROOT;
  }

  if (routePath) {
    routePath = injectParamsToRoute(routePath, {
      projectId,
    });
  } else {
    routePath = RoutePaths.PROJECTS.ROOT;
    showNotification({
      message: errorMessage,
      type: NotificationTypes.ERROR,
    });
  }
  return routePath;
};

export const getPermittedMembersRoutePath = ({
  globalUsersPermissions,
  globalRolesPermissions,
}: {
  globalUsersPermissions: PermissionsForUser;
  globalRolesPermissions: PermissionsForUser;
}): string => {
  let routePath;
  if (globalUsersPermissions.entityRead) {
    routePath = RoutePaths.MEMBERS.USERS;
  } else if (globalRolesPermissions.entityRead) {
    routePath = RoutePaths.MEMBERS.ROLES;
  } else {
    routePath = RoutePaths.PROJECTS.ROOT;
  }
  return routePath;
};

export const lazyWithRetry = (componentImport: any) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      getLocalStorageItem(LocalStorageKeys.PAGE_HAS_BEEN_FORCE_REFRESHED) ||
        'false'
    );
    try {
      const component = await componentImport();
      setLocalStorageItem(
        LocalStorageKeys.PAGE_HAS_BEEN_FORCE_REFRESHED,
        'false'
      );
      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        setLocalStorageItem(
          LocalStorageKeys.PAGE_HAS_BEEN_FORCE_REFRESHED,
          'true'
        );
        return window.location.reload();
      }
      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Therefore the error boundary will handle that error
      throw error;
    }
  });
