/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ImportedTestCase,
  ImportTestCaseFileType,
} from '@customTypes/importTestCase';
import { createSlice, current } from '@reduxjs/toolkit';

import { TestCaseFolderType, TestCaseType } from '../types';

const initialState: {
  rootFolder: TestCaseFolderType | null;
  importTestCasesItems: ImportedTestCase[] | null;
  importJobId: string | null;
  importFileType: ImportTestCaseFileType;
} = {
  rootFolder: null,
  importTestCasesItems: null,
  importJobId: null,
  importFileType: ImportTestCaseFileType.XLSX,
};

const saveSubFolder = (
  folders: TestCaseFolderType[],
  data: TestCaseFolderType
): TestCaseFolderType[] =>
  folders?.map((folder) =>
    data.id === folder.id
      ? {
          ...folder,
          ...data,
          subFolders: data.subFolders?.map((subFolder) => ({
            ...subFolder,
            parentFolderId: folder.id,
            path: folder.path?.concat(`.${subFolder.name}`),
          })),
          testcases: data.testcases?.map((testCase) => ({
            ...testCase,
            parentFolderId: folder.id,
            path: folder.path?.concat(`.${testCase.name}`),
          })),
        }
      : {
          ...folder,
          subFolders:
            folder.subFolders && saveSubFolder(folder.subFolders, data),
        }
  );

const addSubFolderToParent = (
  folder: TestCaseFolderType,
  data: TestCaseFolderType
): TestCaseFolderType => ({
  ...folder,
  subFolders:
    folder.id === data.parentFolderId
      ? [...(folder.subFolders || []), data]
      : folder?.subFolders?.map((subFolder) =>
          addSubFolderToParent(subFolder, data)
        ),
});

const addNewTestCase = (
  folder: TestCaseFolderType,
  data: TestCaseType
): TestCaseFolderType => ({
  ...folder,
  testcases:
    folder.id === data.parentFolderId
      ? [...folder.testcases!, data]
      : folder.testcases,
  subFolders: folder.subFolders?.map((subFolder) =>
    addNewTestCase(subFolder, data)
  ),
});

const updateTestCaseDetails = (
  folder: TestCaseFolderType,
  data: TestCaseType
): TestCaseFolderType => ({
  ...folder,
  testcases:
    folder.id === data.parentFolderId
      ? folder.testcases?.map((testCase) =>
          testCase.id === data.id ? data : testCase
        )
      : folder.testcases,
  subFolders: folder.subFolders?.map((subFolder) =>
    updateTestCaseDetails(subFolder, data)
  ),
});

const updateSubFolderName = (
  folder: TestCaseFolderType,
  data: TestCaseFolderType
): TestCaseFolderType => ({
  ...folder,
  ...(folder.id === data.id
    ? data
    : {
        subFolders: folder?.subFolders?.map((subFolder) =>
          updateSubFolderName(subFolder, data)
        ),
      }),
});

const deleteFolderById = (
  folders: TestCaseFolderType[],
  id: string
): TestCaseFolderType[] => {
  const index = folders?.findIndex((folder) => folder.id === id);
  if (index >= 0) {
    return folders.filter((folder) => folder.id !== id);
  }
  return folders?.map((folder) => ({
    ...folder,
    subFolders: folder?.subFolders && deleteFolderById(folder?.subFolders, id),
  }));
};

const deleteTestCases = (
  folders: TestCaseFolderType[],
  data: string[]
): TestCaseFolderType[] => {
  const result = folders.map((folder) => ({
    ...folder,
    testcases: folder.testcases?.filter(
      (testCase) => !data.includes(testCase.id)
    ),
    subFolders: folder.subFolders && deleteTestCases(folder.subFolders, data),
  }));
  return result;
};

const deleteTestCaseFolders = (
  folders: TestCaseFolderType[],
  data: string[]
): TestCaseFolderType[] =>
  folders
    .filter((folder) => !data.includes(folder.id))
    .map((folder) => ({
      ...folder,
      subFolders:
        folder.subFolders && deleteTestCaseFolders(folder.subFolders, data),
    }));

const deleteRootTestCases = (testCases: TestCaseType[], data: string[]) =>
  testCases.filter((testCase) => !data.includes(testCase.id));

const setExpanded = (
  folder: TestCaseFolderType,
  id: string,
  isExpanded: boolean
): TestCaseFolderType => ({
  ...folder,
  ...(folder.id === id
    ? { isExpanded }
    : {
        subFolders: folder?.subFolders?.map((subFolder) =>
          setExpanded(subFolder, id, isExpanded)
        ),
      }),
});

const setTestCasesExpanded = (
  folder: TestCaseFolderType,
  id: string,
  isTestCasesExpanded: boolean
): TestCaseFolderType => ({
  ...folder,
  ...(folder.id === id
    ? { isTestCasesExpanded }
    : {
        subFolders: folder?.subFolders?.map((subFolder) =>
          setTestCasesExpanded(subFolder, id, isTestCasesExpanded)
        ),
      }),
});

const updateTestCaseFolderPosition = (
  folder: TestCaseFolderType,
  response: TestCaseFolderType,
  updatedFolder: TestCaseFolderType
): TestCaseFolderType => {
  const filteredSubFolders = folder.subFolders?.filter(
    (subFol) => subFol.id !== response.id
  );
  const subFolders =
    response.parentFolder?.id === folder.id
      ? filteredSubFolders?.length
        ? [
            ...filteredSubFolders.slice(0, response.position),
            {
              ...updatedFolder,
              ...response,
              parentFolderId: response.parentFolder?.id,
            },
            ...filteredSubFolders.slice(
              response.position,
              filteredSubFolders.length
            ),
          ]
        : [
            {
              ...updatedFolder,
              response,
              parentFolderId: response.parentFolder?.id,
            },
          ]
      : filteredSubFolders
          ?.filter((subFolder) => subFolder.id !== response.id)
          .map((subFolder, index) => ({
            ...subFolder,
            position: index,
          }));
  return {
    ...folder,
    subFolders: subFolders?.length
      ? subFolders?.map((subFol) =>
          updateTestCaseFolderPosition(subFol, response, updatedFolder)
        )
      : subFolders,
  };
};

const updateTestCasePosition = (
  folder: TestCaseFolderType,
  response: TestCaseType,
  updatedTestCase: TestCaseType
): TestCaseFolderType => {
  const filteredTestCases =
    updatedTestCase.parentFolderId === folder.id
      ? folder.testcases?.filter((testCase) => testCase.id !== response.id)
      : folder.testcases;
  const testCases =
    response.folder?.id === folder.id
      ? filteredTestCases?.length
        ? [
            ...filteredTestCases.slice(0, response.position),
            {
              ...updatedTestCase,
              ...response,
              parentFolderId: response.folder?.id,
            },
            ...filteredTestCases.slice(
              response.position,
              filteredTestCases.length
            ),
          ]
        : [
            {
              ...updatedTestCase,
              response,
              parentFolderId: response.folder?.id,
            },
          ]
      : filteredTestCases
          ?.filter((testCase) => testCase.id !== response.id)
          .map((testCase, index) => ({
            ...testCase,
            position: index,
          }));
  return {
    ...folder,
    testcases: testCases,
    subFolders: folder?.subFolders?.length
      ? folder.subFolders.map((subFol) =>
          updateTestCasePosition(subFol, response, updatedTestCase)
        )
      : folder?.subFolders,
  };
};

export const testSuiteReducerSlice = createSlice({
  name: 'testSuite',
  initialState,
  reducers: {
    fetchTestSuite: (state, { payload }) => {
      state.rootFolder = {
        ...payload,
        path: payload.name,
        subFolders: payload.subFolders?.map(
          (subFolder: TestCaseFolderType) => ({
            ...subFolder,
            parentFolderId: payload.id,
            path: `${payload.name}.${subFolder.name}`,
          })
        ),
        testcases: payload.testcases?.map((testCase: TestCaseFolderType) => ({
          ...testCase,
          parentFolderId: payload.id,
          path: `${payload.name}.${testCase.name}`,
        })),
        isExpanded: true,
      };
    },
    fetchSubFolders: (state, { payload }) => {
      state.rootFolder!.subFolders = saveSubFolder(
        current(state).rootFolder?.subFolders || [],
        payload
      );
    },
    addSubFolder: (state, { payload }) => {
      state.rootFolder = addSubFolderToParent(
        current(state).rootFolder!,
        payload
      );
    },
    addTestCase: (state, { payload }) => {
      state.rootFolder = addNewTestCase(current(state).rootFolder!, payload);
    },
    updateTestCase: (state, { payload }) => {
      state.rootFolder = updateTestCaseDetails(
        current(state).rootFolder!,
        payload
      );
    },
    updateSubFolder: (state, { payload }) => {
      state.rootFolder!.subFolders = (
        current(state).rootFolder?.subFolders || []
      ).map((rootFolder) => updateSubFolderName(rootFolder, payload));
    },
    deleteFolder: (state, { payload }) => {
      state.rootFolder!.subFolders = deleteFolderById(
        current(state).rootFolder?.subFolders || [],
        payload
      );
    },
    deleteTestCasesByIds: (state, { payload }) => {
      state.rootFolder = {
        ...(current(state).rootFolder as TestCaseFolderType),
        testcases: deleteRootTestCases(
          current(state).rootFolder?.testcases || [],
          payload
        ),
        subFolders: deleteTestCases(
          current(state).rootFolder?.subFolders || [],
          payload
        ),
      };
    },
    deleteTestCasesByFolderIds: (state, { payload }) => {
      state.rootFolder!.subFolders = deleteTestCaseFolders(
        current(state).rootFolder?.subFolders || [],
        payload
      );
    },
    toggleExpanded: (state, { payload }) => {
      state.rootFolder = setExpanded(
        current(state).rootFolder!,
        payload.id,
        payload.isExpanded
      );
    },
    toggleTestCaseExpanded: (state, { payload }) => {
      state.rootFolder = setTestCasesExpanded(
        current(state).rootFolder!,
        payload.id,
        payload.isTestCasesExpanded
      );
    },
    resetTestSuite: (state) => {
      state.rootFolder = null;
    },
    setImportTestcaseItems: (state, { payload }) => {
      state.importTestCasesItems = payload;
    },
    deleteImportTestcaseItem: (state, { payload }) => {
      const currentList =
        state.importTestCasesItems?.filter(({ id }) => payload !== id) ?? null;
      state.importTestCasesItems = currentList;
    },
    updateFolderPosition: (state, { payload }) => {
      state.rootFolder = updateTestCaseFolderPosition(
        current(state).rootFolder!,
        payload.response,
        payload.updatedFolder
      );
    },
    updateCasePosition: (state, { payload }) => {
      state.rootFolder = updateTestCasePosition(
        current(state).rootFolder!,
        payload.response,
        payload.updatedTestCase
      );
    },
    setImportJobId: (state, { payload }) => {
      state.importJobId = payload;
    },
    clearImportJobId: (state) => {
      state.importJobId = null;
    },
    setImportFileType: (state, { payload }) => {
      state.importFileType = payload;
    },
  },
});

export const {
  addSubFolder,
  addTestCase,
  deleteFolder,
  deleteTestCasesByIds,
  deleteTestCasesByFolderIds,
  fetchTestSuite,
  fetchSubFolders,
  updateSubFolder,
  updateTestCase,
  toggleExpanded,
  toggleTestCaseExpanded,
  updateFolderPosition,
  updateCasePosition,
  resetTestSuite,
  setImportTestcaseItems,
  deleteImportTestcaseItem,
  setImportJobId,
  clearImportJobId,
  setImportFileType,
} = testSuiteReducerSlice.actions;

export default testSuiteReducerSlice.reducer;
