import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalEditor } from 'lexical';
import { useEffect } from 'react';

const GetEditorStatePlugin = ({
  setEditor,
}: {
  setEditor: (editor: LexicalEditor) => void;
}) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    setEditor(editor);
  }, [editor, setEditor]);

  return null;
};

export default GetEditorStatePlugin;
