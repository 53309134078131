export enum TestCaseTypes {
  ACCEPTANCE = 'ACCEPTANCE',
  ACCESSIBILITY = 'ACCESSIBILITY',
  AUTOMATED = 'AUTOMATED',
  COMPATIBILITY = 'COMPATIBILITY',
  DESTRUCTIVE = 'DESTRUCTIVE',
  FUNCTIONAL = 'FUNCTIONAL',
  OTHER = 'OTHER',
  PERFORMANCE = 'PERFORMANCE',
  REGRESSION = 'REGRESSION',
  SECURITY = 'SECURITY',
  SMOKE_AND_SANITY = 'SMOKE_AND_SANITY',
  USABILITY = 'USABILITY',
}

export enum TestCaseTemplates {
  SINGLE_STEP = 'SINGLE_STEP',
  MULTI_STEP = 'MULTI_STEP',
}

export const TestCaseTemplatesTextMapping: Record<TestCaseTemplates, string> = {
  [TestCaseTemplates.SINGLE_STEP]: 'Single Step',
  [TestCaseTemplates.MULTI_STEP]: 'Multi Step',
};

export enum Priorities {
  CRITICAL = 'CRITICAL',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export const TESTCASE_NAME_MAXLENGTH = 255;
export const TESTCASE_TAGNAME_MAXLENGTH = 100;
export const TESTCASE_AUTOMATION_MAXLENTH = 100;

export enum TimeUnit {
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
}
