import { FC, MouseEventHandler, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { Link } from 'react-router-dom';

import { ButtonTypes } from '@constants/components';

import Spinner from '../spinner/Spinner';

export type ButtonProps = {
  handleClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  type?: string;
  className?: string;
  label?: string;
  htmlType?: 'submit' | 'button' | 'reset' | undefined;
  to?: string;
  showLoader?: boolean;
  form?: string;
  children?: ReactNode;
  linkState?: unknown;
};

const Button: FC<ButtonProps> = (props) => {
  const {
    label,
    className,
    type,
    handleClick,
    disabled,
    htmlType = 'button',
    to,
    showLoader = false,
    form,
    children,
    linkState,
  } = props;
  const getStyles = () => {
    switch (type) {
      case ButtonTypes.FILLED:
        return 'border-accent-primary bg-accent-primary hover:bg-[#674ed8] text-white';
      case ButtonTypes.OUTLINED:
        return 'border-red-primary text-red-primary bg-white';
      case ButtonTypes.NO_BORDER:
        return 'border-none text-grayish-navy bg-white';
      default:
        return 'border-blue-magenta-faded text-blue-magenta-faded bg-white';
    }
  };

  const buttonStyles = twMerge(
    `flex justify-center items-center py-[8px] border-[0.5px] rounded px-4 text-center text-sm ${getStyles()}`,
    className,
    disabled && 'disabled-button'
  );

  if (to) {
    return (
      <Link to={to} className={buttonStyles} state={linkState}>
        {label}
      </Link>
    );
  }

  return (
    <button
      className={buttonStyles}
      onClick={handleClick}
      disabled={disabled || showLoader}
      // eslint-disable-next-line react/button-has-type
      type={htmlType}
      form={form}
    >
      {showLoader ? <Spinner className='absolute' /> : null}
      {/* Applying opacity-0 when showing spinner instead of hiding label to avoid layout shift */}
      {label && <p className={showLoader ? 'opacity-0' : ''}>{label}</p>}
      <div className={showLoader ? 'opacity-0' : ''}>{children}</div>
    </button>
  );
};

Button.defaultProps = {
  type: ButtonTypes.DEFAULT,
  className: '',
  handleClick: () => null,
  disabled: false,
};

export default Button;
