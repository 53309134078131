// Add custom colors in HEX (#XXXXXX)
const colors = {
  'accent-primary': '#7257F0',
  'grey-border': '#DBDBDB',
  'grey-divider': '#E1DFDF',
  'grey-placeholder': 'B5B7C0',
  'grey-table-header': '#80828B',
  'dark-grey-primary': '#606367',
  'light-greyish-blue': '#F7F8FC',
  'black-primary': '#0D062D',
  'blue-magenta-faded': '#787486',
  'light-purple': '#F1EEFD',
  'red-primary': '#D22D4A',
  'light-lavender': '#E0E5F4',
  'light-slate-grey': '#767D92',
  'vivid-red': '#FF023F',
  'light-blue': '#03B0F4',
  'vivid-blue': '#3B82F6',
  'black-gray': '#323232',
  'light-gray': '#F0F0F0',
  'light-green': '#69CB69',
  'dark-yellow': '#FDDC2B',
  'dark-gray': '#595959',
  'dark-peach': '#DF6E6E',
  'grey-subheading': '#6C6C6C',
  'dark-gray-secondary': '#3E3E3E',
  'grayish-navy': '#505768',
  'dark-main': '#020202',
  'test-case-passed': '#69CB69',
  'test-case-blocked': '#595959',
  'test-case-failed': '#DF6E6E',
  'test-case-retest': '#FDDC2B',
  'icon-dark-gray': '#6F7278',
  'icon-light-gray': '#A6A8AB',
  purple: '#5030E5',
};

module.exports = colors;
