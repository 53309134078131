const theme = {
  text: {
    bold: 'font-bold',
    italic: 'italic',
    underline: 'underline',
  },
  list: {
    nested: {
      listitem: 'list-none',
    },
    ul: 'p-0 m-0 ml-4 list-disc',
    listitem: 'my-[8px] mx-[32px]',
  },
  link: 'no-underline text-blue-400',
};

export default theme;
