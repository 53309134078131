import {
  Milestone,
  GetMilestonesByProjectIdResponse,
  GetMilestonesInput,
  CreateMilestoneInput,
  CreateMilestoneResponse,
  GetMilestoneByIdResponse,
  GetSearchMilestonesResponse,
  GetSearchMilestonesinput,
  SearchMilestone,
  DeleteMilestoneByIdResponse,
  UpdateMilestoneByIdResponse,
  UpdateMilestoneByIdInput,
} from '@customTypes/milestones';
import { Page } from '@customTypes/common';
import api from '@services/api';
import { TagTypes } from '@constants/common';
import {
  getMilestoneByIdQuery,
  createMilestoneMutation,
  getMilestonesByProjectIdQuery,
  getSearchMilestonesQuery,
  deleteMilestoneMutation,
  updateMilestoneMutation,
} from './queries';

const milestonesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMilestonesByProjectId: builder.query<
      Page<Milestone>,
      GetMilestonesInput
    >({
      query: (variables) => ({
        document: getMilestonesByProjectIdQuery,
        variables,
      }),
      providesTags: [TagTypes.MILESTONE.MILESTONES],
      transformResponse: (response: GetMilestonesByProjectIdResponse) =>
        response?.allMilestonesByProjectId,
    }),
    createMilestone: builder.mutation<{ id: string }, CreateMilestoneInput>({
      query: (variables) => ({
        document: createMilestoneMutation,
        variables,
      }),
      invalidatesTags: [
        TagTypes.MILESTONE.MILESTONES,
        TagTypes.PROJECTS.PROJECTS,
      ],
      transformResponse: (response: CreateMilestoneResponse) =>
        response?.createMilestone,
      extraOptions: {
        successMessageKey: 'milestones/addMilestone:successfullyAdded',
        // failure messages to be handled at the point of api execution
      },
    }),
    getMilestoneById: builder.query<Milestone, string>({
      query: (id) => ({
        document: getMilestoneByIdQuery,
        variables: {
          id,
        },
      }),
      providesTags: [TagTypes.MILESTONE.MILESTONE],
      transformResponse: (response: GetMilestoneByIdResponse) =>
        response?.milestoneById,
    }),
    searchMilestones: builder.query<
      SearchMilestone[],
      GetSearchMilestonesinput
    >({
      query: (variables) => ({
        document: getSearchMilestonesQuery,
        variables,
      }),
      transformResponse: (response: GetSearchMilestonesResponse) =>
        response.searchMilestones,
    }),
    deleteMilestone: builder.mutation<boolean, string>({
      query: (id) => ({
        document: deleteMilestoneMutation,
        variables: {
          id,
        },
      }),
      invalidatesTags: [
        TagTypes.MILESTONE.MILESTONES,
        TagTypes.MILESTONE.MILESTONE,
        TagTypes.PROJECTS.PROJECTS,
        TagTypes.TEST_RUNS.TEST_RUN_BY_ID,
        TagTypes.TEST_RUNS.TEST_RUN_DETAILED,
      ],
      transformResponse: (response: DeleteMilestoneByIdResponse) =>
        response?.deleteMilestoneById,
      extraOptions: {
        successMessageKey: 'milestones/milestoneList:deletedSuccessfully',
        failureMessageKey: 'milestones/milestoneList:deletionFailed',
      },
    }),
    updateMilestone: builder.mutation<{ id: string }, UpdateMilestoneByIdInput>(
      {
        query: (variables) => ({
          document: updateMilestoneMutation,
          variables,
        }),
        invalidatesTags: [
          TagTypes.MILESTONE.MILESTONE,
          TagTypes.MILESTONE.MILESTONES,
          TagTypes.TEST_RUNS.TEST_RUN_BY_ID,
          TagTypes.TEST_RUNS.TEST_RUN_DETAILED,
        ],
        transformResponse: (response: UpdateMilestoneByIdResponse) =>
          response?.updateMilestoneById,
        extraOptions: {
          successMessageKey: 'milestones/milestoneList:updatedSuccessfully',
          // failure messages to be handled at the point of api execution
        },
      }
    ),
  }),
});

export const {
  useGetMilestonesByProjectIdQuery,
  useLazyGetMilestonesByProjectIdQuery,
  useCreateMilestoneMutation,
  useGetMilestoneByIdQuery,
  useLazyGetMilestoneByIdQuery,
  useLazySearchMilestonesQuery,
  useDeleteMilestoneMutation,
  useUpdateMilestoneMutation,
} = milestonesApi;
