import store from '@store/store';
import { showNotifier } from '@containers/notification/reducer';

interface NotifierProps {
  message: string;
  type: string;
}

const showNotification = ({ message, type }: NotifierProps) => {
  store.dispatch(
    showNotifier({
      message,
      type,
    })
  );
};

export default showNotification;
