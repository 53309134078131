import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';

import {
  CaretDown,
  DoubleCaretLeft,
  DoubleCaretRight,
  Setting,
  TaskSquare,
  Users,
} from '@icons';
import { SVGComponent } from '@customTypes/common';
import { useI18n } from '@hooks';
import {
  getProjectIdFromProjectSubPaths,
  injectParamsToRoute,
} from '@utils/navigation';
import {
  parseAndGetGlobalPermissions,
  parseAndGetProjectPermissions,
} from '@utils/permission';
import RoutePaths from '@routes/RoutesPath';
import { User } from '@customTypes/authentication';
import {
  PermissionsForUser,
  ProjectPermissionType,
} from '@customTypes/permission';
import { RoleName, TRANS_KEYS } from '@constants/common';
import { KVLogo } from '@images';
import { useGetGlobalPermissionsForUserQuery } from '@store/api';
import { FullPageSpinner } from '@components';

import {
  useGetProjectDetailsQuery,
  useLazyGetProjectPermissionsForUserQuery,
} from '../projects/store/api';
import KVLogoSmall from './KeyValueLogo.png';

const links: { transKey: string; url: string }[] = [];

const getRoutes = (
  globalUsersPermissions: PermissionsForUser,
  globalRolesPermissions: PermissionsForUser
) => {
  const routes = [
    {
      transKey: TRANS_KEYS.PROJECTS,
      url: RoutePaths.PROJECTS.ROOT,
      icon: TaskSquare,
      nestedLinks: links,
    },
    ...(globalUsersPermissions.entityRead || globalRolesPermissions.entityRead
      ? [
          {
            transKey: TRANS_KEYS.MEMBERS,
            url: globalUsersPermissions.entityRead
              ? RoutePaths.MEMBERS.USERS
              : RoutePaths.MEMBERS.ROLES,
            pathToCheck: RoutePaths.MEMBERS.ROOT,
            icon: Users,
            nestedLinks: links,
          },
        ]
      : []),
  ];
  return routes;
};

const getProjectLinks = ({
  testRunPermissions,
  milestonePermissions,
  testSuitePermissions,
}: ProjectPermissionType) => {
  const projectLinks = [
    ...(milestonePermissions.entityRead || testRunPermissions.entityRead
      ? [
          {
            transKey: TRANS_KEYS.OVERVIEW,
            url: RoutePaths.PROJECTS.OVERVIEW,
          },
        ]
      : []),
    ...(milestonePermissions.entityRead
      ? [
          {
            transKey: TRANS_KEYS.MILESTONES,
            url: RoutePaths.MILESTONES.ROOT,
          },
        ]
      : []),
    ...(testRunPermissions.entityRead
      ? [
          {
            transKey: TRANS_KEYS.TEST_RUNS,
            url: RoutePaths.TEST_RUNS.ROOT,
          },
        ]
      : []),
    ...(testSuitePermissions.entityRead
      ? [
          {
            transKey: TRANS_KEYS.TEST_SUITE,
            url: RoutePaths.TEST_SUITE.ROOT,
          },
        ]
      : []),
  ];
  return projectLinks;
};

const getMembersLinks = (
  globalUsersPermissions: PermissionsForUser,
  globalRolesPermissions: PermissionsForUser
) => {
  const membersLinks = [
    ...(globalUsersPermissions.entityRead
      ? [
          {
            transKey: TRANS_KEYS.USERS,
            url: RoutePaths.MEMBERS.USERS,
          },
        ]
      : []),
    ...(globalRolesPermissions.entityRead
      ? [
          {
            transKey: TRANS_KEYS.ROLES,
            url: RoutePaths.MEMBERS.ROLES,
          },
        ]
      : []),
  ];
  return membersLinks;
};

const pageNumberValues: { [key: string]: string | null } = {};

function SideNavLink({
  url,
  icon: Icon,
  name,
  isActive,
  showExpandedView = true,
  isNestedLink = false,
  hasNestedLinks = false,
}: {
  url: string;
  icon?: SVGComponent;
  name: string;
  isActive: boolean;
  showExpandedView?: boolean;
  isNestedLink?: boolean;
  hasNestedLinks?: boolean;
}) {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const isInListPage = useCallback(() => {
    const regexString = url.concat('(\\?page=[0-9]+)?$');
    return new RegExp(regexString).test(window.location.href);
  }, [url]);

  useEffect(() => {
    if (isInListPage()) {
      pageNumberValues[url] = queryParams.get('page');
    }
  }, [isInListPage, queryParams, url]);

  const doNavigation = useCallback(() => {
    if (isInListPage()) {
      pageNumberValues[url] = null;
    }
    const navigationUrl = pageNumberValues[url]
      ? url.concat(`?page=${pageNumberValues[url]}`)
      : url;
    navigate(navigationUrl);
  }, [isInListPage, navigate, url]);

  return (
    <button
      type='button'
      className={`flex w-full ${
        showExpandedView
          ? 'py-[7px] pl-[27px] pr-4 rounded-md'
          : 'py-[9px] justify-center rounded-l-md'
      }  items-center ${
        isActive
          ? 'bg-neutral-100 text-accent-primary'
          : 'text-blue-magenta-faded'
      } ${isNestedLink && showExpandedView ? 'pl-[14px]' : ''}`}
      onClick={doNavigation}
    >
      {Icon && (
        <Icon
          className={`w-6 h-6 flex-shrink-0 ${
            isActive ? 'path-stroke-accent' : ''
          } ${showExpandedView ? 'mr-4' : ''}`}
        />
      )}
      {showExpandedView && (
        <span className='truncate whitespace-pre'>{name}</span>
      )}
      {hasNestedLinks && isActive && showExpandedView && (
        <CaretDown className='ml-auto' />
      )}
    </button>
  );
}

const checkIsActivePath = (pathToCheck: string, currentPath: string) =>
  currentPath.startsWith(pathToCheck);

function SideNavigation({
  isBarExpanded,
  onBottomCaretClick,
  user,
}: {
  isBarExpanded: boolean;
  onBottomCaretClick: VoidFunction;
  user?: User;
}) {
  const { pathname } = useLocation();
  const { t } = useI18n(['sideNavigation']);
  const projectId = getProjectIdFromProjectSubPaths(pathname);

  const { data: projectDetails, isLoading: projectDetailsLoading } =
    useGetProjectDetailsQuery(projectId, {
      skip: !projectId,
    });

  const {
    data: globalPermissionsForUser,
    isFetching: isGlobalPermissionsFetching,
  } = useGetGlobalPermissionsForUserQuery();
  const { globalUsersPermissions, globalRolesPermissions } = useMemo(
    () => parseAndGetGlobalPermissions(globalPermissionsForUser),
    [globalPermissionsForUser]
  );

  const [
    getProjectPermissionsForUser,
    {
      data: projectPermissionsForUser,
      isFetching: isProjectPermissionsFetching,
    },
  ] = useLazyGetProjectPermissionsForUserQuery();

  useEffect(() => {
    if (projectId) {
      getProjectPermissionsForUser(projectId);
    }
  }, [getProjectPermissionsForUser, projectId]);

  const projectPermissions = useMemo(
    () => parseAndGetProjectPermissions(projectPermissionsForUser),
    [projectPermissionsForUser]
  );

  const routeObject = {} as any;
  const routes = getRoutes(globalUsersPermissions, globalRolesPermissions);
  routes.forEach((route) => {
    routeObject[route.transKey] = route;
  });
  const projectRoute = routeObject[TRANS_KEYS.PROJECTS];
  const membersRoute = routeObject[TRANS_KEYS.MEMBERS];

  const projectLinks = useMemo(
    () => getProjectLinks(projectPermissions),
    [projectPermissions]
  );
  if (projectRoute && projectLinks) {
    projectRoute.nestedLinks = projectLinks;
  }

  const membersLinks = useMemo(
    () => getMembersLinks(globalUsersPermissions, globalRolesPermissions),
    [globalUsersPermissions, globalRolesPermissions]
  );
  if (membersRoute && membersLinks) {
    membersRoute.nestedLinks = membersLinks;
  }

  if (isGlobalPermissionsFetching || isProjectPermissionsFetching) {
    return <FullPageSpinner />;
  }

  return (
    <nav
      className={`${
        isBarExpanded
          ? 'w-[var(--expanded-sidenav-width)]'
          : 'w-[var(--collapsed-sidenav-width)]'
      } h-screen z-2 bg-white fixed top-0 left-0 border-r border-r-grey-border transition-all duration-[var(--sidenav-animation-duration)] motion-reduce:transition-none`}
    >
      <Link
        to={RoutePaths.PROJECTS.ROOT}
        className={`flex items-center py-8 ${
          isBarExpanded ? 'px-9' : 'justify-center'
        } border-b border-b-grey-border h-[89px]`}
      >
        {isBarExpanded ? (
          <KVLogo className='h-[80px]' />
        ) : (
          <img
            alt='logo'
            loading='eager'
            src={KVLogoSmall}
            className='shrink-0 w-6 h-6'
          />
        )}
        {/* TODO: Change image and name with appropriate tenant data */}
        {/* {isBarExpanded &&
          (user ? (
            <span className='ml-4 text-lg leading-6 text-black-primary truncate'>
              {user?.userTenants[0]?.tenant?.name}
            </span>
          ) : (
            <div className='flex-1 ml-4 h-5 rounded-md skeleton-shimmer' />
          ))} */}
      </Link>
      <div className={`py-[38px] ${isBarExpanded ? 'px-[10px]' : ''}`}>
        {routes.map(({ transKey, url, nestedLinks, icon, pathToCheck }) => {
          const isActiveRootPath = checkIsActivePath(
            pathToCheck || url,
            pathname
          );
          const hasNestedLinks = !!nestedLinks?.length;
          const isProjectSection = url === RoutePaths.PROJECTS.ROOT;
          const showNestedPaths = isProjectSection ? !!projectId : true;
          return (
            <div className={isBarExpanded ? 'mb-4' : 'mb-3'} key={url}>
              <SideNavLink
                url={url}
                name={
                  transKey === TRANS_KEYS.PROJECTS &&
                  showNestedPaths &&
                  !projectDetailsLoading
                    ? projectDetails?.name || t(`routeNames.${transKey}.root`)
                    : t(`routeNames.${transKey}.root`)
                }
                icon={icon}
                isActive={isActiveRootPath}
                showExpandedView={isBarExpanded}
                hasNestedLinks={hasNestedLinks && showNestedPaths}
              />
              {!!hasNestedLinks &&
                isBarExpanded &&
                isActiveRootPath &&
                showNestedPaths && (
                  <div className='flex flex-col gap-1 pl-4 mt-4 ml-9 border-l-2 border-l-grey-border'>
                    {nestedLinks?.map(
                      ({
                        transKey: nestedLinkTransKey,
                        url: nestedLinkUrl,
                      }) => {
                        const route = isProjectSection
                          ? injectParamsToRoute(nestedLinkUrl, {
                              projectId,
                            })
                          : nestedLinkUrl;
                        return (
                          <SideNavLink
                            url={route}
                            name={t(
                              `routeNames.${transKey}.${nestedLinkTransKey}`
                            )}
                            isActive={checkIsActivePath(route, pathname)}
                            key={route}
                            isNestedLink
                          />
                        );
                      }
                    )}
                  </div>
                )}
            </div>
          );
        })}
      </div>
      <button
        onClick={onBottomCaretClick}
        type='button'
        className='absolute right-4 bottom-4'
      >
        {isBarExpanded ? <DoubleCaretLeft /> : <DoubleCaretRight />}
      </button>
    </nav>
  );
}

export default SideNavigation;
