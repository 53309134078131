import { StringCardProps, StringCardsProps } from '@customTypes/common';
import { Cross } from '@icons';

const StringCard = ({ id, label, onCloseClick }: StringCardProps) => {
  const handleCloseClick = (event: any) => {
    event.stopPropagation();
    onCloseClick(id);
  };
  return (
    <div className='flex items-center py-1 px-2 m-1 bg-white rounded border border-[#A3A5B0]'>
      <p className='mr-2 text-xs text-blue-magenta-faded break-all'>{label}</p>
      <button
        type='button'
        tabIndex={0}
        onClick={handleCloseClick}
        onKeyDown={handleCloseClick}
      >
        <Cross className='w-3 h-3 path-stroke-grey' />
      </button>
    </div>
  );
};

const StringCards = ({ selectedItems, onCardCloseClick }: StringCardsProps) => (
  <div>
    {selectedItems.length > 0 && (
      <div className='flex overflow-auto flex-wrap p-1 mb-2 max-h-[78px] bg-white border border-light-lavender white-scroll'>
        {selectedItems.map((item) => (
          <StringCard
            key={item.id}
            id={item.id}
            label={item.title}
            onCloseClick={onCardCloseClick}
          />
        ))}
      </div>
    )}
  </div>
);

export default StringCards;
