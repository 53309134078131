export const TagTypes = {
  MILESTONE: {
    MILESTONES: 'Milestones',
    MILESTONE: 'Milestone',
  },
  PROJECTS: {
    PROJECT_DETAILS: 'ProjectDetails',
    PROJECTS: 'Projects',
  },
  TEST_CASE: {
    TEST_CASE_DETAILS: 'TestCaseDetails',
    FAILED_TEST_CASE_DETAILS: 'failedTestCaseDetails',
    IMPORT_JOBS: 'importJobs',
    TEST_CASE_EXECUTION_DETAILS: 'TestCaseExecutionDetails',
  },
  TEST_RUNS: {
    TEST_CASE_HISTORY: 'TestCaseHistory',
    TEST_RUNS: 'TestRuns',
    ALL_TESTRUN_EXECUTIONS_BY_TESTRUN_ID: 'AllTestrunExecutionsByTestrunId',
    TESTCASE_EXECUTIONS_BY_TESTRUN_EXEC_ID: 'TestcaseExecutionsByTestrunExecId',
    TEST_RUN_WITH_SEARCH_PARAMS: 'TestRunWithSearchParams',
    TEST_RUN_DETAILED: 'TestRunDetailed',
    TEST_RUN_BY_ID: 'TestRunById',
    PROJECT_USERS: 'projectUsers',
  },
  PERMISSIONS_FOR_USER: {
    GLOBAL_PERMISSIONS_FOR_USER: 'globalPermissionsForUser',
    PROJECT_PERMISSIONS_FOR_USER: 'projectPermissionsForUser',
  },
  USERS: {
    USERS: 'Users',
  },
  ROLES: {
    GLOBAL: 'Global',
    PROJECT: 'Project',
  },
};

export enum SessionStorageKeys {
  TEST_RUN_TAB = 'testRunTab',
}

export enum LocalStorageKeys {
  PAGE_HAS_BEEN_FORCE_REFRESHED = 'pageHasBeenForceRefreshed',
}

export const ATTACHMENT_MAX_FILE_SIZE = 5000000;

export const PROJECT_NAME_MATCH =
  /[a-zA-Z0-9]?[a-zA-Z0-9]?[a-zA-Z0-9]+[a-zA-Z0-9#.:_\- ]*/;

export const TRANS_KEYS = {
  PROJECTS: 'projects',
  MEMBERS: 'members',
  USERS: 'users',
  ROLES: 'roles',
  OVERVIEW: 'overview',
  MILESTONES: 'milestones',
  TEST_RUNS: 'testRuns',
  TEST_SUITE: 'testSuite',
};

export enum KeyboardEvent {
  ENTER = 'Enter',
}

export enum RoleName {
  ADMIN = 'ADMIN',
}

export const NAME_SEARCH_REGEX = /[a-zA-Z0-9]+/;

export const horizontalBarGraphOptions = {
  maintainAspectRatio: false,
  indexAxis: 'y' as const,
  maxBarThickness: 32,
  elements: {
    bar: {
      borderWidth: 0,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'right' as const,
    },
    title: {
      display: true,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
};

export enum CkEditorToolbar {
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINE = 'underline',
  LINK = 'link',
  BULLETED_LIST = 'bulletedList',
  NUMBERED_LIST = 'numberedList',
  INSERT_TABLE = 'insertTable',
  FILE_UPLOAD = 'fileupload',
}
