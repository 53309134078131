import { MutableRefObject, useRef, useState } from 'react';

import { useClickOutside } from '@hooks';
import { ComboboxChanges, ComboboxItem } from '@customTypes/components';
import { CaretDown } from '@icons';
import { getDebouncedCallback } from '@utils/common';
import { SelectWithSearchProps } from '@customTypes/common';

import { twMerge } from 'tailwind-merge';
import Combobox from '../combobox/Combobox';

const SelectWithSearch = ({
  id,
  label,
  placeholder,
  getSearchTerm,
  comboItems,
  getSelectedItem,
  onClick = () => null,
  defaultSelectedItem = {} as ComboboxItem,
  buttonStyle,
  showLoader = false,
  menuContainerStyle = '',
  inputContainerStyle = '',
  inputStyle = '',
  disabled = false,
}: SelectWithSearchProps) => {
  const [showComboBox, setShowComboBox] = useState(false);

  const selectRef = useRef() as MutableRefObject<HTMLDivElement>;

  const closeComboBox = () => {
    getSearchTerm('');
    setShowComboBox(false);
  };

  const onSearch = getDebouncedCallback((change: ComboboxChanges) => {
    const trimmedValue = change?.inputValue?.trim() || '';
    getSearchTerm(trimmedValue);
  });

  const onSelectedItemChange = (changes: ComboboxChanges) => {
    const item = changes.selectedItem;
    if (item) {
      closeComboBox();
      getSelectedItem(item);
    }
  };

  useClickOutside({ ref: selectRef, handler: closeComboBox });

  return (
    <div>
      {label && (
        <label
          htmlFor={id}
          className='inline-block mb-1.5 text-sm text-light-slate-grey'
        >
          {label}
        </label>
      )}
      <div ref={selectRef} className='relative items-center'>
        <button
          id={id}
          type='button'
          onClick={() => {
            setShowComboBox((prevState) => !prevState);
            getSearchTerm('');
            onClick();
          }}
          className={twMerge(
            'flex justify-between items-center py-[8px] px-[12px] h-[40px] bg-white hover:bg-[#5130e51a] rounded border border-light-lavender cursor-pointer disabled:bg-[#EBEDED] disabled:text-[#A3A5B0]',
            buttonStyle
          )}
          disabled={disabled}
        >
          <div className='flex items-center w-full max-w-[220px]'>
            <p className='overflow-hidden text-[15px] truncate whitespace-pre'>
              {defaultSelectedItem.title}
            </p>
          </div>
          {!disabled && <CaretDown />}
        </button>
        {showComboBox && (
          <div className='flex absolute w-full bg-white'>
            <Combobox
              placeholder={placeholder}
              items={comboItems}
              onInputValueChange={onSearch}
              onSelectedItemChange={onSelectedItemChange}
              inputContainerStyle={twMerge('mt-1 p-0', inputContainerStyle)}
              showCaretDown={false}
              containerStyle='w-full'
              emptyStateStyle='p-1'
              inputStyle={twMerge(
                'w-full h-[40px] m-0 z-10 px-[12px] py-[8px] rounded',
                inputStyle
              )}
              defaultIsOpen
              menuContainerStyle={twMerge(
                'overflow-y-auto',
                menuContainerStyle
              )}
              menuItemTextStyle='whitespace-pre'
              showLoader={showLoader}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectWithSearch;
