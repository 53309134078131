import { DropdownActions } from '@constants/components';
import { Root, Trigger, Content, Item } from '@radix-ui/react-dropdown-menu';
import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

type DropdownProps = {
  options: { label: string; type: string; style?: string }[];
  trigger: React.ReactNode;
  asChild?: boolean;
  contentStyles?: string;
  optionStyles?: string;
  onSelect: (type: string) => void;
  align?: 'start' | 'center' | 'end';
};

const DropDown: FC<DropdownProps> = (props) => {
  const {
    options,
    trigger,
    asChild = false,
    contentStyles,
    optionStyles,
    onSelect,
    align,
  } = props;
  return (
    <Root>
      <Trigger asChild={asChild} className='hover:cursor-pointer'>
        {trigger}
      </Trigger>
      <Content
        className={twMerge(
          'rounded-md border bg-white z-10 mt-1',
          contentStyles
        )}
        align={align}
      >
        {options.map(({ label, type, style }, index) => (
          <Item
            key={label}
            onSelect={() => onSelect(type)}
            className={twMerge(
              `menuItem py-2 px-4 text-left hover:cursor-pointer focus:outline-none focus:bg-gray-50 ${
                index !== options.length - 1 ? `border-b` : ''
              } ${type === DropdownActions.DELETE ? `text-red-primary` : ''}`,
              optionStyles,
              style
            )}
          >
            {label}
          </Item>
        ))}
      </Content>
    </Root>
  );
};

export default DropDown;
