import { Fragment, SyntheticEvent } from 'react';
import { twMerge } from 'tailwind-merge';
import { Listbox, Transition } from '@headlessui/react';

import { TestcaseStatus, TestCaseStatusMapping } from '@constants/components';
import { allowedTestCaseTransitionStates } from '@constants/testRuns';
import { pickStatusColor } from '@containers/test-runs/utils/pickStatusColor';
import { CaretDown } from '@icons';
import { useI18n } from '@hooks';

interface TestCaseStatusSelectorProps {
  onSelect: (status: TestcaseStatus) => void;
  status: TestcaseStatus;
  finalStatus?: TestcaseStatus;
  isDisabled?: boolean;
  disableEventPropagation?: boolean;
}

const TestCaseStatusSelector = ({
  onSelect,
  status,
  finalStatus,
  isDisabled,
  disableEventPropagation = false,
}: TestCaseStatusSelectorProps) => {
  const { t } = useI18n(['common']);

  const allowedStatusTransitions = allowedTestCaseTransitionStates.get(
    finalStatus ?? status
  );

  const handleSelectorWrapperClick = (e: SyntheticEvent) => {
    e.stopPropagation();
  };
  return (
    <Listbox value={status} onChange={onSelect} disabled={isDisabled}>
      {/* Not adding button properties since we need not click on the wrapper div */}
      <div
        className='relative'
        role='presentation'
        onClick={
          disableEventPropagation ? handleSelectorWrapperClick : undefined
        }
      >
        <Listbox.Button
          className={twMerge(
            'relative py-[4.5px] pl-5 pr-[14px] w-full text-left rounded flex flex-row justify-between items-center',
            TestCaseStatusMapping.get(status)?.bgColor || 'bg-light-purple',
            TestCaseStatusMapping.get(status)?.textColor || 'text-dark-gray',
            isDisabled ? 'opacity-50' : ''
          )}
        >
          <span className='block text-sm font-normal truncate'>
            {status
              ? t(TestCaseStatusMapping.get(status)?.label || '')
              : t('select')}
          </span>
          <CaretDown
            className={twMerge(
              TestCaseStatusMapping.get(status)?.caretColor || 'text-dark-gray'
            )}
          />
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Listbox.Options className='overflow-auto absolute z-10 mt-1 w-full max-h-60 text-base bg-white rounded focus:outline-none shadow-md'>
            {allowedStatusTransitions?.map((item, index) => (
              <Listbox.Option
                key={item}
                className={({ active }) =>
                  `relative cursor-pointer select-none ${
                    active ? 'bg-gray-50' : 'text-gray-900'
                  }`
                }
                value={item}
              >
                <span
                  className={twMerge(
                    'flex flex-row items-center py-[4.5px] px-3',
                    index !== allowedStatusTransitions.length - 1 &&
                      'border-b-[1px] border-b-light-lavender'
                  )}
                >
                  <span
                    className={twMerge(
                      'mr-2 w-2 h-2 rounded-md',
                      pickStatusColor(item)
                    )}
                  />
                  <span className='text-sm font-normal text-light-slate-grey'>
                    {t(TestCaseStatusMapping.get(item)?.label || '')}
                  </span>
                </span>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default TestCaseStatusSelector;
