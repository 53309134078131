import { CheckBoxStates } from '@constants/components';
import { FunctionComponent, SVGProps } from 'react';

export type SVGComponent = FunctionComponent<SVGProps<SVGSVGElement>>;

export interface PaginationInput {
  page: number;
  size: number;
}

export interface Page<ContentType> {
  content: Array<ContentType>;
  totalPages: number;
  totalElements: number;
  pageable: {
    pageNumber: number;
  };
  last: boolean;
}

export interface CheckboxState {
  [key: string]: {
    id: string;
    type: 'folder' | 'testCase';
    state: CheckBoxStates;
    parentId: string;
    testCasesCount?: number;
    path?: string;
    disabled?: boolean;
  };
}

export enum GlobalSearchEntityName {
  PROJECT = 'PROJECT',
  TEST_CASE = 'TESTCASE',
  TEST_RUN = 'TESTRUN',
  MILESTONE = 'MILESTONE',
}

export interface GlobalSearch {
  projectId: string;
  projectName: string;
  name: string;
  entityName: GlobalSearchEntityName;
  entityId: string;
}

export interface GlobalSearchResopnse {
  globalSearch: GlobalSearch[];
}

export interface TestcaseImportTemplate {
  CsvFileUrl: string;
  XlsxFileUrl: string;
  XlsFileUrl: string;
}
export interface TMSProperties {
  testcaseImportTemplate: TestcaseImportTemplate;
}

export interface getTMSPropertiesResponse {
  getTMSProperties: TMSProperties;
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SortData {
  field: string;
  sortOrder: SortDirection;
}

export interface CommonState {
  columnSorts: { [key: string]: SortData | undefined };
}

export interface ColumnsSortPayload {
  pageId: string;
  sortData: SortData | undefined;
}

interface ComboboxItem {
  id: string;
  title: string;
}

export interface StringCardProps {
  id: string;
  label: string;
  onCloseClick: (id: string) => void;
}

export interface StringCardsProps {
  selectedItems: ComboboxItem[];
  onCardCloseClick: (id: string) => void;
}

export interface TextboxWithCardsProps {
  label?: string;
  placeholder?: string;
  inputClassName?: string;
  labelClassName?: string;
  regexValidator?: RegExp;
  errorMessage: string;
  required: boolean;
  onInputStringsChange: (strings: string[]) => void;
  inputString?: string;
  disabled?: boolean;
  unique?: boolean;
}

export interface ComboboxWithCardsProps {
  label?: string;
  placeholder: string;
  inputContainerStyle?: string;
  items: ComboboxItem[];
  showLoader?: boolean;
  onSelectedItemsChange: (items: ComboboxItem[]) => void;
  defaultSelectedItems: ComboboxItem[];
  inputStyle?: string;
  menuContainerStyle?: string;
  containerStyle?: string;
  onSearchValueChange: (value: string) => void;
  showCaretDown?: boolean;
}

export interface SelectWithSearchProps {
  id: string;
  label?: string;
  placeholder: string;
  getSearchTerm: (searchString: string) => void;
  comboItems: ComboboxItem[];
  getSelectedItem: (item: ComboboxItem) => void;
  onClick?: () => void;
  defaultSelectedItem: ComboboxItem | undefined;
  buttonStyle?: string;
  showLoader?: boolean;
  menuContainerStyle?: string;
  inputContainerStyle?: string;
  inputStyle?: string;
  disabled?: boolean;
}

export enum ErrorCode {
  INVALID_USER = 'INVALID_USER',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}
