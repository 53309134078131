import {
  getTMSPropertiesResponse,
  GlobalSearch,
  GlobalSearchResopnse,
  TMSProperties,
} from '@customTypes/common';
import {
  PermissionsForUser,
  PermissionsForUserResponse,
} from '@customTypes/permission';
import api from '@services/api';
import { TagTypes } from '@constants/common';

import {
  globalSearchQuery,
  getTMSPropertiesQuery,
  getGlobalPermissionsForUserQuery,
} from './queries';

const commonApis = api.injectEndpoints({
  endpoints: (builder) => ({
    globalSearch: builder.query<GlobalSearch[], string>({
      query: (searchTerm) => ({
        document: globalSearchQuery,
        variables: {
          searchTerm,
        },
      }),
      transformResponse: (response: GlobalSearchResopnse) =>
        response?.globalSearch,
    }),
    getTMSProperties: builder.query<TMSProperties, null>({
      query: () => ({
        document: getTMSPropertiesQuery,
      }),
      transformResponse: (response: getTMSPropertiesResponse) =>
        response?.getTMSProperties,
    }),
    getGlobalPermissionsForUser: builder.query<PermissionsForUser[], void>({
      query: () => ({
        document: getGlobalPermissionsForUserQuery,
      }),
      providesTags: [TagTypes.PERMISSIONS_FOR_USER.GLOBAL_PERMISSIONS_FOR_USER],
      transformResponse: (response: PermissionsForUserResponse) =>
        response?.getGlobalPermissionsForUser,
    }),
  }),
});

export const {
  useLazyGlobalSearchQuery,
  useGetTMSPropertiesQuery,
  useGetGlobalPermissionsForUserQuery,
} = commonApis;
