import { RefObject, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { useI18n } from '@hooks';
import { ATTACHMENT_MAX_FILE_SIZE } from '@constants/common';
import { FileInput } from '@containers/test-suite/types';
import showNotification from '@utils/showNotification';
import { NotificationTypes } from '@constants/components';
import { AttachmentIcon } from '@icons';

import Attachment from './Attachment';

interface AttachmentsProps {
  uploadAttachmentClick?: (
    file: File | undefined,
    onUploadComplete: () => void
  ) => void;
  showCloseButton?: boolean;
  fileInputRef?: RefObject<HTMLInputElement>;
  attachments: FileInput[] | null | undefined;
  onAttachmentDownload: (key: string, fileName: string) => void;
  removeAttachment?: (index: number) => void;
  containerStyle?: string;
  emptyStateStyle?: string;
  itemsContainerStyle?: string;
  showUploadButton?: boolean;
}

const Attachments = ({
  uploadAttachmentClick,
  showCloseButton,
  attachments,
  onAttachmentDownload,
  removeAttachment,
  containerStyle,
  fileInputRef,
  showUploadButton = false,
  emptyStateStyle = '',
  itemsContainerStyle = '',
}: AttachmentsProps) => {
  const { t } = useI18n(['common']);

  const localFileInputRef = useRef<HTMLInputElement>(null);

  const [uploadingAttachments, setUploadingAttachments] =
    useState<FileInput[]>();

  const addAttachmentToList = (attachment: File) => {
    if (attachment.size < ATTACHMENT_MAX_FILE_SIZE) {
      let currentUploadingAttachments: FileInput[] = [];
      currentUploadingAttachments = [
        ...(uploadingAttachments ?? []),
        {
          fileName: attachment.name,
          key: `${uploadingAttachments?.length ?? '0'}`,
        },
      ];
      setUploadingAttachments(currentUploadingAttachments);
      return uploadAttachmentClick?.(attachment, () => {
        setUploadingAttachments((prevList) => {
          if (prevList?.length) {
            return prevList?.filter(
              ({ key }) => key !== `${currentUploadingAttachments.length - 1}`
            );
          }
          return [];
        });
      });
    }
    return showNotification({
      message: t('fileSizeExceeded'),
      type: NotificationTypes.ERROR,
    });
  };

  return (
    <div
      className={twMerge(
        !(attachments?.length || uploadingAttachments?.length)
          ? emptyStateStyle
          : itemsContainerStyle,
        containerStyle
      )}
    >
      {uploadAttachmentClick && (
        <input
          ref={fileInputRef ?? localFileInputRef}
          className='hidden'
          type='file'
          onChange={(e) =>
            e.target?.files?.[0] && addAttachmentToList(e.target?.files?.[0])
          }
        />
      )}
      {attachments?.length ||
      uploadingAttachments?.length ||
      showUploadButton ? (
        <div className='flex flex-wrap'>
          {showUploadButton ? (
            <button
              type='button'
              className='p-[9px]'
              onClick={() => localFileInputRef.current?.click()}
            >
              <AttachmentIcon />
            </button>
          ) : null}
          {attachments?.map((attachment, index) => (
            <Attachment
              key={attachment?.fileName}
              name={attachment?.fileName}
              onClose={() => removeAttachment?.(index)}
              showCloseButton={showCloseButton}
              onDownloadClick={() =>
                onAttachmentDownload?.(attachment.key, attachment.fileName)
              }
            />
          ))}
          {uploadingAttachments?.map((attachment) => (
            <Attachment
              key={attachment?.key}
              name={attachment?.fileName}
              isLoading
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Attachments;
