import { twMerge } from 'tailwind-merge';
import { Todo, Completed, InProgress } from '@icons';
import { Link } from 'react-router-dom';
import { StatusCardProgressBar, StatusCardType } from '@constants/components';
import { TestRunStatuses } from '@constants/testRuns';
import { MilestoneStatuses } from '@constants/milestone';
import ProgressBar, { MilestoneProgressBar } from '../progress-bar/ProgressBar';

type StatusCardProps = {
  data: {
    progressBarType: StatusCardProgressBar;
    title: string;
    status: string;
    testCaseSummaryCount?: {
      untested?: number;
      passed?: number;
      failed?: number;
      inprogress?: number;
    };
    summaryCount?: {
      inProgressCount?: number;
      notStartedCount?: number;
      completedCount?: number;
    };
    data: string[];
    testCaseSummaryProgress?: {
      untested?: number;
      passed?: number;
      failed?: number;
      inprogress?: number;
    };
    summaryProgress?: {
      inProgressCount?: number;
      notStartedCount?: number;
      completedCount?: number;
    };
  };
  className?: string;
  to: string;
  type: StatusCardType;
};

const StatusCard = ({ data, className, to, type }: StatusCardProps) => {
  const getProgressBar = () => {
    switch (data.progressBarType) {
      case StatusCardProgressBar.MILESTONE_PROGRESS_BAR:
        if (data.summaryCount && data.summaryProgress) {
          return (
            <MilestoneProgressBar
              progress={data.summaryProgress}
              count={data.summaryCount}
            />
          );
        }
        break;
      default:
        if (data.testCaseSummaryCount && data.testCaseSummaryProgress) {
          return (
            <ProgressBar
              progress={data.testCaseSummaryProgress}
              count={data.testCaseSummaryCount}
            />
          );
        }
    }
    return null;
  };

  function renderIcon() {
    if (type === StatusCardType.TESTRUN) {
      switch (data.status) {
        case TestRunStatuses.TODO:
          return <Todo className='w-[30px] h-[30px]' />;
        case TestRunStatuses.IN_PROGRESS:
          return <InProgress className='w-[30px] h-[30px]' />;
        default:
          return <Completed className='w-[30px] h-[30px]' />;
      }
    } else {
      switch (data.status) {
        case MilestoneStatuses.NOT_STARTED:
          return <Todo className='w-[30px] h-[30px]' />;
        case MilestoneStatuses.STARTED:
          return <InProgress className='w-[30px] h-[30px]' />;
        default:
          return <Completed className='w-[30px] h-[30px]' />;
      }
    }
  }

  return (
    <Link to={to}>
      <div
        className={twMerge(
          'flex flex-row justify-between w-full pl-6 pr-16 bg-white rounded-md 2xl:rounded-lg shadow-[0_4px_4px_rgba(0,0,0,0.1)]',
          className
        )}
      >
        <div className='flex flex-row items-center pr-1 w-[65%]'>
          <div className='shrink-0 py-6 pr-6'>{renderIcon()}</div>
          <div className='flex flex-col w-[calc(100%-54px)]'>
            <p className='w-full truncate whitespace-pre break-all'>
              {data.title}
            </p>
            <div className='flex flex-row'>
              {data.data.map((text, index) => (
                <div key={text} className='flex flex-row item-center'>
                  {index > 0 && (
                    <div className='p-2'>
                      <div className='w-[6px] h-[6px] bg-dark-gray rounded-lg' />
                    </div>
                  )}
                  <p className='text-sm font-normal text-black-primary'>
                    {text}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='flex justify-end items-center w-[35%]'>
          {getProgressBar()}
        </div>
      </div>
    </Link>
  );
};

export default StatusCard;
