import { GetSocialSignInUriInput, User } from '@customTypes/authentication';
import api from '@services/api';

import { getSignInUrlQuery, getUserQuery, logoutMutation } from './queries';

interface LoginResponse {
  getSocialSigninUrl: {
    url: string;
  };
}

interface LogoutResponse {
  logout: string;
}

const LoginApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSocialSignInUrl: builder.query<string, GetSocialSignInUriInput>({
      query: ({ successRedirectUri, failedRedirectUri }) => ({
        document: getSignInUrlQuery,
        variables: {
          successRedirectUri,
          ...(failedRedirectUri && { failedRedirectUri }),
        },
      }),
      transformResponse: (response: LoginResponse) =>
        response.getSocialSigninUrl.url,
    }),
    getUser: builder.query<User, void>({
      query: () => ({
        document: getUserQuery,
      }),
      transformResponse: (response: { user: User }) => response.user,
    }),
    logout: builder.mutation<string, void>({
      query: () => ({
        document: logoutMutation,
      }),
      transformResponse: (response: LogoutResponse) => response?.logout,
      extraOptions: {
        failureMessageKey: 'common:somethingWrong',
      },
    }),
  }),
});

export const {
  useGetSocialSignInUrlQuery,
  useGetUserQuery,
  useLogoutMutation,
} = LoginApi;
