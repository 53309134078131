import {
  useState,
  useRef,
  useCallback,
  MouseEvent,
  KeyboardEvent,
} from 'react';
import { Calendar } from 'react-date-range';
import { twMerge } from 'tailwind-merge';
import { Root, Trigger, Content } from '@radix-ui/react-dropdown-menu';
import { useClickOutside } from '@hooks';
import { Delete } from '@icons';

import './DatePickerStyles.css';

interface DatePickerProps {
  date?: Date;
  minDate?: Date;
  maxDate?: Date;
  onApplyDate: (date?: Date) => void;
  triggerClassName?: string;
  disabled?: boolean;
}

const DatePicker = ({
  date,
  minDate,
  maxDate,
  onApplyDate,
  triggerClassName,
  disabled = false,
}: DatePickerProps) => {
  const [open, setOpen] = useState(false);
  const datePicker = useRef<HTMLDivElement>(null);

  const toggleDatePicker = useCallback(() => {
    setOpen((currentValue) => !currentValue);
  }, []);

  const handleCancel = () => {
    toggleDatePicker();
  };

  useClickOutside({ ref: datePicker, handler: handleCancel });

  const onCrossClick = (
    event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>
  ) => {
    event.stopPropagation();
    if (!date) {
      toggleDatePicker();
    } else {
      onApplyDate();
    }
  };

  return (
    <Root open={open}>
      <Trigger
        className={twMerge(
          'flex justify-between items-center w-full text-left',
          triggerClassName
        )}
        onClick={toggleDatePicker}
        disabled={disabled}
      >
        <p
          className={twMerge(
            !date ? 'placeholder' : '',
            disabled ? 'text-gray-300' : ''
          )}
        >
          {date ? date.toLocaleDateString() : 'DD/MM/YYYY'}
        </p>
        <div
          onClick={onCrossClick}
          onKeyDown={onCrossClick}
          role='button'
          tabIndex={0}
          className={disabled ? 'pointer-events-none' : ''}
        >
          <Delete
            className={`w-6 h-6 path-fill-current ${
              !date || disabled ? 'text-light-lavender' : 'text-accent-primary'
            }`}
          />
        </div>
      </Trigger>
      <Content
        className='z-10 p-6 pt-3 bg-white rounded-lg shadow-default'
        ref={datePicker}
      >
        <Calendar
          date={date}
          color='#000000'
          maxDate={maxDate}
          minDate={minDate}
          shownDate={date ?? minDate}
          onChange={(newDate) => {
            onApplyDate(newDate);
            toggleDatePicker();
          }}
          showMonthAndYearPickers={false}
          className='w-[300px] border-b border-[#F5F6F8]'
        />
      </Content>
    </Root>
  );
};

export default DatePicker;
