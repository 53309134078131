import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorBoundaryFallback, FullPageSpinner } from '@components';
import store from '@store/store';
import RouteLayout from '@routes/RouteLayout';

import './i18n';

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <Suspense fallback={<FullPageSpinner useScreenDimensions />}>
        <Provider store={store}>
          <Router>
            <RouteLayout />
          </Router>
        </Provider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
