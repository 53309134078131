import { gql } from 'graphql-request';

const project = `
  id
  name
  description
`;

export const createProjectMutation = gql`
  mutation createProject($createProjectInput: CreateProjectInput!) {
    createProject(createProjectInput: $createProjectInput) {
     ${project}
    }
  }
`;

export const getProjectsQuery = gql`
  query getProjects(
    $paginationInput: PaginationInput
    $sortBy: ProjectSortField
    $sortDirection: SortDirection
  ) {
    getProjectSummaryForUser(
      paginationInput: $paginationInput
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      content {
        id
        name
        description
        totalMilestones
        completedMilestones
        totalTestruns
        completedTestruns
        totalTestcases
        percentageCompletion
      }
      totalPages
      totalElements
      last
    }
  }
`;

export const updateProjectMutation = gql`
  mutation updateProject($updateProjectInput: UpdateProjectInput!) {
    updateProject(updateProjectInput: $updateProjectInput) {
     ${project}
    }
  }
`;

export const deleteProjectMutation = gql`
  mutation deleteProjectById($id: UUID!) {
    deleteProjectById(id: $id)
  }
`;

export const getProjectDetailsQuery = gql`
  query getProjectById($id: UUID!) {
    getProjectById(id: $id) {
     ${project}
    }
  }
`;

export const searchProjectsQuery = gql`
  query searchProjects($searchTerm: String!) {
    searchProjects(searchTerm: $searchTerm) {
     ${project}
    }
  }
`;

export const getProjectPermissionsForUserQuery = gql`
  query getProjectPermissionsForUser($projectId: UUID!) {
    getProjectPermissionsForUser(projectId: $projectId) {
      entityName
      entityRead
      entityCreate
      entityUpdate
      entityDelete
    }
  }
`;
