import { FC } from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { ChevronUp } from '@icons';
import colors from '@styles/colors';
import { SortData, SortDirection } from '@customTypes/common';
import { useAppDispatch } from '@store/store';
import { RootState, updateCurrentSorting } from '@store/reducer';

import Pagination from '../pagination/Pagination';

type TableProps = {
  columns: {
    label: string | JSX.Element;
    field: string;
    styles?: string;
    headerStyles?: string;
    showSort?: boolean;
    prefixElement?: any;
  }[];
  colSpans?: number[];
  headgap?: string;
  className?: string;
  data: any;
  onRowClick?: (rowData: any) => void;
  onHover?: (rowId: string) => void;
  next?: VoidFunction;
  hasMore?: boolean;
  paginationEnabled?: boolean;
  prev?: VoidFunction;
  currentPage?: number;
  title: string;
  onSort?: (sortData?: { field: string; sortOrder: SortDirection }) => void;
  columnSortData?: {
    column: string;
    sortDirection: SortDirection;
  };
  maxPages: number;
  pageSize?: number;
  pageId?: string;
  headStyles?: string;
  rowStyles?: string;
  totalElements?: number;
};

const Table: FC<TableProps> = (props) => {
  const dispatch = useAppDispatch();

  const {
    columns,
    data,
    headgap = false,
    colSpans,
    className,
    maxPages,
    next = () => null,
    prev = () => null,
    hasMore = false,
    paginationEnabled = true,
    onRowClick,
    onHover,
    currentPage = 0,
    title,
    onSort,
    columnSortData,
    pageSize,
    pageId = '',
    headStyles,
    rowStyles,
    totalElements,
  } = props;

  const { columnSorts } = useSelector(
    (state: RootState) => state.rootReducer.common
  );

  const onColumnSortClick = (field: string) => {
    const getNextSortParams = () => {
      const sortedField = pageId
        ? columnSorts[pageId]?.field
        : columnSortData?.column;
      const sortedDirection = pageId
        ? columnSorts[pageId]?.sortOrder
        : columnSortData?.sortDirection;
      if (field !== sortedField) {
        return { field, sortOrder: SortDirection.ASC };
      }
      switch (sortedDirection) {
        case SortDirection.ASC:
          return { field, sortOrder: SortDirection.DESC };
        case SortDirection.DESC:
          return undefined;
        default:
          return { field, sortOrder: SortDirection.ASC };
      }
    };

    const sortData: SortData | undefined = getNextSortParams();
    onSort?.(sortData);
    if (pageId) {
      dispatch(updateCurrentSorting({ pageId, sortData }));
    }
  };

  const getSortIconColor = (field: string, direction: SortDirection) => {
    if (
      field === columnSortData?.column &&
      columnSortData?.sortDirection === direction
    ) {
      return colors['icon-dark-gray'];
    }
    return colors['icon-light-gray'];
  };

  return (
    <>
      <table
        className={twMerge(
          'w-full border-separate table-fixed border-spacing-y-3',
          className
        )}
      >
        <thead className={twMerge('text-grey-table-header', headStyles)}>
          <tr className={rowStyles}>
            {columns.map((column, index) => (
              <th
                key={`colHead-${column.field}`}
                className={twMerge('p-3 text-right font-medium', column.styles)}
                colSpan={colSpans && colSpans[index] ? colSpans[index] : 1}
              >
                <div className={twMerge('flex gap-[5px]', column.headerStyles)}>
                  {column.prefixElement}
                  {column.showSort ? (
                    <button
                      type='button'
                      onClick={() => onColumnSortClick(column.field)}
                      className={twMerge(
                        'w-full flex flex-row justify-end text-right items-center cursor-pointer',
                        column.headerStyles
                      )}
                    >
                      {column.label}
                      <div className='ml-1'>
                        <ChevronUp
                          color={getSortIconColor(
                            column.field,
                            SortDirection.ASC
                          )}
                          className='mb-[1px]'
                        />
                        <ChevronUp
                          color={getSortIconColor(
                            column.field,
                            SortDirection.DESC
                          )}
                          className='rotate-180'
                        />
                      </div>
                    </button>
                  ) : (
                    column.label
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {headgap && <div className='invisible w-full h-4' />}
          {data.map((row: any) => (
            <tr
              key={row.id}
              onClick={
                onRowClick &&
                ((event) => {
                  const targetElement: any = event.target;
                  // Added this extra check since click event is triggered on clicking nested dropdown items
                  if (!targetElement?.className?.includes('menuItem'))
                    onRowClick(row);
                })
              }
              onMouseEnter={() => onHover && onHover(row.id)}
              onMouseLeave={() => onHover && onHover('')}
              className={twMerge(
                `${
                  onRowClick
                    ? 'hover:cursor-pointer hover:shadow-[0_4px_10px_4px_rgba(219,219,219,0.1)]'
                    : ''
                }`,
                rowStyles
              )}
            >
              {columns.map((column, index) => (
                <td
                  className={twMerge(
                    'text-blue-magenta-faded bg-white p-4 text-base  text-right first:rounded-l-md last:rounded-r-md',
                    column.styles
                  )}
                  key={column.field}
                  colSpan={colSpans && colSpans[index] ? colSpans[index] : 1}
                >
                  {row[column.field]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {paginationEnabled && maxPages > 1 && totalElements ? (
        <Pagination
          paginateFront={next}
          paginateBack={prev}
          currentPage={currentPage}
          limit={data?.length ?? 0}
          hasMore={hasMore}
          title={title}
          pageSize={pageSize}
          totalElements={totalElements}
        />
      ) : null}
    </>
  );
};

export default Table;
