import api from '@services/api';
import { Page } from '@customTypes/common';

import { TagTypes } from '@constants/common';
import {
  CreateTestcasesInputType,
  CreateTestcasesResponse,
  CreateTestcases,
} from 'types/importTestCase';
import {
  addSubFolder,
  addTestCase,
  deleteFolder,
  deleteTestCasesByIds,
  fetchTestSuite,
  fetchSubFolders,
  updateCasePosition,
  updateFolderPosition,
  updateSubFolder,
  updateTestCase,
  deleteTestCasesByFolderIds,
} from './reducer';
import {
  createTestCaseMutation,
  createTestCaseFolderMutation,
  deleteTestCaseFolderMutation,
  getTestCasesByFolderId,
  getTestSuitByProjectId,
  exportTestSuiteQuery,
  getTestCaseFolderById,
  getTestCaseById,
  updateTestCaseFolderMutation,
  updateTestCaseMutation,
  deleteTestCasesMutation,
  deleteTestCasesByFolderIdMutation,
  getTestcaseAttachmentUploadUrl,
  getTestcaseAttachmentDownloadUrl,
  getTestcaseDeletionWarnings,
  createTestcasesMutation,
  getFailedTestCaseExecutionDetailsQuery,
  removeTestcaseAttachmentsByKeysMutation,
  createImportJobMutation,
  getImportJobErrorFileUploadUrlQuery,
  getImportJobErrorFileDownloadUrlQuery,
  updateImportJobMutation,
  getAllImportJobsQuery,
  getTestCaseExecutionDetailsQuery,
} from './queries';
import {
  CreateTestCaseFolderInput,
  UpdateTestCaseFolderInput,
  DeleteTestCasesResponse,
  DeleteTestCasesFoldersResponse,
  GetTestCasesByFolderIdPayload,
  GetTestSuitByProjectIdPayload,
  CreateTestCaseInput,
  CreateTestCaseResponse,
  TestCase,
  UpdateTestCaseInput,
  TestCaseFolderType,
  TestCaseType,
  FileUploadUrlResponse,
  GetTestcaseAttachmentUploadUrlInput,
  GetAttachmentDownloadUrlInput,
  FileDownloadUrlResponse,
  GetTestCaseDeletionScenarioResponse,
  GetTestCaseDeletionScenarioInput,
  DeleteTestCasePayload,
  UpdateTestCaseFolderByIdInput,
  UpdateTestCaseByIdInput,
  ExportTestSuiteInput,
  ExportTestSuiteResponse,
  FailedTestCaseExecutionDetails,
  CreateImportJobInput,
  ImportJob,
  ImportJobFileUploadUrlResponse,
  ImportJobErrorFileUploadUrlInput,
  ImportJobErrorFileDownloadUrl,
  UpdateImportJobInput,
  GetAllImportJobsPayload,
  TestCaseExecutionDetails,
} from '../types';

const testSuiteApis = api.injectEndpoints({
  endpoints: (builder) => ({
    createTestCase: builder.mutation<TestCase, CreateTestCaseInput>({
      query: (createTestcaseInput) => ({
        document: createTestCaseMutation,
        variables: {
          createTestcaseInput,
        },
      }),
      transformResponse: (response: CreateTestCaseResponse) =>
        response?.createTestcase,
      extraOptions: {
        successMessageKey: 'testSuite/addTestCase:addSuccess',
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            addTestCase({ ...data, ...arg, parentFolderId: arg.folderId })
          );
        } catch (err) {
          // `onError` side-effect
        }
      },
      invalidatesTags: [TagTypes.PROJECTS.PROJECTS],
    }),
    getTestSuiteByProjectId: builder.query<
      TestCaseFolderType,
      GetTestSuitByProjectIdPayload
    >({
      query: (payload) => ({
        document: getTestSuitByProjectId,
        variables: payload,
      }),
      transformResponse: (response: {
        getRootTestcaseFolder: TestCaseFolderType;
      }) => response?.getRootTestcaseFolder,
      keepUnusedDataFor: 0,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(fetchTestSuite(data));
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getTestCaseFolderById: builder.query<TestCaseFolderType, string>({
      query: (id) => ({
        document: getTestCaseFolderById,
        variables: { id },
      }),
      transformResponse: (response: {
        testcaseFolderById: TestCaseFolderType;
      }) => response?.testcaseFolderById,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(fetchSubFolders(data));
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    createTestCaseFolder: builder.mutation<
      TestCaseFolderType,
      CreateTestCaseFolderInput
    >({
      query: (createTestcaseFolderInput) => ({
        document: createTestCaseFolderMutation,
        variables: {
          createTestcaseFolderInput,
        },
      }),
      transformResponse: (response: {
        createTestcaseFolder: TestCaseFolderType;
      }) => response?.createTestcaseFolder,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            addSubFolder({ ...data, parentFolderId: arg.parentFolderId })
          );
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    updateTestCaseFolder: builder.mutation<
      TestCaseFolderType,
      UpdateTestCaseFolderInput
    >({
      query: ({ id, ...updateTestcaseFolderInput }) => ({
        document: updateTestCaseFolderMutation,
        variables: {
          id,
          updateTestcaseFolderInput,
        },
      }),
      invalidatesTags: [TagTypes.TEST_RUNS.TEST_RUN_DETAILED],
      transformResponse: (response: {
        updateTestcaseFolderById: TestCaseFolderType;
      }) => response?.updateTestcaseFolderById,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateSubFolder({ ...data, id: arg.id }));
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteTestCaseFolder: builder.mutation<boolean, string>({
      query: (id) => ({
        document: deleteTestCaseFolderMutation,
        variables: {
          id,
        },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(deleteFolder(arg));
          }
        } catch (err) {
          // `onError` side-effect
        }
      },
      invalidatesTags: [
        TagTypes.PROJECTS.PROJECTS,
        TagTypes.TEST_RUNS.TEST_RUN_DETAILED,
      ],
    }),
    getTestCaseById: builder.query<TestCase, string>({
      query: (id) => ({
        document: getTestCaseById,
        variables: {
          id,
        },
      }),
      providesTags: [TagTypes.TEST_CASE.TEST_CASE_DETAILS],
      transformResponse: (response: { testcaseById: TestCase }) =>
        response?.testcaseById,
    }),
    updateTestCase: builder.mutation<TestCase, UpdateTestCaseInput>({
      query: ({ id, ...updateTestcaseInput }) => ({
        document: updateTestCaseMutation,
        variables: {
          id,
          updateTestcaseInput,
        },
      }),
      invalidatesTags: [
        TagTypes.TEST_CASE.TEST_CASE_DETAILS,
        TagTypes.MILESTONE.MILESTONE,
        TagTypes.TEST_RUNS.TEST_RUNS,
        TagTypes.TEST_RUNS.TEST_CASE_HISTORY,
        TagTypes.TEST_RUNS.ALL_TESTRUN_EXECUTIONS_BY_TESTRUN_ID,
        TagTypes.TEST_RUNS.TEST_RUN_DETAILED,
      ],
      transformResponse: (response: { updateTestcaseById: TestCase }) =>
        response?.updateTestcaseById,
      extraOptions: {
        successMessageKey: 'testSuite/addTestCase:updateSuccess',
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            updateTestCase({ ...data, ...arg, parentFolderId: arg.folderId })
          );
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getTestCasesByFolderId: builder.query<
      Page<TestCaseType>,
      GetTestCasesByFolderIdPayload
    >({
      query: (payload) => ({
        document: getTestCasesByFolderId,
        variables: payload,
      }),
      transformResponse: (response: {
        allTestcasesByFolderId: Page<TestCaseType>;
      }) => response?.allTestcasesByFolderId,
    }),
    deleteTestCasesByIds: builder.mutation<
      DeleteTestCasesResponse,
      DeleteTestCasePayload
    >({
      query: ({ ids, shouldDeleteExecutedCase }) => ({
        document: deleteTestCasesMutation,
        variables: {
          ids,
          shouldDeleteExecutedCase,
        },
      }),
      invalidatesTags: [
        TagTypes.MILESTONE.MILESTONE,
        TagTypes.TEST_RUNS.TEST_RUNS,
        TagTypes.TEST_RUNS.TEST_CASE_HISTORY,
        TagTypes.TEST_RUNS.ALL_TESTRUN_EXECUTIONS_BY_TESTRUN_ID,
        TagTypes.PROJECTS.PROJECTS,
        TagTypes.TEST_RUNS.TEST_RUN_DETAILED,
      ],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(deleteTestCasesByIds(arg.ids));
          }
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteTestCasesByFolderIds: builder.mutation<
      DeleteTestCasesFoldersResponse,
      DeleteTestCasePayload
    >({
      query: ({ ids, shouldDeleteExecutedCase }) => ({
        document: deleteTestCasesByFolderIdMutation,
        variables: {
          ids,
          shouldDeleteExecutedCase,
        },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(deleteTestCasesByFolderIds(arg.ids));
          }
        } catch (err) {
          // `onError` side-effect
        }
      },
      invalidatesTags: [
        TagTypes.MILESTONE.MILESTONE,
        TagTypes.TEST_RUNS.TEST_RUNS,
        TagTypes.TEST_RUNS.TEST_CASE_HISTORY,
        TagTypes.TEST_RUNS.ALL_TESTRUN_EXECUTIONS_BY_TESTRUN_ID,
        TagTypes.PROJECTS.PROJECTS,
        TagTypes.TEST_RUNS.TEST_RUN_DETAILED,
      ],
    }),
    getTestcaseAttachmentUploadUrl: builder.query<
      FileUploadUrlResponse,
      GetTestcaseAttachmentUploadUrlInput
    >({
      query: (variables) => ({
        document: getTestcaseAttachmentUploadUrl,
        variables,
      }),
    }),
    getTestcaseAttachmentDownloadUrl: builder.query<
      FileDownloadUrlResponse,
      GetAttachmentDownloadUrlInput
    >({
      query: (variables) => ({
        document: getTestcaseAttachmentDownloadUrl,
        variables,
      }),
    }),
    getTestcaseDeletionWarnings: builder.query<
      GetTestCaseDeletionScenarioResponse[],
      GetTestCaseDeletionScenarioInput
    >({
      query: (variables) => ({
        document: getTestcaseDeletionWarnings,
        variables,
      }),
      transformResponse: (response: {
        getTestcaseTestrunAssociationTypesCountByTestcaseAndFolderIds: GetTestCaseDeletionScenarioResponse[];
      }) =>
        response?.getTestcaseTestrunAssociationTypesCountByTestcaseAndFolderIds,
    }),
    updateTestCaseFolderPosition: builder.mutation<
      TestCaseFolderType,
      UpdateTestCaseFolderByIdInput
    >({
      query: ({ id, updateTestcaseFolderInput }) => ({
        document: updateTestCaseFolderMutation,
        variables: {
          id,
          updateTestcaseFolderInput,
        },
      }),
      transformResponse: (response: {
        updateTestcaseFolderById: TestCaseFolderType;
      }) => response?.updateTestcaseFolderById,
      extraOptions: {
        successMessageKey:
          'testSuite/testSuite:updatePosition.folderSuccessMessage',
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            updateFolderPosition({
              response: data,
              updatedFolder: arg.updatedFolder,
            })
          );
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    updateTestCasePosition: builder.mutation<
      TestCaseType,
      UpdateTestCaseByIdInput
    >({
      query: ({ id, updateTestcaseInput }) => ({
        document: updateTestCaseMutation,
        variables: {
          id,
          updateTestcaseInput,
        },
      }),
      transformResponse: (response: { updateTestcaseById: TestCaseType }) =>
        response?.updateTestcaseById,
      extraOptions: {
        successMessageKey:
          'testSuite/testSuite:updatePosition.testCaseSuccessMessage',
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            updateCasePosition({
              response: { ...data, position: arg.updateTestcaseInput.position },
              updatedTestCase: arg.updatedTestCase,
            })
          );
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    createTestcases: builder.mutation<
      CreateTestcases,
      CreateTestcasesInputType
    >({
      query: (variables) => ({
        document: createTestcasesMutation,
        variables,
      }),
      transformResponse: (response: CreateTestcasesResponse) =>
        response.createTestcases,
    }),
    exportTestSuite: builder.query<
      ExportTestSuiteResponse,
      ExportTestSuiteInput
    >({
      query: (variables) => ({
        document: exportTestSuiteQuery,
        variables,
      }),
      transformResponse: (response: {
        exportTestSuite: ExportTestSuiteResponse;
      }) => response?.exportTestSuite,
    }),

    getFailedTestCaseExecutionDetails: builder.query<
      FailedTestCaseExecutionDetails,
      { id: string }
    >({
      query: (variables) => ({
        document: getFailedTestCaseExecutionDetailsQuery,
        variables,
      }),
      providesTags: [TagTypes.TEST_CASE.FAILED_TEST_CASE_DETAILS],
      transformResponse: (response: {
        testcaseById: FailedTestCaseExecutionDetails;
      }) => response?.testcaseById,
    }),

    getTestCaseExecutionDetails: builder.query<
      TestCaseExecutionDetails,
      { id: string }
    >({
      query: (variables) => ({
        document: getTestCaseExecutionDetailsQuery,
        variables,
      }),
      providesTags: [TagTypes.TEST_CASE.TEST_CASE_EXECUTION_DETAILS],
      transformResponse: (response: {
        testcaseById: TestCaseExecutionDetails;
      }) => response?.testcaseById,
    }),

    removeTestcaseAttachments: builder.mutation<boolean, string[]>({
      query: (keys) => ({
        document: removeTestcaseAttachmentsByKeysMutation,
        variables: {
          keys,
        },
      }),
    }),
    createImportJob: builder.mutation<ImportJob, CreateImportJobInput>({
      query: (variables) => ({
        document: createImportJobMutation,
        variables,
      }),
      invalidatesTags: [TagTypes.TEST_CASE.IMPORT_JOBS],
      transformResponse: (response: { createImportJob: ImportJob }) =>
        response?.createImportJob,
    }),
    getImportJobErrorFileUploadUrl: builder.query<
      ImportJobFileUploadUrlResponse,
      ImportJobErrorFileUploadUrlInput
    >({
      query: (variables) => ({
        document: getImportJobErrorFileUploadUrlQuery,
        variables,
      }),
    }),
    getImportJobErrorFileDownloadUrl: builder.query<
      ImportJobErrorFileDownloadUrl,
      GetAttachmentDownloadUrlInput
    >({
      query: (variables) => ({
        document: getImportJobErrorFileDownloadUrlQuery,
        variables,
      }),
    }),
    updateImportJob: builder.mutation<ImportJob, UpdateImportJobInput>({
      query: (variables) => ({
        document: updateImportJobMutation,
        variables,
      }),
      invalidatesTags: [TagTypes.TEST_CASE.IMPORT_JOBS],
      transformResponse: (response: { updateImportJob: ImportJob }) =>
        response?.updateImportJob,
    }),
    getAllImportJobs: builder.query<Page<ImportJob>, GetAllImportJobsPayload>({
      query: (payload) => ({
        document: getAllImportJobsQuery,
        variables: payload,
      }),
      providesTags: [TagTypes.TEST_CASE.IMPORT_JOBS],
      transformResponse: (response: { getAllImportJobs: Page<ImportJob> }) =>
        response.getAllImportJobs,
    }),
  }),
});

export const {
  useGetTestSuiteByProjectIdQuery,
  useLazyGetTestCaseFolderByIdQuery,
  useCreateTestCaseFolderMutation,
  useUpdateTestCaseFolderMutation,
  useDeleteTestCaseFolderMutation,
  useCreateTestCaseMutation,
  useGetTestCaseByIdQuery,
  useLazyGetTestCaseByIdQuery,
  useUpdateTestCaseMutation,
  useGetTestCasesByFolderIdQuery,
  useDeleteTestCasesByIdsMutation,
  useDeleteTestCasesByFolderIdsMutation,
  useLazyGetTestcaseAttachmentUploadUrlQuery,
  useLazyGetTestcaseAttachmentDownloadUrlQuery,
  useLazyGetTestcaseDeletionWarningsQuery,
  useUpdateTestCaseFolderPositionMutation,
  useUpdateTestCasePositionMutation,
  useCreateTestcasesMutation,
  useLazyExportTestSuiteQuery,
  useGetFailedTestCaseExecutionDetailsQuery,
  useGetTestCaseExecutionDetailsQuery,
  useRemoveTestcaseAttachmentsMutation,
  useCreateImportJobMutation,
  useLazyGetImportJobErrorFileUploadUrlQuery,
  useLazyGetImportJobErrorFileDownloadUrlQuery,
  useUpdateImportJobMutation,
  useGetAllImportJobsQuery,
} = testSuiteApis;
