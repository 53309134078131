import { PAGE_SIZE } from '@constants/components';
import { useI18n } from '@hooks';
import { FC } from 'react';
import { CaretLeft, CaretRight } from '@icons';

type PaginationProps = {
  paginateFront?: () => void;
  paginateBack?: () => void;
  currentPage?: number;
  limit: number;
  hasMore?: boolean;
  title: string;
  pageSize?: number;
  totalElements: number;
};
const Pagination: FC<PaginationProps> = (props) => {
  const {
    paginateFront = () => null,
    paginateBack = () => null,
    currentPage = 1,
    hasMore = false,
    limit,
    title,
    pageSize,
    totalElements,
  } = props;
  const { t } = useI18n(['common']);

  return (
    <div className='flex justify-end items-center py-2 my-4'>
      <div className='text-base font-medium text-dark-gray-secondary'>
        {`${t('common:showingResults', {
          startCount: currentPage * (pageSize ?? PAGE_SIZE) + 1,
          endCount: currentPage * (pageSize ?? PAGE_SIZE) + limit,
          totalCount: totalElements,
          title,
        })}`}
      </div>
      <div>
        <button
          type='button'
          disabled={currentPage === 0}
          onClick={paginateBack}
          className='items-center p-2 mx-1'
        >
          <CaretLeft opacity={currentPage === 0 ? '0.5' : '1'} />
        </button>
        <button
          type='button'
          disabled={!hasMore}
          onClick={paginateFront}
          className='items-center p-2 mx-1'
        >
          <CaretRight opacity={!hasMore ? '0.5' : '1'} />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
