import {
  Root,
  Item,
  Header,
  Trigger,
  Content,
} from '@radix-ui/react-accordion';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

const Accordion = ({
  items = [],
  type = 'single',
}: {
  type?: 'single' | 'multiple';
  items: {
    value: string;
    trigger: ReactNode | string;
    content?: ReactNode;
    triggerStyles?: string;
  }[];
}) => (
  <Root type={type} collapsible>
    {items.map((item) => (
      <Item value={item.value} key={item.value}>
        <Header className='w-full' asChild>
          <Trigger
            className={twMerge(
              `flex w-full items-center rounded-md px-4 py-2`,
              item.triggerStyles
            )}
          >
            {item.trigger}
          </Trigger>
        </Header>
        {item?.content ? (
          <Content className='mb-2 flex w-full'>{item.content}</Content>
        ) : null}
      </Item>
    ))}
  </Root>
);

export default Accordion;
