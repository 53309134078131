import { IS_LOGGED_IN } from '@constants/authentication';
import { getLocalStorageItem } from '@utils/storage';

import PublicLayout from './PublicLayout';
import PrivateLayout from './PrivateLayout';

function RouteLayout() {
  return getLocalStorageItem(IS_LOGGED_IN) ? (
    <PrivateLayout />
  ) : (
    <PublicLayout />
  );
}

export default RouteLayout;
