import { LegacyRef, RefObject, useMemo, useRef, useState } from 'react';
import { useCombobox } from 'downshift';
import { Link } from 'react-router-dom';

import { useClickOutside, useI18n } from '@hooks';
import { GlobalSearchIcon } from '@icons';
import { Spinner } from '@components';
import { injectParamsToRoute } from '@utils/navigation';
import { GlobalSearch, GlobalSearchEntityName } from '@customTypes/common';
import { GlobalSearchChanges } from '@customTypes/components';
import RoutePaths from '@routes/RoutesPath';
import { useAppDispatch } from '@store/store';
import { resetTestSuite } from '@containers/test-suite/store/reducer';

const entityNavigationMap = {
  [GlobalSearchEntityName.PROJECT]: RoutePaths.PROJECTS.OVERVIEW,
  [GlobalSearchEntityName.TEST_RUN]: RoutePaths.TEST_RUNS.OVERVIEW,
  [GlobalSearchEntityName.TEST_CASE]: RoutePaths.TEST_SUITE.DETAILS,
  [GlobalSearchEntityName.MILESTONE]: RoutePaths.MILESTONES.OVERVIEW,
};

interface GlobalSearchBoxProps {
  suggestions: GlobalSearch[];
  onInputChange: (changes: GlobalSearchChanges) => void;
  showLoader: boolean;
}

const GlobalSearchBox = ({
  suggestions,
  onInputChange,
  showLoader,
}: GlobalSearchBoxProps) => {
  const { t } = useI18n(['common']);

  const containerRef = useRef<HTMLElement>(null);
  const [showSuggestion, setShowSuggestion] = useState(false);

  const { isOpen, getMenuProps, getInputProps, getItemProps, inputValue } =
    useCombobox({
      items: suggestions,
      onInputValueChange: onInputChange,
    });

  const dispatch = useAppDispatch();

  const groupedSuggestions = useMemo(() => {
    const groupedMap: Map<GlobalSearchEntityName, GlobalSearch[]> = new Map();

    const addSuggestionToMap = (suggestion: GlobalSearch) => {
      if (suggestion?.entityName) {
        groupedMap.set(suggestion.entityName, [
          ...(groupedMap.get(suggestion.entityName) ?? []),
          suggestion,
        ]);
      }
    };

    suggestions?.forEach(addSuggestionToMap);
    return groupedMap;
  }, [suggestions]);

  const filteredEntities = Object.values(GlobalSearchEntityName).filter(
    (entityName) => !!groupedSuggestions.get(entityName)
  );

  useClickOutside({
    ref: containerRef,
    handler: () => setShowSuggestion(false),
  });

  return (
    <div
      onFocus={() => setShowSuggestion(!!inputValue?.length)}
      ref={containerRef as LegacyRef<HTMLDivElement>}
      className='relative ml-[30px]'
    >
      <div className=' flex items-center py-2 px-[12px] text-center bg-gray-100 rounded-md border border-spacing-2'>
        <GlobalSearchIcon className='mr-3' />
        <input
          {...getInputProps()}
          className='flex-1 mr-2 w-[370px] placeholder:text-blue-magenta-faded bg-gray-100 focus-visible:outline-none placeholder:opacity-100'
          placeholder={t('searchForAnything')}
        />
      </div>
      <ul
        {...getMenuProps()}
        className='overflow-auto absolute z-10 mt-2 w-full max-h-[500px] bg-white rounded shadow-default'
      >
        {(showSuggestion || isOpen) && !showLoader && suggestions?.length
          ? filteredEntities?.map((entityName) => (
              <>
                <p className='p-2 px-4 text-[13px] text-[#787486]'>
                  {t(`globalSearchEntityName.${entityName}`)}
                </p>
                {groupedSuggestions.get(entityName)?.map((suggestion) => (
                  <Link
                    {...getItemProps({ item: suggestion })}
                    key={suggestion.entityId}
                    to={injectParamsToRoute(
                      entityNavigationMap[suggestion.entityName],
                      {
                        projectId: suggestion.projectId,
                        milestoneId: suggestion.entityId,
                        testCaseId: suggestion.entityId,
                        testRunId: suggestion.entityId,
                      }
                    )}
                    onClick={() => {
                      setTimeout(() => dispatch(resetTestSuite()), 500);
                      // setTimeout() is used here to reset test suite only after the navigation has finished. Calling resetTestSuite() without timeout will clear the state before navigation has completed
                    }}
                    className='flex flex-row items-center py-2 px-4 w-full hover:bg-slate-100 cursor-pointer'
                  >
                    <div className='mr-2 w-3 h-3 bg-accent-primary rounded-md' />
                    <div className='flex-1 truncate'>
                      <p className='flex-1 text-sm truncate whitespace-pre break-all'>
                        {suggestion.name}
                      </p>
                      {entityName !== GlobalSearchEntityName.PROJECT ? (
                        <p className='flex-1 text-xs text-[#605E5E] truncate whitespace-pre break-all'>
                          {suggestion.projectName}
                        </p>
                      ) : null}
                    </div>
                  </Link>
                ))}
              </>
            ))
          : null}
        {isOpen && showLoader ? <Spinner className='my-3 mx-auto' /> : null}
        {(showSuggestion || isOpen) && !showLoader && !suggestions?.length ? (
          <p className='p-2 text-center text-blue-magenta-faded'>
            {t('common:noResultsToShow')}
          </p>
        ) : null}
      </ul>
    </div>
  );
};

export default GlobalSearchBox;
