import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  notifications: any[];
} = {
  notifications: [],
};

export const notificationsReducerSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    showNotifier: (state, { payload }) => {
      const id = Math.random();
      state.notifications = [
        ...state.notifications,
        { message: payload.message, type: payload.type, id },
      ];
    },
    hideNotifier: (state, { payload }) => {
      const NotificationsArray = state.notifications.filter(
        (item) => item.id !== payload
      );
      state.notifications = [...NotificationsArray];
    },
  },
});

export const { showNotifier, hideNotifier } = notificationsReducerSlice.actions;

export default notificationsReducerSlice.reducer;
