export enum MilestoneStatuses {
  NOT_STARTED = 'NOT_STARTED',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
}

export enum MilestoneActions {
  EDIT = 'edit',
  MARK_AS_COMPLETED = 'markAsCompleted',
  DELETE = 'delete',
}

export const PAGINATION_SIZE = 10;
