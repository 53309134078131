import { gql } from 'graphql-request';

export const getTestSuitByProjectId = gql`
  query getTestSuitByProjectId($projectId: UUID!) {
    getRootTestcaseFolder(projectId: $projectId) {
      id
      name
      subFolders {
        id
        name
        testcaseCount
      }
      testcases {
        id
        name
        extId
        position
      }
      testcaseCount
    }
  }
`;

export const getTestCaseFolderById = gql`
  query getTestCaseFolderById($id: UUID!) {
    testcaseFolderById(id: $id) {
      id
      name
      subFolders {
        id
        name
        testcaseCount
      }
      testcases {
        id
        name
        extId
        position
      }
      testcaseCount
    }
  }
`;

export const createTestCaseFolderMutation = gql`
  mutation createTestcaseFolder(
    $createTestcaseFolderInput: CreateTestcaseFolderInput!
  ) {
    createTestcaseFolder(
      createTestcaseFolderInput: $createTestcaseFolderInput
    ) {
      id
      name
    }
  }
`;

export const updateTestCaseFolderMutation = gql`
  mutation updateTestcaseFolderById(
    $id: UUID!
    $updateTestcaseFolderInput: UpdateTestcaseFolderInput!
  ) {
    updateTestcaseFolderById(
      id: $id
      updateTestcaseFolderInput: $updateTestcaseFolderInput
    ) {
      id
      name
      parentFolder {
        id
        name
      }
      position
    }
  }
`;

export const deleteTestCaseFolderMutation = gql`
  mutation deleteTestcaseFolderById($id: UUID!) {
    deleteTestcaseFolderById(id: $id)
  }
`;

export const createTestCaseMutation = gql`
  mutation createTestcase($createTestcaseInput: CreateTestcaseInput!) {
    createTestcase(createTestcaseInput: $createTestcaseInput) {
      id
      extId
    }
  }
`;

export const updateTestCaseMutation = gql`
  mutation updateTestcaseById(
    $id: UUID!
    $updateTestcaseInput: UpdateTestcaseInput!
  ) {
    updateTestcaseById(id: $id, updateTestcaseInput: $updateTestcaseInput) {
      id
      extId
      position
      folder {
        id
      }
    }
  }
`;

export const getTestCaseById = gql`
  query getTestCaseById($id: UUID!) {
    testcaseById(id: $id) {
      id
      extId
      name
      folder {
        id
      }
      feature
      type
      tag
      template
      automation
      precondition
      priority
      estimatedTime
      estimatedTimeUnit
      attachments {
        preconditionAttachments {
          key
          fileName
        }
      }
      testcaseSteps {
        id
        step
        expected
        position
        attachments {
          stepAttachments {
            key
            fileName
          }
          expectedAttachments {
            key
            fileName
          }
        }
      }
      testcaseHistory {
        action
        date
        updatedUsersName
      }
    }
  }
`;
export const getTestCasesByFolderId = gql`
  query getTestCasesByFolderId(
    $projectId: UUID!
    $folderId: UUID!
    $paginationInput: PaginationInput
  ) {
    allTestcasesByFolderId(
      projectId: $projectId
      folderId: $folderId
      paginationInput: $paginationInput
    ) {
      content {
        id
        name
      }
      totalPages
      totalElements
      pageable {
        pageNumber
      }
      last
    }
  }
`;

export const deleteTestCasesMutation = gql`
  mutation deleteTestcasesByIds(
    $ids: [UUID]!
    $shouldDeleteExecutedCase: Boolean!
  ) {
    deleteTestcasesByIds(
      ids: $ids
      shouldDeleteExecutedCase: $shouldDeleteExecutedCase
    )
  }
`;

export const deleteTestCasesByFolderIdMutation = gql`
  mutation deleteTestcaseFoldersByIds(
    $ids: [UUID]!
    $shouldDeleteExecutedCase: Boolean!
  ) {
    deleteTestcaseFoldersByIds(
      ids: $ids
      shouldDeleteExecutedCase: $shouldDeleteExecutedCase
    )
  }
`;

export const getTestcaseAttachmentUploadUrl = gql`
  query getTestcaseAttachmentUploadUrl(
    $projectId: UUID!
    $contentType: String!
  ) {
    getTestcaseAttachmentUploadUrl(
      projectId: $projectId
      contentType: $contentType
    ) {
      url
      key
    }
  }
`;

export const getTestcaseAttachmentDownloadUrl = gql`
  query getTestcaseAttachmentDownloadUrl($key: String!, $contentType: String!) {
    getTestcaseAttachmentDownloadUrl(key: $key, contentType: $contentType) {
      url
    }
  }
`;

export const getTestcaseDeletionWarnings = gql`
  query getTestcaseTestrunAssociationTypesCountByTestcaseAndFolderIds(
    $testcaseIds: [UUID]!
    $testcaseFolderIds: [UUID]!
  ) {
    getTestcaseTestrunAssociationTypesCountByTestcaseAndFolderIds(
      ids: $testcaseIds
      folderIds: $testcaseFolderIds
    ) {
      scenario
      count
    }
  }
`;

export const createTestcasesMutation = gql`
  mutation createTestcases(
    $createTestcasesInputs: [CreateTestcasesInput]!
    $projectId: UUID!
    $folderId: UUID!
    $importJobId: UUID!
  ) {
    createTestcases(
      createTestcasesInputs: $createTestcasesInputs
      projectId: $projectId
      folderId: $folderId
      importJobId: $importJobId
    ) {
      createdTestcases {
        id
        name
      }
      failedTestcases {
        name
        folderHierarchy
        feature
        type
        tag
        template
        automation
        precondition
        priority
        testcaseSteps {
          step
          expected
          position
        }
        estimatedTime
        estimatedTimeUnit
        errorCode
      }
    }
  }
`;
export const exportTestSuiteQuery = gql`
  query exportTestSuite(
    $projectId: UUID!
    $testcaseIds: [UUID]
    $folderIds: [UUID]
    $exportType: ExportType!
  ) {
    exportTestSuite(
      projectId: $projectId
      testcaseIds: $testcaseIds
      folderIds: $folderIds
      exportType: $exportType
    ) {
      url
    }
  }
`;

export const getFailedTestCaseExecutionDetailsQuery = gql`
  query getFailedTestCaseExecutionDetailsQuery($id: UUID!) {
    testcaseById(id: $id) {
      failedExecutions {
        testrunName
        executedBy
        latestStatus
      }
      failedExecutionsSummary {
        totalTestedCount
        totalAssociatedTestrunCount
      }
    }
  }
`;

export const getTestCaseExecutionDetailsQuery = gql`
  query getTestCaseExecutionDetailsQuery($id: UUID!) {
    testcaseById(id: $id) {
      attachedTestrunExecutions {
        testcaseStatus
        testrunName
        statusChangedOn
        userName
        userProfilePicture
      }
      testcaseExecutionSummary
    }
  }
`;

export const removeTestcaseAttachmentsByKeysMutation = gql`
  mutation removeTestcaseAttachmentsByKeys($keys: [String]) {
    removeTestcaseAttachmentsByKeys(keys: $keys)
  }
`;

export const createImportJobMutation = gql`
  mutation createImportJobMutation(
    $projectId: UUID!
    $createImportJobInput: CreateImportJobInput!
  ) {
    createImportJob(
      projectId: $projectId
      createImportJobInput: $createImportJobInput
    ) {
      id
      createdBy {
        id
        name
        email
      }
      projectId
      status
      totalRecords
      failedRecords
      errorFileInfo {
        key
        fileName
      }
    }
  }
`;

export const getImportJobErrorFileUploadUrlQuery = gql`
  query getImportJobErrorFileUploadUrlQuery(
    $projectId: UUID!
    $importJobId: UUID!
    $contentType: String!
  ) {
    getImportJobErrorFileUploadUrl(
      projectId: $projectId
      importJobId: $importJobId
      contentType: $contentType
    ) {
      url
      key
    }
  }
`;

export const getImportJobErrorFileDownloadUrlQuery = gql`
  query getImportJobErrorFileDownloadUrlQuery(
    $key: String!
    $contentType: String!
  ) {
    getImportJobErrorFileDownloadUrl(key: $key, contentType: $contentType) {
      url
    }
  }
`;

export const updateImportJobMutation = gql`
  mutation updateImportJobMutation(
    $importJobId: UUID!
    $updateImportJobInput: UpdateImportJobInput!
  ) {
    updateImportJob(
      importJobId: $importJobId
      updateImportJobInput: $updateImportJobInput
    ) {
      id
      status
      errorFileInfo {
        key
        fileName
      }
    }
  }
`;

export const getAllImportJobsQuery = gql`
  query getAllImportJobsQuery(
    $projectId: UUID!
    $paginationInput: PaginationInput
  ) {
    getAllImportJobs(projectId: $projectId, paginationInput: $paginationInput) {
      content {
        id
        createdBy {
          id
          name
          email
        }
        createdAt
        projectId
        status
        totalRecords
        failedRecords
        errorFileInfo {
          key
          fileName
        }
      }
      totalPages
      totalElements
      pageable {
        pageNumber
      }
      last
    }
  }
`;
