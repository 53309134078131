import { ExportType } from '@constants/test-cases/testcases';
import { TEST_CASE_SCENARIO_WARNINGS } from '@constants/test-suite/deleteTestCase';
import { Project } from '@customTypes/projects';
import { TimeUnit } from '@constants/test-suite/addTestCase';
import { TestcaseStatus } from '@constants/components';
import { PaginationInput } from '@customTypes/common';
// eslint-disable-next-line import/no-cycle
import { TestcaseFolder } from '../test-runs/types';

export interface FileInput {
  key: string;
  fileName: string;
}

export interface TestcaseStepAttachments {
  stepAttachments?: FileInput[];
  expectedAttachments?: FileInput[];
}

export interface TestcaseAttachments {
  preconditionAttachments: FileInput[];
}

export interface CreateTestCaseStepInput {
  id?: string;
  step: string;
  expected: string;
  position: number;
  attachments?: TestcaseStepAttachments;
}

export interface CreateTestCaseInput {
  name: string;
  projectId?: string;
  folderId?: string;
  extId?: string;
  feature?: string;
  type?: string;
  tag?: string;
  template?: string;
  automation?: string;
  precondition?: string;
  priority?: string;
  testcaseSteps?: CreateTestCaseStepInput[];
  attachments?: TestcaseAttachments;
  estimatedTime?: number | null;
  estimatedTimeUnit?: TimeUnit | null;
}

export interface UpdateTestCaseInput extends CreateTestCaseInput {
  id: string;
  createStep?: CreateTestCaseStepInput[];
  updateStep?: CreateTestCaseStepInput[];
  deleteStep?: string[];
}

export interface TestCaseStep {
  id: string;
  tenantId: string;
  step: string;
  expected: string;
  position: number;
  attachments?: TestcaseStepAttachments;
}

export interface AttachedTestrunExecutions {
  testcaseStatus: TestcaseStatus;
  testrunName: string;
  statusChangedOn: string;
  userName: string;
  userProfilePicture: string;
}

export interface TestCase {
  id: string;
  extId: string;
  name: string;
  tenantId: string;
  description: string;
  project: Project;
  feature?: string;
  type?: string;
  tag?: string;
  template?: string;
  automation?: string;
  precondition?: string;
  priority?: string;
  testcaseSteps?: TestCaseStep[];
  folder: TestcaseFolder;
  position: number;
  deletedAt?: string;
  attachments?: TestcaseAttachments;
  estimatedTime?: number;
  estimatedTimeUnit?: TimeUnit;
  attachedTestrunExecutions: AttachedTestrunExecutions[];
  testcaseExecutionSummary: {
    [key: string]: { [status: string]: number };
  };
  testcaseHistory: {
    action: string;
    date: string;
    updatedUsersName: string;
  }[];
}

export interface CreateTestCaseResponse {
  createTestcase: TestCase;
}
export interface TestCaseType {
  id: string;
  name: string;
  parentFolderId?: string;
  extId?: string;
  path?: string;
  position: number;
  folder?: {
    id: string;
    name: string;
  };
}

export interface TestCaseFolderType extends TestCaseType {
  subFolders?: TestCaseFolderType[];
  testcases?: TestCaseType[];
  parentFolderId?: string;
  testcaseCount: number;
  isExpanded?: boolean;
  isTestCasesExpanded?: boolean;
  parentFolder?: {
    id: string;
    name: string;
  };
}

export interface GetTestSuitByProjectIdPayload {
  projectId: string;
}

export interface CreateTestCaseFolderInput {
  id?: string;
  name: string;
  projectId: string;
  parentFolderId?: string | null;
}

export type UpdateTestCaseFolderInput = Pick<
  CreateTestCaseFolderInput,
  'id' | 'name' | 'parentFolderId'
>;

export interface GetTestCasesByFolderIdPayload {
  projectId: string;
  folderId: string | null;
}

export interface DeleteTestCasesResponse {
  deleteTestcasesByIds: boolean;
}

export interface DeleteTestCasesFoldersResponse {
  deleteTestcaseFoldersByIds: boolean;
}

export interface FileUploadUrlResponse {
  getTestcaseAttachmentUploadUrl: {
    url: string;
    key: string;
  };
}

export interface FileDownloadUrlResponse {
  getTestcaseAttachmentDownloadUrl: {
    url: string;
  };
}

export interface GetTestcaseAttachmentUploadUrlInput {
  projectId: string;
  contentType: string;
}

export interface GetAttachmentDownloadUrlInput {
  key: string;
  contentType: string;
}

export interface GetTestCaseDeletionScenarioResponse {
  scenario: TEST_CASE_SCENARIO_WARNINGS;
  count: number;
}

export interface GetTestCaseDeletionScenarioInput {
  testcaseIds: string[];
  testcaseFolderIds: string[];
}

export interface DeleteTestCasePayload {
  ids: string[];
  shouldDeleteExecutedCase: boolean;
}

export interface UpdateTestcaseFolderPositionInput {
  name?: string;
  parentFolderId?: string;
  position?: number;
}

export interface UpdateTestCaseFolderByIdInput {
  id: string;
  updateTestcaseFolderInput: UpdateTestcaseFolderPositionInput;
  updatedFolder: TestCaseFolderType;
}

export interface UpdateTestcasePositionInput {
  folderId?: string;
  position?: number;
}

export interface UpdateTestCaseByIdInput {
  id: string;
  updateTestcaseInput: UpdateTestcasePositionInput;
  updatedTestCase: TestCaseType;
}

export interface ExportTestSuiteInput {
  projectId: string;
  testcaseIds?: string[];
  folderIds?: string[];
  exportType: ExportType;
}

export interface ExportTestSuiteResponse {
  url: string;
}

export interface DeleteTestCaseFromTestRun {
  testcaseId: string;
  testrunId: string;
}

export interface DeleteTestCaseFromTestRunResponse {
  deleteTestcaseFromTestrun: boolean;
}

export type FailedTestcaseExecutions = {
  testrunName: string;
  executedBy: string;
  latestStatus: TestcaseStatus;
};

export type FailedTestcaseExecutionsSummary = {
  totalTestedCount: number;
  totalAssociatedTestrunCount: number;
};

export type FailedTestCaseExecutionDetails = {
  failedExecutions?: FailedTestcaseExecutions[];
  failedExecutionsSummary?: FailedTestcaseExecutionsSummary;
};

export type TestCaseExecutionDetails = {
  testcaseExecutionSummary: {
    [key: string]: { [status: string]: number };
  };
  attachedTestrunExecutions: AttachedTestrunExecutions[];
};

export type CreateImportJobInput = {
  projectId: string;
  createImportJobInput: {
    totalRecords: number;
  };
};

export enum ImportJobStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  COMPLETED_WITH_ERRORS = 'COMPLETED_WITH_ERRORS',
  COMPLETED_WITH_WARNINGS = 'COMPLETED_WITH_WARNINGS',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export type ImportJob = {
  id: string;
  createdBy: {
    id: string;
    name: string;
    email: string;
  };
  projectId: string;
  status?: ImportJobStatus;
  totalRecords?: number;
  failedRecords?: number;
  errorFileInfo?: FileInput;
  createdAt?: string;
};

export type ImportJobErrorFileUploadUrlInput = {
  projectId: string;
  importJobId: string;
  contentType: string;
};

export type ImportJobFileUploadUrlResponse = {
  getImportJobErrorFileUploadUrl: {
    url: string;
    key: string;
  };
};

export interface ImportJobErrorFileDownloadUrl {
  getImportJobErrorFileDownloadUrl: {
    url: string;
  };
}

export interface UpdateImportJobInput {
  importJobId: string;
  updateImportJobInput: {
    status: ImportJobStatus;
    errorFileInfo?: FileInput;
  };
}

export interface GetAllImportJobsPayload {
  paginationInput: PaginationInput;
  projectId: string;
}
