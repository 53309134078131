import { TagTypes } from '@constants/common';
import { PermissionsForUser } from '@customTypes/permission';
import {
  CreateProjectInput,
  Project,
  CreateProjectResponse,
  ProjectPage,
  UpdateProjectResponse,
  GetProjectDetailsResponse,
  DeleteProjectByIdResponse,
  SearchProjectsResponse,
  UpdateProjectInput,
  ProjectSummaryInput,
} from '@customTypes/projects';
import api from '@services/api';
import {
  createProjectMutation,
  deleteProjectMutation,
  getProjectDetailsQuery,
  getProjectsQuery,
  searchProjectsQuery,
  updateProjectMutation,
  getProjectPermissionsForUserQuery,
} from './queries';

const projectsApis = api.injectEndpoints({
  endpoints: (builder) => ({
    createProject: builder.mutation<Project, CreateProjectInput>({
      query: (createProjectInput) => ({
        document: createProjectMutation,
        variables: {
          createProjectInput,
        },
      }),
      transformResponse: (response: CreateProjectResponse) =>
        response?.createProject,
      extraOptions: {
        successMessageKey: 'projects/addProject:addSuccess',
      },
    }),
    getProjects: builder.query<ProjectPage, ProjectSummaryInput>({
      query: ({ paginationInput, sortBy, sortDirection }) => ({
        document: getProjectsQuery,
        variables: {
          paginationInput,
          sortBy,
          sortDirection,
        },
      }),
      keepUnusedDataFor: 10,
      providesTags: [TagTypes.PROJECTS.PROJECTS],
      transformResponse: (response: {
        getProjectSummaryForUser: ProjectPage;
      }) => response?.getProjectSummaryForUser,
      // TODO: Update project cache manually on project add and edit
    }),
    updateProject: builder.mutation<Project, UpdateProjectInput>({
      query: (updateProjectInput) => ({
        document: updateProjectMutation,
        variables: {
          updateProjectInput,
        },
      }),
      invalidatesTags: [
        TagTypes.PROJECTS.PROJECT_DETAILS,
        TagTypes.USERS.USERS,
      ],
      transformResponse: (response: UpdateProjectResponse) =>
        response?.updateProject,
      extraOptions: {
        successMessageKey: 'projects/addProject:updateSuccess',
      },
    }),
    deleteProject: builder.mutation<boolean, string>({
      query: (id) => ({
        document: deleteProjectMutation,
        variables: {
          id,
        },
      }),
      invalidatesTags: [TagTypes.PROJECTS.PROJECTS, TagTypes.USERS.USERS],
      transformResponse: (response: DeleteProjectByIdResponse) =>
        response?.deleteProjectById,
      extraOptions: {
        successMessageKey: 'projects/projectList:deletedSuccessfully',
        failureMessageKey: 'projects/projectList:deletionFailed',
      },
    }),
    getProjectDetails: builder.query<Project, string>({
      query: (id) => ({
        document: getProjectDetailsQuery,
        variables: {
          id,
        },
      }),
      providesTags: [TagTypes.PROJECTS.PROJECT_DETAILS],
      transformResponse: (response: GetProjectDetailsResponse) =>
        response?.getProjectById,
    }),
    searchProjects: builder.query<Project[], string>({
      query: (searchTerm) => ({
        document: searchProjectsQuery,
        variables: {
          searchTerm,
        },
      }),
      providesTags: [TagTypes.PROJECTS.PROJECTS],
      transformResponse: (response: SearchProjectsResponse) =>
        response?.searchProjects,
    }),
    getProjectPermissionsForUser: builder.query<PermissionsForUser[], string>({
      query: (projectId) => ({
        document: getProjectPermissionsForUserQuery,
        variables: {
          projectId,
        },
      }),
      providesTags: (result, error, arg) => [
        {
          type: TagTypes.PERMISSIONS_FOR_USER.PROJECT_PERMISSIONS_FOR_USER,
          id: arg,
        },
      ],
      transformResponse: (response: {
        getProjectPermissionsForUser: PermissionsForUser[];
      }) => response?.getProjectPermissionsForUser,
    }),
  }),
});

export const {
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useGetProjectsQuery,
  useLazyGetProjectsQuery,
  useUpdateProjectMutation,
  useGetProjectDetailsQuery,
  useLazySearchProjectsQuery,
  useGetProjectPermissionsForUserQuery,
  useLazyGetProjectPermissionsForUserQuery,
} = projectsApis;
