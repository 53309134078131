import { Suspense, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { FullPageSpinner } from '@components';
import { useGetUserQuery } from '@containers/login/store/api';
import SideNavigation from '@containers/side-navigation/SideNavigation';
import NotifierStack from '@containers/notification/Notification';
import Header from '@containers/header/Header';
import { lazyWithRetry } from '@utils/navigation';

import RoutePaths from './RoutesPath';

const PageNotFound = lazyWithRetry(() => import('@pages/PageNotFound'));
const Projects = lazyWithRetry(
  () => import('@containers/projects/ProjectList')
);
const MemberUser = lazyWithRetry(
  () => import('@containers/members/users/UserList')
);
const MilestonesPage = lazyWithRetry(
  () => import('@containers/milestones/MilestoneList')
);
const AddMilestonePage = lazyWithRetry(
  () => import('@containers/milestones/add-milestone/AddMilestone')
);
const MilestoneOverview = lazyWithRetry(
  () => import('@containers/milestones/MilestoneOverview')
);
const ProjectOverview = lazyWithRetry(
  () => import('@containers/projects/ProjectOverview')
);
const AddTestRun = lazyWithRetry(
  () => import('@containers/test-runs/AddTestRun')
);
const TestSuite = lazyWithRetry(
  () => import('@containers/test-suite/test-suite/TestSuite')
);
const TestRuns = lazyWithRetry(
  () => import('@containers/test-runs/TestRunsList')
);
const TestRunOverview = lazyWithRetry(
  () => import('@containers/test-runs/TestRunOverview/TestRunOverview')
);
const AddTestCase = lazyWithRetry(
  () => import('@containers/test-suite/add-testcase/AddTestCase')
);
const ViewTestCase = lazyWithRetry(
  () =>
    import('@containers/test-suite/testcase-details/ViewTestCaseDetailsPage')
);
const ImportTestCase = lazyWithRetry(
  () => import('@containers/test-cases/import-testcase/ImportTestCase')
);

const ImportHistory = lazyWithRetry(
  () => import('@containers/test-cases/import-testcase/ImportHistory')
);

const RoleSettings = lazyWithRetry(
  () => import('@containers/members/RoleSettings')
);

const PrivateLayout = () => {
  const [isBarExpanded, setBarExpanded] = useState(true);
  const { data } = useGetUserQuery();

  return (
    <>
      <SideNavigation
        isBarExpanded={isBarExpanded}
        onBottomCaretClick={() => setBarExpanded(!isBarExpanded)}
        user={data}
      />
      <Header user={data} isBarExpanded={isBarExpanded} />
      <div
        className={`${
          isBarExpanded
            ? 'ml-[var(--expanded-sidenav-width)] w-[calc(100%-var(--expanded-sidenav-width))]'
            : 'ml-[var(--collapsed-sidenav-width)] w-[calc(100%-var(--collapsed-sidenav-width))]'
        } relative transition-all duration-[var(--sidenav-animation-duration)] motion-reduce:transition-none z-0`}
      >
        <div className='page'>
          <Suspense fallback={<FullPageSpinner />}>
            <Routes>
              <Route
                path={RoutePaths.LOGIN}
                element={<Navigate to={RoutePaths.PROJECTS.ROOT} replace />}
              />
              <Route
                path='/'
                element={<Navigate to={RoutePaths.PROJECTS.ROOT} replace />}
              />
              <Route path={RoutePaths.PROJECTS.ROOT} element={<Projects />} />
              <Route
                path={RoutePaths.MILESTONES.ROOT}
                element={<MilestonesPage />}
              />
              <Route
                path={RoutePaths.MILESTONES.OVERVIEW}
                element={<MilestoneOverview />}
              />
              <Route
                path={RoutePaths.MILESTONES.ADD}
                element={<AddMilestonePage />}
              />
              <Route
                path={RoutePaths.MILESTONES.EDIT}
                element={<AddMilestonePage />}
              />
              <Route
                path={RoutePaths.PROJECTS.OVERVIEW}
                element={<ProjectOverview />}
              />
              <Route path={RoutePaths.TEST_RUNS.ADD} element={<AddTestRun />} />
              <Route
                path={RoutePaths.TEST_SUITE.ROOT}
                element={<TestSuite />}
              />
              <Route
                path={RoutePaths.TEST_SUITE.DETAILS}
                element={<ViewTestCase />}
              />
              <Route
                path={RoutePaths.TEST_SUITE.ADD}
                element={<AddTestCase />}
              />
              <Route
                path={RoutePaths.TEST_SUITE.EDIT}
                element={<AddTestCase />}
              />
              <Route
                path={RoutePaths.TEST_SUITE.IMPORT}
                element={<ImportTestCase />}
              />
              <Route path={RoutePaths.MEMBERS.USERS} element={<MemberUser />} />
              <Route
                path={RoutePaths.MEMBERS.ROLES}
                element={<RoleSettings />}
              />
              <Route path={RoutePaths.ALL} element={<PageNotFound />} />
              <Route path={RoutePaths.TEST_RUNS.ROOT} element={<TestRuns />} />
              <Route
                path={RoutePaths.TEST_RUNS.OVERVIEW}
                element={<TestRunOverview />}
              />
              <Route
                path={RoutePaths.TEST_RUNS.EDIT}
                element={<AddTestRun />}
              />
              <Route
                path={RoutePaths.TEST_SUITE.IMPORT_HISTORY}
                element={<ImportHistory />}
              />
              <Route path={RoutePaths.ALL} element={<PageNotFound />} />
            </Routes>
          </Suspense>
        </div>
      </div>
      <NotifierStack />
    </>
  );
};

export default PrivateLayout;
