import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import api from '@services/api';
// eslint-disable-next-line import/no-cycle
import rootReducer from './reducer';

const { reducerPath, reducer, middleware: apiMiddleware } = api;

const middlewares = [apiMiddleware];

const store = configureStore({
  reducer: {
    rootReducer,
    [reducerPath]: reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares),
});

setupListeners(store.dispatch);

type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
export type RootState = ReturnType<typeof store.getState>;
