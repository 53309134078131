import { useState } from 'react';

import { useCreateTestcasesMutation } from '@containers/test-suite/store/api';
import { IMPORT_TESTCASES_MAX_BATCH_COUNT } from '@constants/test-cases/testcases';
import { getCreateTestCasesInput } from '@utils/importTestCase';
import {
  CreateTestcasesErrorCode,
  FailedTestcase,
  ImportedTestCase,
} from '@customTypes/importTestCase';

interface BatchTestCaseCreationProps {
  projectId: string;
  rootFolderId: string | undefined;
  importJobId: string | null;
  onComplete: (backEndFailedTestCase: FailedTestcase[]) => void;
}

const useBatchTestcaseCreation = ({
  projectId,
  onComplete,
  rootFolderId,
  importJobId,
}: BatchTestCaseCreationProps) => {
  const [uploadedBatchCount, setUploadedBatchCount] = useState(0);

  const [createTestcases] = useCreateTestcasesMutation();

  const createTestCasesBatchWise = async (
    importedTestCases: ImportedTestCase[]
  ) => {
    const failedTestCases: FailedTestcase[] = [];
    const createTestcasesInputs = getCreateTestCasesInput(
      importedTestCases?.filter(
        (testCase) => !testCase.internalErrors?.length
      ) ?? []
    );

    if (createTestcasesInputs?.length && rootFolderId && importJobId) {
      let startIndex = 0;
      do {
        setUploadedBatchCount((prev) => prev + 1);

        const endIndex = startIndex + IMPORT_TESTCASES_MAX_BATCH_COUNT;
        const currentBatch = createTestcasesInputs.slice(
          startIndex,
          endIndex < createTestcasesInputs?.length ? endIndex : undefined
        );

        try {
          // eslint-disable-next-line no-await-in-loop
          const resp = await createTestcases({
            projectId,
            folderId: rootFolderId,
            createTestcasesInputs: currentBatch,
            importJobId,
          })?.unwrap();
          if (resp.failedTestcases?.length) {
            failedTestCases.push(...resp.failedTestcases);
          }
        } catch (error) {
          failedTestCases.push(
            ...currentBatch.map((testCase) => ({
              ...testCase,
              errorCode: CreateTestcasesErrorCode.BATCH_FAILED,
            }))
          );
        }

        startIndex += IMPORT_TESTCASES_MAX_BATCH_COUNT;
      } while (startIndex < createTestcasesInputs?.length);
      setUploadedBatchCount(0);
      onComplete(failedTestCases);
    }
  };
  return {
    createTestCasesBatchWise,
    uploadedBatchCount,
  };
};

export default useBatchTestcaseCreation;
