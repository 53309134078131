import { gql } from 'graphql-request';

export const createTestRunMutation = gql`
  mutation createTestRun($createTestRunInput: CreateTestrunInput!) {
    createTestrun(createTestrunInput: $createTestRunInput) {
      id
    }
  }
`;

export const getTestRunsQuery = gql`
  query getTestRuns(
    $projectId: UUID!
    $filter: TestrunFilter
    $paginationInput: PaginationInput
  ) {
    allTestrunsByProjectId(
      projectId: $projectId
      filter: $filter
      paginationInput: $paginationInput
    ) {
      totalPages
      totalElements
      pageable {
        pageNumber
      }
      last
      content {
        id
        name
        description
        testrunSummary {
          notStartedCount
          inProgressCount
          completedCount
        }
        totalTestcaseSummary {
          blockedCount
          retestCount
          passedCount
          failedCount
          untestedCount
          inProgressCount
        }
        latestTestcaseSummary {
          blockedCount
          retestCount
          passedCount
          failedCount
          untestedCount
          inProgressCount
        }
      }
    }
  }
`;

export const getAllTestrunExecutionsByTestrunIdQuery = gql`
  query getAllTestrunExecutionsByTestrunId(
    $projectId: UUID!
    $testrunId: UUID!
    $paginationInput: PaginationInput
  ) {
    getAllTestrunExecutionsByTestrunId(
      projectId: $projectId
      testrunId: $testrunId
      paginationInput: $paginationInput
    ) {
      content {
        id
        status
        testrunExecTestcaseSummary
        dateStarted
        dateEnded
      }
    }
  }
`;

export const getTestrunExecutionDataQuery = gql`
  query getAllTestrunExecutionsByTestrunId(
    $projectId: UUID!
    $testrunId: UUID!
    $paginationInput: PaginationInput
  ) {
    getAllTestrunExecutionsByTestrunId(
      projectId: $projectId
      testrunId: $testrunId
      paginationInput: $paginationInput
    ) {
      content {
        id
        status
        testrunExecTestcaseSummary
        dateStarted
        dateEnded
        allVersions
        testedUsers
        testcaseExecStatusCount {
          retestedCount
          failedCount
          testedCount
          uniqueRetestedCount
          uniqueFailedCount
          uniqueTestedCount
        }
      }
    }
  }
`;

export const getTestRunsWithQueryParamsQuery = gql`
  query getTestRuns(
    $projectId: UUID!
    $filter: TestrunFilter
    $paginationInput: PaginationInput
  ) {
    allTestrunsByProjectId(
      projectId: $projectId
      filter: $filter
      paginationInput: $paginationInput
    ) {
      totalPages
      totalElements
      pageable {
        pageNumber
      }
      last
      content {
        id
        name
        milestones {
          id
          name
        }
      }
    }
  }
`;

export const getTestCaseExecutionHistoryQuery = gql`
  query getTestCaseExecutionHistory($testRunExecId: UUID!, $testCaseId: UUID!) {
    getTestcaseStatusHistoryByTestrunExecId(
      testrunExecId: $testRunExecId
      testcaseId: $testCaseId
    ) {
      status
      updatedAt
      comments
      reference
      attachments {
        key
        fileName
      }
      updatedBy {
        name
      }
    }
  }
`;

export const searchTestrunsQuery = gql`
  query searchTestruns($searchTerm: String!, $projectId: UUID!) {
    searchTestruns(searchTerm: $searchTerm, projectId: $projectId) {
      id
      name
      milestones {
        id
        name
      }
    }
  }
`;

export const getTestcaseExecutionsByTestrunExecIdQuery = gql`
  query getTestcaseExecutionsByTestrunExecId($testrunExecutionId: UUID!) {
    getTestcaseExecutionsByTestrunExecId(
      testrunExecutionId: $testrunExecutionId
    ) {
      id
      testcase {
        id
        deletedAt
        name
        extId
        feature
        type
        tag
        position
        template
        automation
        attachments {
          preconditionAttachments {
            key
            fileName
          }
        }
        precondition
        priority
        testcaseSteps {
          id
          step
          expected
          position
          attachments {
            stepAttachments {
              key
              fileName
            }
            expectedAttachments {
              key
              fileName
            }
          }
        }
        folder {
          id
          name
          path
          position
          pathPosition
        }
      }
      status
      statusChangeCount
    }
  }
`;

export const updateTestrunByIdMutation = gql`
  mutation updateTestrunById(
    $id: UUID!
    $updateTestrunInput: UpdateTestrunInput!
  ) {
    updateTestrunById(id: $id, updateTestrunInput: $updateTestrunInput) {
      id
    }
  }
`;

export const updateTestcaseExecutionStatusMutation = gql`
  mutation updateTestcaseExecutionStatus(
    $testcaseExecutionId: UUID!
    $newStatus: TestcaseStatus!
    $statusChangeInput: TCExecutionStatusChangeInput
  ) {
    updateTestcaseExecutionStatus(
      testcaseExecutionId: $testcaseExecutionId
      newStatus: $newStatus
      statusChangeInput: $statusChangeInput
    ) {
      id
    }
  }
`;

export const testrunByIdQuery = gql`
  query testrunById($id: UUID!) {
    testrunById(id: $id) {
      id
      name
      enableAutoStart
      description
      startDate
      endDate
      totalTestcaseSummary {
        blockedCount
        retestCount
        passedCount
        failedCount
        untestedCount
        inProgressCount
      }
    }
  }
`;

export const deleteTestRunMutation = gql`
  mutation deleteTestrunById($id: UUID!) {
    deleteTestrunById(id: $id)
  }
`;

export const updateTestrunExecutionStatusMutation = gql`
  mutation updateTestrunExecutionStatus(
    $testrunExecutionId: UUID!
    $newStatus: TestrunStatus!
  ) {
    updateTestrunExecutionStatus(
      testrunExecutionId: $testrunExecutionId
      newStatus: $newStatus
    ) {
      id
      status
    }
  }
`;

export const getTestRunDetailedByIdQuery = gql`
  query getTestRunDetailedById($id: UUID!) {
    testrunById(id: $id) {
      id
      name
      description
      startDate
      enableAutoStart
      endDate
      version
      assignedTo {
        id
        name
      }
      attachedReferences
      testrunSummary {
        notStartedCount
        inProgressCount
        completedCount
      }
      testcases {
        id
        name
        folder {
          id
          name
          path
        }
      }
      milestones {
        id
        name
      }
    }
  }
`;

export const getTestcaseStatusChangeAttachmentUploadUrlQuery = gql`
  query getTestcaseStatusChangeAttachmentUploadUrl(
    $projectId: UUID!
    $testrunId: UUID!
    $testcaseId: UUID!
    $contentType: String!
  ) {
    getTestcaseStatusChangeAttachmentUploadUrl(
      projectId: $projectId
      testrunId: $testrunId
      testcaseId: $testcaseId
      contentType: $contentType
    ) {
      url
      key
    }
  }
`;

export const getTestcaseStatusChangeAttachmentDownloadUrlQuery = gql`
  query getTestcaseStatusChangeAttachmentDownloadUrl(
    $key: String!
    $contentType: String!
  ) {
    getTestcaseStatusChangeAttachmentDownloadUrl(
      key: $key
      contentType: $contentType
    ) {
      url
    }
  }
`;

export const deleteTestcaseFromTestrunMutation = gql`
  mutation deleteTestcaseFromTestRun($testrunId: UUID!, $testcaseId: UUID!) {
    deleteTestcaseFromTestrun(testrunId: $testrunId, testcaseId: $testcaseId)
  }
`;

export const getUsersInProjectQuery = gql`
  query getUsersInProjectQuery($projectId: UUID!) {
    getUsersInProject(projectId: $projectId) {
      id
      name
    }
  }
`;
