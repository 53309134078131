export const IMPORT_TESTCASE_PAGINATION_SIZE = 7;

export const IMPORT_TESTCASES_MAX_BATCH_COUNT = 100;

export enum ExportType {
  CSV = 'CSV',
  XLSX = 'XLSX',
  PDF = 'PDF',
}

export enum TestCaseTab {
  DETAILS = 'DETAILS',
  TEST_RUNS = 'TEST_RUNS',
  BUGS = 'BUGS',
  HISTORY = 'HISTORY',
}

export enum TestCaseHistoryActions {
  INSERT = 'INSERT',
  UPDATE = 'UPDATE',
}
