import { ImportJobStatus } from '@containers/test-suite/types';
import {
  CreateTestcasesErrorCode,
  ErrorField,
  ImportedTesCaseInternalError,
  ImportedTestCaseWarning,
  ImportTestcaseFileItemFieldsEnum,
  ImportTestCaseFileType,
} from '@customTypes/importTestCase';
import { NotificationTypes } from './components';

export enum UploadStatus {
  NOT_UPLOADED = 'NOT_UPLOADED',
  UPLOADED = 'UPLOADED',
  FILE_EMPTY = 'FILE_EMPTY',
  NO_VALID_TEST_CASES = 'NO_VALID_TEST_CASES',
}

export const FailedTestCaseFileContentType = {
  [ImportTestCaseFileType.CSV]: 'text/plain;charset=UTF-8',
  [ImportTestCaseFileType.XLS]: 'application/vnd.ms-excel',
  [ImportTestCaseFileType.XLSX]:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const FailedTestCasesXlsxSchema = [
  ...Object.values(ImportTestcaseFileItemFieldsEnum),
  ErrorField.ErrorReason,
].map((importTestCaseField) => ({
  column: importTestCaseField,
  type: String,
  value: (row: { [key: string]: string }) => row[importTestCaseField],
  width: 20,
}));

export const ImportErrorMessageMap = {
  [CreateTestcasesErrorCode.ENTITY_ALREADY_EXISTS]:
    'Duplicate Test Case already exist in system',
  [CreateTestcasesErrorCode.BATCH_FAILED]: 'Some thing went wrong',
  [ImportedTesCaseInternalError.EMPTY_NAME]: 'Mandatory field Title is empty',
  [ImportedTesCaseInternalError.FOLDER_NAME_AS_PROJECT_NAME]:
    'Section name and Project name cannot be same',
  [ImportedTesCaseInternalError.FOLDER_NAME_LENGTH_EXCEEDED]:
    'Section name has exceeded permissible length',
  [ImportedTesCaseInternalError.NAME_LENGTH_EXCEEDED]:
    'Title has exceeded permissible length',
  [ImportedTesCaseInternalError.TAG_LENGTH_EXCEEDED]:
    'TC Tag has exceeded permissible length',
  [ImportedTesCaseInternalError.AUTOMATION_LENGTH_EXCEEDED]:
    'TC Automation has exceeded permissible length',
  [ImportedTesCaseInternalError.DUPLICATE_ENTRY_IN_FILE]:
    'Duplicates found in file',
};

export const ImportWarningMessageMap = {
  [ImportedTestCaseWarning.FOLDER_DEPTH_EXCEEDED]:
    'The sections mentioned have more than 3 sub levels. The test case will be created under the 3rd level.',
};

export const ColumnInstructions = {
  [ImportTestcaseFileItemFieldsEnum.SectionHierarchy]:
    'This gives the order of hierarchy of sections upto the one that\ncontains the test case. If the section is present  in the same order\nthen the case will be placed directly in it, else the same will be\nplaced under an import folder followed by the mentioned hierarchy\nMain_Section > Sub_Section1 > Sub_Section2 > Sub_Section3',
  [ImportTestcaseFileItemFieldsEnum.Title]:
    'contains the title/ summary\nTEST_CASE_TITLE',
  [ImportTestcaseFileItemFieldsEnum.Feature]: '',
  [ImportTestcaseFileItemFieldsEnum.Type]:
    'contains type of test case will be compared with a list of availible types.\nRegression',
  [ImportTestcaseFileItemFieldsEnum.Tag]: 'tag will be a text field\nTAG 1',
  [ImportTestcaseFileItemFieldsEnum.Priority]:
    'will be compared with a list of items\nin the list.\nCritical, high, medium and low',
  [ImportTestcaseFileItemFieldsEnum.Template]:
    'single step or\nMultiple step\nDefault value will be single step',
  [ImportTestcaseFileItemFieldsEnum.Automation]: '',
  [ImportTestcaseFileItemFieldsEnum.Effort]:
    'This is the time in\nminutes needed to execute the test case.',
  [ImportTestcaseFileItemFieldsEnum.Precondition]: '',
  [ImportTestcaseFileItemFieldsEnum.Steps]:
    'in multistep each line\nwill be considered as each step',
  [ImportTestcaseFileItemFieldsEnum.ExpectedResults]:
    'in multistep each line\nwill be considered as each expected result',
  [ErrorField.ErrorReason]: '',
};

export const jobStatusToastMessageMap = {
  [ImportJobStatus.COMPLETED]: {
    message: 'importJobToastMessage.COMPLETED',
    toastType: NotificationTypes.SUCCESS,
  },
  [ImportJobStatus.COMPLETED_WITH_ERRORS]: {
    message: 'importJobToastMessage.COMPLETED_WITH_ERRORS',
    toastType: NotificationTypes.SUCCESS,
  },
  [ImportJobStatus.COMPLETED_WITH_WARNINGS]: {
    message: 'importJobToastMessage.COMPLETED',
    toastType: NotificationTypes.SUCCESS,
  },
  [ImportJobStatus.CANCELLED]: {
    message: 'importJobToastMessage.CANCELLED',
    toastType: NotificationTypes.ERROR,
  },
  [ImportJobStatus.FAILED]: {
    message: 'importJobToastMessage.FAILED',
    toastType: NotificationTypes.ERROR,
  },

  [ImportJobStatus.IN_PROGRESS]: {
    message: '',
    toastType: NotificationTypes.ERROR,
  },
};

export enum ImportHistoryTableColumn {
  USER_INFO = 'userInfo',
  START_DATE = 'startDate',
  STATUS = 'status',
  SUCCESS_COUNT = 'successCount',
  ERROR_COUNT = 'errorCount',
  DOWNLOAD = 'download',
  CANCEL = 'cancel',
}

export const ImportStatusStyleMap = {
  [ImportJobStatus.COMPLETED]: {
    text: 'status.completed',
    color: 'text-[#1FAD71]',
  },
  [ImportJobStatus.COMPLETED_WITH_ERRORS]: {
    text: 'status.completedWithErrors',
    color: 'text-[#AD521F]',
  },
  [ImportJobStatus.COMPLETED_WITH_WARNINGS]: {
    text: 'status.completedWithWarnings',
    color: 'text-[#ffb302]',
  },
  [ImportJobStatus.CANCELLED]: {
    text: 'status.cancelled',
    color: 'text-[#4B5563]',
  },
  [ImportJobStatus.IN_PROGRESS]: {
    text: 'status.inProgress',
    color: 'text-[#FDDC2B]',
  },
  [ImportJobStatus.FAILED]: {
    text: 'status.failed',
    color: 'text-[#AD521F]',
  },
};
