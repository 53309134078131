import { TestcaseStatus } from '@constants/components';

export const pickStatusColor = (status: TestcaseStatus) => {
  switch (status) {
    case TestcaseStatus.PASSED:
      return 'bg-[#69CB69]';
    case TestcaseStatus.FAILED:
      return 'bg-[#DF6E6E]';
    case TestcaseStatus.BLOCKED:
      return 'bg-[#595959]';
    case TestcaseStatus.RETEST:
      return 'bg-[#FDDC2B]';
    case TestcaseStatus.UNTESTED:
      return 'bg-[#787486]';
    default:
      return '';
  }
};
