import { Link } from 'react-router-dom';

import { useI18n } from '@hooks';
import { UserAuthFailedIcon } from '@images';

const ContactAdmin = (): JSX.Element => {
  const { t } = useI18n(['login']);

  return (
    <div className='flex flex-col items-center'>
      <img
        src={UserAuthFailedIcon}
        alt='User not found'
        className='mb-4 w-24 h-24'
      />
      <p className='text-lg font-semibold'>{t('noAccess')}</p>
      <p className=' mb-4 text-gray-700 text-md'>
        {t('contactAdminToGetAccess')}
      </p>
      <Link className='p-3 text-white bg-blue-500 rounded-md' to='/login'>
        {t('tryWithDifferentAccount')}
      </Link>
    </div>
  );
};
export default ContactAdmin;
