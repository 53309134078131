import { EntityPermission } from '@customTypes/members';

export enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  INVITED = 'invited',
}

export const ROLE_NAME_MAXLENGTH = 25;

export const entityPermissions = [
  EntityPermission.ENTITY_READ,
  EntityPermission.ENTITY_CREATE,
  EntityPermission.ENTITY_UPDATE,
  EntityPermission.ENTITY_DELETE,
];

export const EMAIL_PATTERN =
  /^(?=.{1,64}@)[A-Za-z0-9]+(\.[A-Za-z0-9]+)*@[A-Za-z0-9]+([.-][A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;

export const DEFAULT_ROLE = 'USER';
