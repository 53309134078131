/* eslint-disable jsx-a11y/label-has-associated-control */
import { useRef, useState } from 'react';
import { useCombobox } from 'downshift';
import { twMerge } from 'tailwind-merge';

import { ComboboxChanges, ComboboxItem } from '@customTypes/components';
import { useClickOutside, useI18n } from '@hooks';
import { CaretDown, TickMark } from '@icons';

import Spinner from '../spinner/Spinner';

interface AutocompleteDropdownProps {
  inputValue?: string;
  items: Array<ComboboxItem>;
  placeholder: string;
  showLoader?: boolean;
  onInputValueChange: (changes: ComboboxChanges) => void;
  onSelectedItemChange?: (changes: ComboboxChanges) => void;
  inputContainerStyle?: string;
  prefix?: JSX.Element;
  postfix?: JSX.Element;
  inputStyle?: string;
  label?: string;
  menuContainerStyle?: string;
  menuItemTextStyle?: string;
  renderMenuItem?: (title: ComboboxItem) => JSX.Element;
  showCaretDown?: boolean;
  containerStyle?: string;
  emptyStateStyle?: string;
  defaultIsOpen?: boolean;
}
const AutocompleteDropdown = ({
  inputValue,
  label,
  items,
  placeholder,
  onInputValueChange,
  showLoader = false,
  onSelectedItemChange,
  prefix,
  postfix,
  inputContainerStyle,
  containerStyle,
  emptyStateStyle,
  inputStyle,
  menuContainerStyle,
  menuItemTextStyle,
  renderMenuItem,
  showCaretDown = true,
  defaultIsOpen = false,
}: AutocompleteDropdownProps) => {
  const [isSelected, setIsSelected] = useState(false);

  const {
    isOpen,
    selectedItem,
    highlightedIndex,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    inputValue: searchTerm,
  } = useCombobox({
    items,
    itemToString: (item) => (item ? item.title : ''),
    onInputValueChange,
    onSelectedItemChange: (changes: ComboboxChanges) => {
      setIsSelected(true);
      onSelectedItemChange?.(changes);
    },
    inputValue,
    defaultIsOpen,
  });

  const { t } = useI18n(['common']);

  const [showSuggestion, setShowSuggestion] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside({
    ref: containerRef,
    handler: () => setShowSuggestion(false),
  });

  return (
    <div
      ref={containerRef}
      onFocus={() => setShowSuggestion(!!searchTerm.length)}
      className={twMerge('relative', containerStyle)}
    >
      {label ? (
        <label
          {...getLabelProps()}
          className='inline-block mb-1.5 text-sm text-blue-magenta-faded'
        >
          {label}
        </label>
      ) : null}
      <div
        {...getComboboxProps()}
        className={twMerge(
          'flex p-3 rounded border border-light-lavender',
          inputContainerStyle
        )}
      >
        {prefix}
        <input
          {...getInputProps({
            onFocus: () => setIsSelected(false),
          })}
          className={twMerge(
            'flex-1 mr-2 focus-visible:outline-none',
            inputStyle
          )}
          placeholder={placeholder}
        />
        {(!!postfix || showCaretDown) && (
          <button
            type='button'
            {...getToggleButtonProps()}
            aria-label='toggle menu'
          >
            {postfix ?? <CaretDown />}
          </button>
        )}
      </div>
      <ul
        {...getMenuProps()}
        className={twMerge(
          'overflow-hidden absolute z-10 w-full bg-white rounded shadow-default',
          menuContainerStyle
        )}
      >
        {((!isSelected && showSuggestion && !isOpen) || isOpen) &&
        !showLoader &&
        items?.length
          ? items.map(
              (item, index) =>
                renderMenuItem?.(item) ?? (
                  <li
                    key={`${item.id}`}
                    className={`z-0 p-2 flex items-center justify-between hover:cursor-pointer ${
                      highlightedIndex === index ? 'bg-gray-100' : ''
                    } ${
                      index !== items.length - 1
                        ? 'border-b-[0.2px] border-b-grey-border'
                        : ''
                    } `}
                    {...getItemProps({ item, index })}
                  >
                    <p
                      className={twMerge(
                        'overflow-hidden text-ellipsis whitespace-pre',
                        menuItemTextStyle
                      )}
                    >
                      {item.title}
                    </p>
                    {item.id === selectedItem?.id ? (
                      <TickMark className='shrink-0 ml-1' />
                    ) : null}
                  </li>
                )
            )
          : null}
        {isOpen && showLoader ? <Spinner className='my-3 mx-auto' /> : null}
        {!isSelected &&
        ((showSuggestion && !isOpen) || isOpen) &&
        !showLoader &&
        !items.length ? (
          <p
            className={twMerge(
              'p-3 text-center text-blue-magenta-faded',
              emptyStateStyle
            )}
          >
            {t('noResultsToShow')}
          </p>
        ) : null}
      </ul>
    </div>
  );
};

export default AutocompleteDropdown;
