import { useRef, useState, MutableRefObject, useMemo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Combobox } from '@components';
import { useClickOutside, useI18n } from '@hooks';
import { logoutUser } from '@utils/authentication';
import { User } from '@customTypes/authentication';
import { useLogoutMutation } from '@containers/login/store/api';
import RoutePaths from '@routes/RoutesPath';
import { CaretDown } from '@icons';
import {
  getProjectIdFromProjectSubPaths,
  injectParamsToRoute,
} from '@utils/navigation';
import { ComboboxChanges, GlobalSearchChanges } from '@customTypes/components';
import {
  useGetProjectDetailsQuery,
  useGetProjectsQuery,
  useLazySearchProjectsQuery,
} from '@containers/projects/store/api';
import { useLazyGlobalSearchQuery } from '@store/api';
import { getDebouncedCallback } from '@utils/common';
import { useAppDispatch } from '@store/store';
import { resetTestSuite } from '@containers/test-suite/store/reducer';
import { PROJECT_NAME_MATCH } from '@constants/common';

import GlobalSearch from './GlobalSearch';

const Header = ({
  user,
  isBarExpanded,
}: {
  isBarExpanded: boolean;
  user?: User;
}) => {
  const [profileMenuOpen, setProfileMenuOpen] = useState<boolean>(false);
  const [logoutMutation] = useLogoutMutation();
  const headerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const projectSelectorRef = useRef() as MutableRefObject<HTMLDivElement>;
  const isProjectTermChangeEventFromSelection = useRef(false);

  const dispatch = useAppDispatch();

  const { name, email, profilePicture, userTenants } = user ?? {};
  const userRole = userTenants?.[0]?.role?.name;

  const [showComboBox, setShowComboBox] = useState(false);
  const [globalSearchTerm, setGlobalSearchTerm] = useState('');
  const [projectSearchTerm, setProjectSearchTerm] = useState('');

  const { t } = useI18n([
    'common',
    'test-runs/addTestRun',
    'testSuite/testSuite',
  ]);

  const location = useLocation();

  const projectId = getProjectIdFromProjectSubPaths(location?.pathname);

  const [
    searchProjects,
    {
      data: searchedProjects,
      isLoading: searchProjectsLoading,
      isError: projectSearchError,
    },
  ] = useLazySearchProjectsQuery();

  const [
    globalSearch,
    {
      data: globalSearchResults,
      isLoading: gloabalSearchLoading,
      isError: isGlobalSearchError,
    },
  ] = useLazyGlobalSearchQuery();

  const { data: defaultProjectsList } = useGetProjectsQuery({
    paginationInput: {
      page: 0,
      size: 10,
    },
  });

  const navigate = useNavigate();

  const { data: projectDetails } = useGetProjectDetailsQuery(projectId, {
    skip: !projectId,
  });

  const showProjectSelector = projectId && projectDetails?.name;

  const closeComboBox = () => {
    setShowComboBox(false);
  };

  useEffect(() => {
    if (!showProjectSelector) {
      closeComboBox();
    }
  }, [showProjectSelector]);

  const onProjectSearchChange = getDebouncedCallback(
    (change: ComboboxChanges) => {
      if (isProjectTermChangeEventFromSelection.current) {
        isProjectTermChangeEventFromSelection.current = false;
      } else {
        const trimmedValue = change?.inputValue?.trim();
        setProjectSearchTerm(trimmedValue ?? '');
        if (trimmedValue?.length && PROJECT_NAME_MATCH.exec(trimmedValue)) {
          searchProjects(trimmedValue);
        }
      }
    }
  );

  const onGloablSearchChange = getDebouncedCallback(
    (change: GlobalSearchChanges) => {
      const trimmedValue = change?.inputValue?.trim();
      setGlobalSearchTerm(trimmedValue ?? '');
      if (trimmedValue?.length) {
        globalSearch(trimmedValue);
      }
    }
  );

  const closeDropdown = () => {
    setProfileMenuOpen(false);
  };

  useClickOutside({ ref: headerRef, handler: closeDropdown });

  useClickOutside({ ref: projectSelectorRef, handler: closeComboBox });

  const toggleDropDown = () => {
    setProfileMenuOpen((prevState) => !prevState);
  };

  const clickLogout = async () => {
    const response = await logoutMutation().unwrap();
    if (response === 'OK') {
      logoutUser();
    }
  };

  const onSelectProject = (changes: ComboboxChanges) => {
    const { title: selectedProjectName, id: selectedProjectId } =
      changes?.selectedItem ?? {};
    if (selectedProjectName && selectedProjectId) {
      closeComboBox();
      setProjectSearchTerm('');
      isProjectTermChangeEventFromSelection.current = true;
      navigate(
        injectParamsToRoute(RoutePaths.PROJECTS.OVERVIEW, {
          projectId: selectedProjectId,
        })
      );
      setTimeout(() => dispatch(resetTestSuite()), 500);
      // setTimeout() is used here to reset test suite only after the navigation has finished. Calling resetTestSuite() without timeout will clear the state before navigation has completed
    }
  };

  const comboBoxItems = useMemo(() => {
    if (
      projectSearchError ||
      (projectSearchTerm.length && !PROJECT_NAME_MATCH.exec(projectSearchTerm))
    ) {
      return [];
    }
    let projects = searchedProjects ?? [];
    if (!projectSearchTerm.length) {
      projects = (defaultProjectsList?.content || []).map(
        ({ id, name: projectName, description }) => ({
          id,
          name: projectName,
          ...(description ? { description } : {}),
        })
      );
    }

    return projects
      ?.filter(({ id }) => projectId !== id)
      .map((project) => ({
        id: project?.id,
        title: project?.name,
      }));
  }, [
    defaultProjectsList?.content,
    projectId,
    projectSearchTerm,
    searchedProjects,
    projectSearchError,
  ]);

  return (
    <div
      className={`flex fixed top-0 left-0 z-1 items-center py-5 pr-12 pl-[50px] ${
        isBarExpanded
          ? 'ml-[var(--expanded-sidenav-width)] w-[calc(100%-var(--expanded-sidenav-width))]'
          : 'ml-[var(--collapsed-sidenav-width)] w-[calc(100%-var(--collapsed-sidenav-width))]'
      } bg-white border-b border-grey-border justify-between`}
    >
      {!projectId && (
        <GlobalSearch
          suggestions={
            globalSearchTerm.length &&
            globalSearchResults?.length &&
            !isGlobalSearchError
              ? globalSearchResults
              : []
          }
          showLoader={gloabalSearchLoading}
          onInputChange={onGloablSearchChange}
        />
      )}
      {projectId ? (
        <div ref={projectSelectorRef} className='relative items-center'>
          <button
            type='button'
            onClick={() => setShowComboBox((prevState) => !prevState)}
            className='flex justify-between items-center py-[8px] px-[12px] w-[270px] bg-purple/[8%] hover:bg-[#5130e51a] rounded-lg cursor-pointer'
          >
            <div className='flex items-center max-w-[220px]'>
              <div className='mr-2'>
                <div className=' w-2 h-2 bg-green-600 rounded-[4px]' />
              </div>
              <p className='overflow-hidden text-[15px] truncate whitespace-pre'>
                {projectDetails?.name}
              </p>
            </div>
            <CaretDown />
          </button>
          {showComboBox && (
            <div className='flex absolute w-full bg-white'>
              <Combobox
                placeholder={t('search')}
                items={comboBoxItems}
                showLoader={searchProjectsLoading}
                onInputValueChange={onProjectSearchChange}
                onSelectedItemChange={onSelectProject}
                inputContainerStyle='py-2 mt-1'
                showCaretDown={false}
                containerStyle='w-full'
                emptyStateStyle='p-1'
                inputStyle='w-full'
                defaultIsOpen
                menuContainerStyle='max-h-[calc(100vh-150px)] overflow-y-auto'
                menuItemTextStyle='whitespace-pre'
              />
            </div>
          )}
        </div>
      ) : null}
      <div ref={headerRef} className='relative'>
        {!user ? (
          <div className='flex items-center'>
            <div className='flex flex-col items-end mr-4'>
              <div className='mb-2 w-24 h-3 rounded skeleton-shimmer' />
              <div className='w-16 h-[10px] rounded skeleton-shimmer' />
            </div>
            <div className='w-12 h-12 rounded-full skeleton-shimmer' />
          </div>
        ) : (
          <button
            id='profileButton'
            type='button'
            onClick={toggleDropDown}
            className='flex cursor-pointer'
          >
            <div className='flex-col mr-4 text-right'>
              <p className='text-base text-black-primary'>{name}</p>
              <p className='text-sm text-blue-magenta-faded'>{userRole}</p>
            </div>
            <img
              src={profilePicture}
              alt='Profile pic'
              className='w-12 h-12 rounded-full border border-blue-magenta-faded'
            />
          </button>
        )}
        {profileMenuOpen && (
          <div className='flex absolute top-[60px] right-0 z-10 flex-col items-center py-4 w-[272px] bg-white rounded-md border border-grey-border'>
            <img
              src={profilePicture}
              alt='Profile pic'
              className='mb-2 w-[60px] h-[60px] rounded-full border border-blue-magenta-faded'
            />
            <div className='text-base text-black-primary'>{name}</div>
            <div className='text-sm text-blue-magenta-faded'>{userRole}</div>
            <div className='mb-4 text-sm text-blue-magenta-faded'>{email}</div>
            <div className='px-2 mb-4 w-[240px] h-1 border-b border-grey-divider' />
            <Button label='Sign Out' handleClick={clickLogout} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
