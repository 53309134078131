import { useState } from 'react';

import {
  FilledCloseIcon,
  FileIcon,
  VideoIcon,
  ImageIcon,
  DownloadIcon,
} from '@icons';
import Spinner from '../spinner/Spinner';

type AttachmentProps = {
  onClose?: () => void;
  name: string;
  onDownloadClick?: () => void;
  isLoading?: boolean;
  showCloseButton?: boolean;
};

enum FileType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  OTHER = 'OTHER',
}

const imageExtensions = ['JPEG', 'PNG', 'JPG'];
const videoExtensions = ['MP4', 'MOV', 'WMV', 'MKV', 'WEBM', 'OGG'];

const Attachment = ({
  onClose,
  name,
  onDownloadClick,
  isLoading,
  showCloseButton = true,
}: AttachmentProps) => {
  const [closeIconColor, setCloseIconColor] = useState('#767D92');

  const fileExtension =
    name?.split('.')?.[name.split('.').length - 1]?.toUpperCase() ?? '';

  const getIcon = () => {
    let fileType = FileType.OTHER;
    if (imageExtensions.includes(fileExtension)) {
      fileType = FileType.IMAGE;
    } else if (videoExtensions.includes(fileExtension)) {
      fileType = FileType.VIDEO;
    }
    switch (fileType) {
      case FileType.IMAGE:
        return <ImageIcon />;
      case FileType.VIDEO:
        return <VideoIcon />;
      default:
        return <FileIcon />;
    }
  };

  return (
    <div className='flex p-1'>
      <div className='flex items-center py-[2px] px-1 h-[45px] bg-[#F2F2F2] rounded border-[1px] border-light-slate-grey'>
        {isLoading ? <Spinner /> : getIcon()}
        <div className='flex flex-col justify-center px-2 max-w-[200px] truncate'>
          <p className='text-xs text-light-slate-grey truncate'>{name}</p>
          <p className='text-xs text-light-slate-grey truncate'>
            {fileExtension}
          </p>
        </div>
        {onDownloadClick && (
          <button type='button' onClick={onDownloadClick}>
            <DownloadIcon className='mx-2' />
          </button>
        )}
      </div>
      {!isLoading && showCloseButton ? (
        <button
          onMouseEnter={() => setCloseIconColor('#7257F0')}
          onMouseLeave={() => setCloseIconColor('#767D92')}
          type='button'
          className='flex items-start mt-[-6px] ml-[-10px]'
          onClick={onClose}
        >
          <FilledCloseIcon color={closeIconColor} />
        </button>
      ) : null}
    </div>
  );
};

export default Attachment;
