import { gql } from 'graphql-request';

export const getMilestonesByProjectIdQuery = gql`
  query getMilestonesByProjectId(
    $paginationInput: PaginationInput
    $projectId: UUID!
  ) {
    allMilestonesByProjectId(
      paginationInput: $paginationInput
      projectId: $projectId
    ) {
      content {
        id
        name
        description
        projectId
        startDate
        endDate
        dateEnded
        status
        summary {
          notStartedCount
          inProgressCount
          completedCount
        }
      }
      totalPages
      totalElements
      pageable {
        pageNumber
      }
      last
    }
  }
`;

export const getMilestoneByIdQuery = gql`
  query getMilestoneById($id: UUID!) {
    milestoneById(id: $id) {
      id
      name
      description
      startDate
      endDate
      status
      enableAutoStart
      summary {
        notStartedCount
        inProgressCount
        completedCount
      }
      testruns {
        id
        name
        testrunSummary {
          notStartedCount
          inProgressCount
          completedCount
        }
        totalTestcaseSummary {
          blockedCount
          retestCount
          passedCount
          failedCount
          untestedCount
          inProgressCount
        }
      }
    }
  }
`;

export const createMilestoneMutation = gql`
  mutation createMilestone($createMilestoneInput: CreateMilestoneInput!) {
    createMilestone(createMilestoneInput: $createMilestoneInput) {
      id
    }
  }
`;

export const getSearchMilestonesQuery = gql`
  query getSearchMilestonesQuery($searchTerm: String!, $projectId: UUID!) {
    searchMilestones(searchTerm: $searchTerm, projectId: $projectId) {
      id
      name
      status
    }
  }
`;

export const deleteMilestoneMutation = gql`
  mutation deleteMilestoneById($id: UUID!) {
    deleteMilestoneById(id: $id)
  }
`;

export const updateMilestoneMutation = gql`
  mutation updateMilestoneById(
    $id: UUID!
    $updateMilestoneInput: UpdateMilestoneInput!
  ) {
    updateMilestoneById(id: $id, updateMilestoneInput: $updateMilestoneInput) {
      id
    }
  }
`;
