import { v4 as uuid4 } from 'uuid';

import {
  ImportErrorMessageMap,
  ImportWarningMessageMap,
  UploadStatus,
} from '@constants/importTestCase';
import {
  ErrorField,
  ImportedTestCase,
  ImportTestcaseFileItemFieldsEnum,
  SeparatedTestCases,
  TestCaseStepForTestCaseImport,
  ImportTestcaseBackendFieldsEnum,
  ImportedTesCaseInternalError,
  CreateTestcasesInput,
  ImportedTestCaseWarning,
  FailedTestcase,
  CreateTestcasesErrorCode,
  ImportErrorDialogMessage,
  ImportErrorDialogMessageType,
} from '@customTypes/importTestCase';
import {
  Priorities,
  TestCaseTemplates,
  TestCaseTypes,
  TimeUnit,
} from '@constants/test-suite/addTestCase';

export const getFormattedTestcaseTemplate = (testcaseTemplate: string) => {
  const testCaseTemplateInCaps = testcaseTemplate
    ?.toUpperCase()
    ?.replaceAll(' ', '_');
  const isValidTestcaseTemplate = (
    Object.values(TestCaseTemplates) as string[]
  ).includes(testCaseTemplateInCaps);
  if (isValidTestcaseTemplate) {
    return testCaseTemplateInCaps;
  }
  return TestCaseTemplates.SINGLE_STEP;
};

const getFormattedTestcaseSteps = (
  template: string,
  steps: string | undefined,
  expectedResults: string | undefined
) => {
  if (steps || expectedResults) {
    const formattedTemplate = getFormattedTestcaseTemplate(template);
    if (formattedTemplate === TestCaseTemplates.MULTI_STEP) {
      const stepsArray = steps?.toString().split('\n') ?? [];
      const testcaseSteps: TestCaseStepForTestCaseImport[] = stepsArray.map(
        (item, index) => ({ position: index, step: item })
      );
      const expectedResultsArray =
        expectedResults?.toString().split('\n') ?? [];
      expectedResultsArray.forEach((resultItem, index) => {
        if (testcaseSteps[index]) {
          testcaseSteps[index].expected = resultItem;
        } else {
          testcaseSteps[index] = { expected: resultItem, position: index };
        }
      });
      return testcaseSteps;
    }
    const testcaseSteps = {
      step: steps?.toString(),
      expected: expectedResults?.toString(),
      position: 0,
    };
    return [testcaseSteps];
  }
  return undefined;
};

export const getFormattedFolderHierarchy = (
  folderHierarchy: string,
  projectName: string
) => {
  if (folderHierarchy) {
    let warning;
    let folderHierarchyArray = folderHierarchy?.toString()?.split('>');
    if (
      folderHierarchyArray?.[0]?.toLowerCase().trim() ===
      projectName.toLowerCase()
    ) {
      folderHierarchyArray = folderHierarchyArray.slice(
        1,
        folderHierarchyArray.length
      );
    }
    if (folderHierarchyArray.length > 3) {
      folderHierarchyArray = folderHierarchyArray.slice(0, 3);
      warning = ImportedTestCaseWarning.FOLDER_DEPTH_EXCEEDED;
    }
    if (folderHierarchyArray.length) {
      return {
        folderHierarchy: folderHierarchyArray
          ?.map((folderName) => folderName.trim())
          .filter((folderName) => !!folderName.length),
        warning,
      };
    }
  }
  return { folderHierarchy: undefined, warning: undefined };
};

export const getFormattedTestcaseType = (testcaseType: string) => {
  const testCaseTypeInCaps = testcaseType?.toUpperCase()?.replaceAll(' ', '_');
  const isValidTestcaseType = (
    Object.values(TestCaseTypes) as string[]
  ).includes(testCaseTypeInCaps);
  if (isValidTestcaseType) {
    return testCaseTypeInCaps;
  }
  return undefined;
};

export const getFormattedTestcasePriority = (testcasePriority: string) => {
  const testCasePriorityInCaps = testcasePriority
    ?.toUpperCase()
    ?.replaceAll(' ', '_');
  const isValidTestcasePriority = (
    Object.values(Priorities) as string[]
  ).includes(testCasePriorityInCaps);
  if (isValidTestcasePriority) {
    return testCasePriorityInCaps;
  }
  return undefined;
};

const validateImportedTestCase = (
  testCase: { [key: string]: string },
  projectName: string
) => {
  let internalErrors: ImportedTesCaseInternalError[] | null = [];
  let internalWarnings: ImportedTestCaseWarning[] | null = [];

  const { folderHierarchy, warning: folderDepthExceededWarning } =
    getFormattedFolderHierarchy(
      testCase[ImportTestcaseFileItemFieldsEnum.SectionHierarchy],
      projectName
    );

  const isAnyFolderNameExceedsCharLimit = folderHierarchy?.some(
    (folderName) => folderName?.length > 50
  );

  const isFolderNameAsProjectName = folderHierarchy?.some(
    (folderName) =>
      folderName.toLocaleLowerCase() === projectName.toLocaleLowerCase()
  );

  if (
    !testCase[ImportTestcaseFileItemFieldsEnum.Title] ||
    testCase[ImportTestcaseFileItemFieldsEnum.Title]?.length < 1
  ) {
    internalErrors.push(ImportedTesCaseInternalError.EMPTY_NAME);
  }
  if (testCase[ImportTestcaseFileItemFieldsEnum.Title]?.length > 255) {
    internalErrors.push(ImportedTesCaseInternalError.NAME_LENGTH_EXCEEDED);
  }
  if (testCase[ImportTestcaseFileItemFieldsEnum.Tag]?.length > 100) {
    internalErrors.push(ImportedTesCaseInternalError.TAG_LENGTH_EXCEEDED);
  }
  if (testCase[ImportTestcaseFileItemFieldsEnum.Automation]?.length > 100) {
    internalErrors.push(
      ImportedTesCaseInternalError.AUTOMATION_LENGTH_EXCEEDED
    );
  }
  if (isAnyFolderNameExceedsCharLimit) {
    internalErrors.push(
      ImportedTesCaseInternalError.FOLDER_NAME_LENGTH_EXCEEDED
    );
  }
  if (isFolderNameAsProjectName) {
    internalErrors.push(
      ImportedTesCaseInternalError.FOLDER_NAME_AS_PROJECT_NAME
    );
  }
  if (folderDepthExceededWarning) {
    internalWarnings.push(folderDepthExceededWarning);
  }
  if (!internalErrors?.length) {
    internalErrors = null;
  }
  if (!internalWarnings?.length) {
    internalWarnings = null;
  }
  return { internalErrors, internalWarnings };
};

const isValidEffortField = (effortField: string | undefined) => {
  if (effortField) {
    return (
      effortField.length <= 4 &&
      Number(effortField) &&
      /^[0-9]*$/.test(effortField)
    );
  }
  return false;
};

/**
 *
 * Method will iterate list of test case rows and trim each field of test case object
 * @param list :list of test case row
 * @returns : trimmed list of test case rows
 */
const getTrimmedListOfRows = (list: { [key: string]: string }[]) =>
  list.map((rowObject) => {
    const keys = Object.keys(rowObject);
    const trimmedObject: { [key: string]: string } = {};
    keys?.forEach((key) => {
      trimmedObject[key?.trim()] =
        rowObject?.[key]?.trim?.() ?? rowObject?.[key];
    });
    return trimmedObject;
  });

const isDuplicateTestCase = (
  addedTestCases: ImportedTestCase[],
  newTestCase: ImportedTestCase
) => {
  const folderHierarchyString = newTestCase.folderHierarchy?.join('');
  const isDuplicate = addedTestCases.some(
    (AddedTestCase) =>
      newTestCase.name ===
        AddedTestCase[ImportTestcaseBackendFieldsEnum.Name] &&
      folderHierarchyString === AddedTestCase.folderHierarchy?.join('')
  );
  return isDuplicate;
};

const formatTestCase = (
  testCaseRow: {
    [key: string]: string;
  },
  projectName: string
) => ({
  id: uuid4(),
  checked: false,
  [ImportTestcaseBackendFieldsEnum.FolderHierarchy]:
    getFormattedFolderHierarchy(
      testCaseRow[ImportTestcaseFileItemFieldsEnum.SectionHierarchy],
      projectName
    ).folderHierarchy,
  [ImportTestcaseBackendFieldsEnum.Name]:
    testCaseRow[ImportTestcaseFileItemFieldsEnum.Title]?.toString(),
  [ImportTestcaseBackendFieldsEnum.Feature]:
    testCaseRow[ImportTestcaseFileItemFieldsEnum.Feature]?.toString(),
  [ImportTestcaseBackendFieldsEnum.Type]: getFormattedTestcaseType(
    testCaseRow[ImportTestcaseFileItemFieldsEnum.Type]?.toString()
  ),
  [ImportTestcaseBackendFieldsEnum.Feature]:
    testCaseRow[ImportTestcaseFileItemFieldsEnum.Feature]?.toString(),
  [ImportTestcaseBackendFieldsEnum.Type]: getFormattedTestcaseType(
    testCaseRow[ImportTestcaseFileItemFieldsEnum.Type]?.toString()
  ),
  ...(testCaseRow[ImportTestcaseFileItemFieldsEnum.Tag]
    ? {
        [ImportTestcaseBackendFieldsEnum.Tag]:
          testCaseRow[ImportTestcaseFileItemFieldsEnum.Tag]?.toString(),
      }
    : {}),
  [ImportTestcaseBackendFieldsEnum.Priority]: getFormattedTestcasePriority(
    testCaseRow[ImportTestcaseFileItemFieldsEnum.Priority]?.toString()
  ),
  ...(testCaseRow[ImportTestcaseFileItemFieldsEnum.Precondition]
    ? {
        [ImportTestcaseBackendFieldsEnum.Precondition]:
          testCaseRow[
            ImportTestcaseFileItemFieldsEnum.Precondition
          ]?.toString(),
      }
    : {}),
  ...(isValidEffortField(
    testCaseRow[ImportTestcaseFileItemFieldsEnum.Effort]?.toString()
  )
    ? {
        [ImportTestcaseBackendFieldsEnum.EstimatedTime]: Number(
          testCaseRow[ImportTestcaseFileItemFieldsEnum.Effort]
        ),
        [ImportTestcaseBackendFieldsEnum.EstimatedTimeUnit]: TimeUnit.MINUTE,
      }
    : {}),
  ...(testCaseRow[ImportTestcaseFileItemFieldsEnum.Automation]
    ? {
        [ImportTestcaseBackendFieldsEnum.Automation]:
          testCaseRow[ImportTestcaseFileItemFieldsEnum.Automation]?.toString(),
      }
    : {}),
  [ImportTestcaseBackendFieldsEnum.Template]: getFormattedTestcaseTemplate(
    testCaseRow[ImportTestcaseFileItemFieldsEnum.Template]?.toString()
  ),
  [ImportTestcaseBackendFieldsEnum.Steps]: getFormattedTestcaseSteps(
    testCaseRow[ImportTestcaseFileItemFieldsEnum.Template],
    testCaseRow[ImportTestcaseFileItemFieldsEnum.Steps],
    testCaseRow[ImportTestcaseFileItemFieldsEnum.ExpectedResults]
  ),
});

/**
 *
 * @param importedTestCases
 * @param projectName
 * @returns list of formatted test cases
 */
export const formatImportedTestCases = (
  importedTestCases: { [key: string]: string }[],
  projectName: string
) => {
  const formattedTestCases: ImportedTestCase[] = [];
  const trimmedTestCaseRows = getTrimmedListOfRows(importedTestCases);

  trimmedTestCaseRows.forEach((testcaseItem) => {
    const { internalErrors, internalWarnings } = validateImportedTestCase(
      testcaseItem,
      projectName
    );

    const newTestCase: ImportedTestCase = formatTestCase(
      testcaseItem,
      projectName
    );

    newTestCase.internalErrors = [
      ...(internalErrors ?? []),
      ...(isDuplicateTestCase(formattedTestCases, newTestCase)
        ? [ImportedTesCaseInternalError.DUPLICATE_ENTRY_IN_FILE]
        : []),
    ];
    newTestCase.internalWarnings = internalWarnings;

    formattedTestCases.push(newTestCase);
  });
  return formattedTestCases;
};

export const separateTestCases = (importTestCasesItems: ImportedTestCase[]) => {
  const { validTestCases, failedTestCases, testCasesWithWarning } =
    importTestCasesItems.reduce(
      (separatedTestCases: SeparatedTestCases, testCase) => ({
        validTestCases: [
          ...separatedTestCases.validTestCases,
          ...(!testCase.internalErrors?.length ? [testCase] : []),
        ],
        failedTestCases: [
          ...separatedTestCases.failedTestCases,
          ...(testCase.internalErrors?.length ? [testCase] : []),
        ],
        testCasesWithWarning: [
          ...separatedTestCases.testCasesWithWarning,
          ...(!testCase.internalErrors?.length && testCase.internalWarnings
            ? [testCase]
            : []),
        ],
      }),
      {
        validTestCases: [],
        failedTestCases: [],
        testCasesWithWarning: [],
      }
    );
  return {
    validTestCases,
    failedTestCases,
    testCasesWithWarning,
  };
};

export const getCreateTestCasesInput = (
  importedTestCases: ImportedTestCase[]
): CreateTestcasesInput[] =>
  importedTestCases.map(
    ({
      internalErrors,
      checked,
      id,
      internalWarnings,
      ...createTestCaseInput
    }) => createTestCaseInput
  );

export const getFormattedFailedTestCases = (
  failedTestCase: ImportedTestCase
) => {
  const getField = (
    field: ImportTestcaseBackendFieldsEnum,
    avoidEmptyList = false
  ) => {
    const testCaseField = failedTestCase[field] as any;

    const notUndefined = !avoidEmptyList && testCaseField;
    const notEmptyList = avoidEmptyList && testCaseField?.length;

    if (notUndefined || notEmptyList) {
      return { [field]: failedTestCase[field] };
    }
    return {};
  };

  return {
    ...getField(ImportTestcaseBackendFieldsEnum.FolderHierarchy, true),
    ...getField(ImportTestcaseBackendFieldsEnum.Name),
    ...getField(ImportTestcaseBackendFieldsEnum.Feature),
    ...getField(ImportTestcaseBackendFieldsEnum.Type),
    ...getField(ImportTestcaseBackendFieldsEnum.Tag),
    ...getField(ImportTestcaseBackendFieldsEnum.Priority),
    ...getField(ImportTestcaseBackendFieldsEnum.Precondition),
    ...getField(ImportTestcaseBackendFieldsEnum.EstimatedTime),
    ...getField(ImportTestcaseBackendFieldsEnum.EstimatedTimeUnit),
    ...getField(ImportTestcaseBackendFieldsEnum.Automation),
    ...getField(ImportTestcaseBackendFieldsEnum.Template),
    ...getField(ImportTestcaseBackendFieldsEnum.Steps, true),
  };
};

export const isUploadError = (uploadStatus: UploadStatus) =>
  [UploadStatus.FILE_EMPTY, UploadStatus.NO_VALID_TEST_CASES].includes(
    uploadStatus
  );

const combineMultiStepsInfoToString = (
  testCaseSteps: TestCaseStepForTestCaseImport[],
  fieldToCombine: 'step' | 'expected'
) =>
  testCaseSteps.reduce(
    (appendedString, step) => `${appendedString}${step[fieldToCombine]}\n`,
    ''
  );

export const convertTestCasesToFileRows = (
  failedList: (ImportedTestCase | FailedTestcase)[]
) =>
  failedList.map((testCase) => ({
    [ImportTestcaseFileItemFieldsEnum.SectionHierarchy]:
      testCase.folderHierarchy?.join('>') ?? '',
    [ImportTestcaseFileItemFieldsEnum.Title]: testCase.name ?? '',
    [ImportTestcaseFileItemFieldsEnum.Feature]: testCase.feature ?? '',
    [ImportTestcaseFileItemFieldsEnum.Type]: testCase.type ?? '',
    [ImportTestcaseFileItemFieldsEnum.Tag]: testCase.tag ?? '',
    [ImportTestcaseFileItemFieldsEnum.Priority]: testCase.priority ?? '',
    [ImportTestcaseFileItemFieldsEnum.Template]: testCase.template ?? '',
    [ImportTestcaseFileItemFieldsEnum.Automation]: testCase.automation ?? '',
    [ImportTestcaseFileItemFieldsEnum.Effort]:
      testCase.estimatedTime?.toString() ?? '',
    [ImportTestcaseFileItemFieldsEnum.Precondition]:
      testCase.precondition ?? '',
    [ImportTestcaseFileItemFieldsEnum.Steps]: testCase.testcaseSteps?.length
      ? combineMultiStepsInfoToString(testCase.testcaseSteps, 'step')
      : '',
    [ImportTestcaseFileItemFieldsEnum.ExpectedResults]: testCase.testcaseSteps
      ?.length
      ? combineMultiStepsInfoToString(testCase.testcaseSteps, 'expected')
      : '',
    [ErrorField.ErrorReason]:
      (testCase?.errorCode
        ? ImportErrorMessageMap[testCase?.errorCode]
        : testCase.internalErrors?.reduce(
            (errorMessages, internalError) =>
              `${errorMessages}${ImportErrorMessageMap[internalError]}\n`,
            ''
          )) ?? '',
  }));

export const getErrorAndWarningMessages = (
  localFailedTestCases: ImportedTestCase[],
  backEndFailedTestCases: FailedTestcase[],
  testCasesWithWarning: ImportedTestCase[]
) => {
  const totalInternalErrorCodes = Object.values(
    ImportedTesCaseInternalError
  ).length;
  const totalBackEndErrorCodes = Object.values(CreateTestcasesErrorCode).length;
  const totalInternalWarningCodes = Object.values(
    ImportedTestCaseWarning
  ).length;
  const internalErrors: ImportedTesCaseInternalError[] = [];
  const backendErrors: CreateTestcasesErrorCode[] = [];
  const internalWarnings: ImportedTestCaseWarning[] = [];

  const importErrorDialogMessages: ImportErrorDialogMessage[] = [];

  localFailedTestCases.every((testCase) => {
    if (testCase.internalErrors) {
      testCase.internalErrors.forEach((internalError) => {
        if (!internalErrors.includes(internalError)) {
          internalErrors.push(internalError);
        }
      });
    }
    return internalErrors.length !== totalInternalErrorCodes;
  });

  backEndFailedTestCases.every((testCase) => {
    if (testCase.errorCode) {
      if (!backendErrors.includes(testCase.errorCode)) {
        backendErrors.push(testCase.errorCode);
      }
    }
    return backendErrors.length !== totalBackEndErrorCodes;
  });
  testCasesWithWarning.every((testCase) => {
    if (testCase.internalWarnings) {
      testCase.internalWarnings.forEach((warning) => {
        if (!internalWarnings.includes(warning)) {
          internalWarnings.push(warning);
        }
      });
    }
    return internalWarnings.length !== totalInternalWarningCodes;
  });
  internalWarnings.forEach((warning) =>
    importErrorDialogMessages.push({
      message: ImportWarningMessageMap[warning],
      type: ImportErrorDialogMessageType.WARNING,
    })
  );
  [...backendErrors, ...internalErrors].forEach((errorCode) =>
    importErrorDialogMessages.push({
      message: ImportErrorMessageMap[errorCode],
      type: ImportErrorDialogMessageType.ERROR,
    })
  );
  return importErrorDialogMessages;
};
