import { CheckBoxStates } from '@constants/components';
import { Checked, Indeterminate, Unchecked } from '@icons';
import { MouseEventHandler } from 'react';

interface CheckboxProps {
  id?: string;
  value?: string;
  className?: string;
  handleChange?: MouseEventHandler<HTMLButtonElement>;
}

function Checkbox({ id, value, className, handleChange }: CheckboxProps) {
  function renderCheckbox() {
    switch (value) {
      case CheckBoxStates.CHECKED:
        return <Checked className={className} />;

      case CheckBoxStates.INDETERMINATE:
        return <Indeterminate className={className} />;

      default:
        return <Unchecked className={className} />;
    }
  }
  return (
    <button id={id} type='button' onClick={handleChange}>
      {renderCheckbox()}
    </button>
  );
}

export default Checkbox;
