/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  ScaleChartOptions,
  CoreChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  DatasetChartOptions,
  DoughnutControllerChartOptions,
  ChartData,
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';
import { useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';

export type centerObjectType = {
  text: string;
  fontSize: number;
  background?: string;
  color?: string;
  innerRadiusFactor: number;
};

const DoughnutChart = ({
  id,
  data,
  options,
}: {
  id?: string;
  data: ChartData<'doughnut', number[], unknown>;
  options: _DeepPartialObject<
    CoreChartOptions<'doughnut'> &
      ElementChartOptions<'doughnut'> &
      PluginChartOptions<'doughnut'> &
      DatasetChartOptions<'doughnut'> &
      ScaleChartOptions &
      DoughnutControllerChartOptions
  >;
}) => {
  useEffect(
    () =>
      // when component unmounts
      () => {
        ChartJS.getChart(id ?? 'doughnut')?.destroy();
      },
    []
  );
  ChartJS.register(ArcElement, Tooltip);

  return <Doughnut id={id ?? 'doughnut'} data={data} options={options} />;
};

const DefaultDoughnutChart = ({
  data,
  center,
}: {
  data: ChartData<'doughnut', number[], unknown>;
  center: centerObjectType;
}) => {
  const colorcenter = {
    id: 'colorcenter',
    beforeDraw(chart: any) {
      const { ctx } = chart;
      if (chart.config.type === 'doughnut') {
        const radius =
          // eslint-disable-next-line no-underscore-dangle
          chart._metasets[0].controller.innerRadius -
          chart.config.options.elements.center.innerRadiusFactor;
        const { x } = chart.getDatasetMeta(0).data[0];
        const { y } = chart.getDatasetMeta(0).data[0];
        ctx.arc(x, y, radius, 0, 2 * Math.PI);
        ctx.fillStyle =
          chart.config?.options?.elements?.center?.background ?? '#fff';
        ctx.fill();
        ctx.fillStyle =
          chart.config?.options?.elements?.center?.color ?? '#000';
        ctx.textAlign = 'center';
        ctx.font = `${
          chart.config?.options?.elements?.center?.fontSize ?? 18
        }px sans-serif`;
        const centerText: string =
          chart.config?.options?.elements?.center?.text ?? '';
        const lineHeight = 22;
        if (centerText.length) {
          const centerTextLines = centerText.split(' ');
          let centerY = centerTextLines.length > 1 ? y : y + 5;
          centerTextLines.forEach((text) => {
            ctx.fillText(text, x, centerY);
            centerY += lineHeight;
          });
        }
      }
    },
  };

  ChartJS.register(ArcElement, Tooltip, colorcenter);

  const options: _DeepPartialObject<
    CoreChartOptions<'doughnut'> &
      ElementChartOptions<'doughnut'> &
      PluginChartOptions<'doughnut'> &
      DatasetChartOptions<'doughnut'> &
      ScaleChartOptions &
      DoughnutControllerChartOptions &
      any
  > = {
    maintainAspectRatio: false,
    cutout: '90%',
    radius: 90,
    backgroundColor: '#F5F6FA',
    animation: { animateRotate: true },
    elements: {
      center,
    },
  };
  return <DoughnutChart data={data} options={options} />;
};

export default DoughnutChart;

export { DefaultDoughnutChart };
