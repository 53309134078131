import { twMerge } from 'tailwind-merge';
import { ButtonTypes } from '@constants/components';

import Button from '../button/Button';

interface ListEmptyStateProps {
  imgSrc: string;
  title: string;
  description?: string;
  buttonLabel: string;
  containerClassName?: string;
  to?: string;
  onButtonClick?: VoidFunction;
  linkState?: unknown;
  buttonVisible?: boolean;
  onSecondaryButtonClick?: VoidFunction;
  secondaryButtonLabel?: string;
  secondaryPath?: string;
}

function ListEmptyState({
  imgSrc,
  title,
  description,
  buttonLabel,
  containerClassName,
  to,
  linkState,
  onButtonClick,
  buttonVisible = true,
  onSecondaryButtonClick,
  secondaryButtonLabel,
  secondaryPath,
}: ListEmptyStateProps) {
  return (
    <div
      className={twMerge(
        'flex flex-col justify-center items-center w-full text-center',
        containerClassName
      )}
    >
      <img src={imgSrc} alt='empty_state' className='mb-10' />
      <h3 className='mb-3 text-xl font-medium text-black'>{title}</h3>
      {description && (
        <p className='text-lg text-blue-magenta-faded sm:max-w-[50%]'>
          {description}
        </p>
      )}
      {buttonVisible && (
        <div className='flex justify-between mt-6'>
          {secondaryButtonLabel && (
            <Button
              label={secondaryButtonLabel}
              type={ButtonTypes.DEFAULT}
              to={secondaryPath}
              handleClick={onSecondaryButtonClick}
              linkState={linkState}
              className='mr-5'
            />
          )}
          {buttonLabel && (
            <Button
              label={buttonLabel}
              type={ButtonTypes.FILLED}
              to={to}
              handleClick={onButtonClick}
              linkState={linkState}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default ListEmptyState;
