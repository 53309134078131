import { TestcaseStatus } from './components';

export enum TestRunStatuses {
  TODO = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum TestCaseTabs {
  DETAILS = 'DETAILS',
  STATUS_AND_RESULTS = 'STATUS_AND_RESULTS',
}

export const TESTRUN_NAME_MAXLENGTH = 255;
export const TESTRUN_DESCRIPTION_MAXLENGTH = 255;
export const VERSION_MAX_LENGTH = 25;
export const allowedTestCaseTransitionStates = new Map([
  [
    TestcaseStatus.UNTESTED,
    [TestcaseStatus.PASSED, TestcaseStatus.FAILED, TestcaseStatus.BLOCKED],
  ],
  [
    TestcaseStatus.PASSED,
    [TestcaseStatus.FAILED, TestcaseStatus.BLOCKED, TestcaseStatus.RETEST],
  ],
  [
    TestcaseStatus.FAILED,
    [TestcaseStatus.PASSED, TestcaseStatus.BLOCKED, TestcaseStatus.RETEST],
  ],
  [
    TestcaseStatus.BLOCKED,
    [TestcaseStatus.FAILED, TestcaseStatus.PASSED, TestcaseStatus.RETEST],
  ],
  [
    TestcaseStatus.RETEST,
    [TestcaseStatus.FAILED, TestcaseStatus.PASSED, TestcaseStatus.BLOCKED],
  ],
]);

export enum TestRunActions {
  EDIT = 'EDIT',
  MARK_AS_COMPLETED = 'MARK_AS_COMPLETED',
  DELETE = 'DELETE',
}
