import { FC, Fragment } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useI18n } from '@hooks';
import { useGetProjectDetailsQuery } from '@containers/projects/store/api';
import { injectParamsToRoute } from '@utils/navigation';
import RoutePaths from '@routes/RoutesPath';
import { useGetTestCaseByIdQuery } from '@containers/test-suite/store/api';
import { useGetMilestoneByIdQuery } from '@containers/milestones/store/api';
import { useTestrunByIdQuery } from '@containers/test-runs/store/api';

const BreadCrumbs: FC = () => {
  const location = useLocation();
  const { t } = useI18n([
    'sideNavigation',
    'test-runs/addTestRun',
    'milestones/addMilestone',
    'testSuite/addTestCase',
    'testcases/importTestcase',
    'testcases/importHistory',
    'projects/projectOverview',
  ]);
  const {
    projectId = '',
    milestoneId = '',
    testCaseId = '',
    testRunId = '',
  } = useParams();
  const { data, isLoading } = useGetProjectDetailsQuery(projectId, {
    skip: !projectId,
  });
  const { data: testCase, isLoading: isLoadingTestCase } =
    useGetTestCaseByIdQuery(testCaseId, { skip: !testCaseId });
  const { data: milestoneByIdData, isLoading: isFetchingMilestoneById } =
    useGetMilestoneByIdQuery(milestoneId, { skip: !milestoneId });
  const { data: testrunByIdData, isLoading: isFetchingTestrunById } =
    useTestrunByIdQuery({ id: testRunId }, { skip: !testRunId });

  const getProjectIdInjectedRoute = (route: string) =>
    injectParamsToRoute(route, { projectId });

  const getTestCaseIdInjectedRoute = (route: string) =>
    injectParamsToRoute(route, { projectId, testCaseId });

  const routeMapping: {
    [key: string]: { title: string; disabled?: boolean };
  } = {
    [RoutePaths.PROJECTS.ROOT]: { title: t('routeNames.projects.root') },
    [`/projects/${projectId}`]: {
      title: data?.name || projectId,
      disabled: true,
    },
    [getProjectIdInjectedRoute(RoutePaths.MILESTONES.ROOT)]: {
      title: t('routeNames.projects.milestones'),
    },
    [injectParamsToRoute(RoutePaths.MILESTONES.OVERVIEW, {
      projectId,
      milestoneId,
    })]: {
      title: milestoneByIdData?.name || '',
    },
    [injectParamsToRoute(RoutePaths.MILESTONES.EDIT, {
      projectId,
      milestoneId,
    })]: {
      title: t('milestones/addMilestone:editTitle'),
    },
    [getProjectIdInjectedRoute(RoutePaths.TEST_RUNS.ROOT)]: {
      title: t('routeNames.projects.testRuns'),
    },
    [getProjectIdInjectedRoute(RoutePaths.TEST_RUNS.ADD)]: {
      title: t('test-runs/addTestRun:title'),
    },
    [injectParamsToRoute(RoutePaths.TEST_RUNS.OVERVIEW, {
      projectId,
      testRunId,
    })]: {
      title: testrunByIdData?.name || testRunId,
    },
    [getProjectIdInjectedRoute(RoutePaths.MILESTONES.ADD)]: {
      title: t('milestones/addMilestone:addTitle'),
    },
    [getProjectIdInjectedRoute(RoutePaths.TEST_SUITE.ROOT)]: {
      title: t('routeNames.projects.testSuite'),
    },
    [getProjectIdInjectedRoute(RoutePaths.TEST_SUITE.ADD)]: {
      title: t('testSuite/addTestCase:addTestCase'),
    },
    [getTestCaseIdInjectedRoute(RoutePaths.TEST_SUITE.DETAILS)]: {
      title: testCase?.extId || testCaseId,
    },
    [getTestCaseIdInjectedRoute(RoutePaths.TEST_SUITE.EDIT)]: {
      title: t('testSuite/addTestCase:editTestCase') || testCaseId,
    },
    [injectParamsToRoute(RoutePaths.TEST_RUNS.EDIT, { projectId, testRunId })]:
      {
        title: t('test-runs/addTestRun:editTitle'),
      },
    [getProjectIdInjectedRoute(RoutePaths.TEST_SUITE.IMPORT)]: {
      title: t('testcases/importTestcase:title'),
    },
    [getProjectIdInjectedRoute(RoutePaths.TEST_SUITE.IMPORT_HISTORY)]: {
      title: t('testcases/importHistory:breadCrumb'),
    },
    [getProjectIdInjectedRoute(RoutePaths.PROJECTS.OVERVIEW)]: {
      title: t('projects/projectOverview:breadCrumbs'),
    },
    /** TODO: add routes and corresponding breadcrumbs titles as urls are defined  */
  };

  const pathname = location.pathname
    .split('/')
    .filter((element) => element.length > 0);

  let pathnameHierarchy = '';
  return !(
    isLoading ||
    isLoadingTestCase ||
    isFetchingMilestoneById ||
    isFetchingTestrunById
  ) ? (
    <div className='flex flex-row mb-2 text-sm text-[#605E5E]'>
      {pathname.map((path, index) => {
        pathnameHierarchy += `/${path}`;
        const target = routeMapping[pathnameHierarchy];
        return (
          target && (
            <Fragment key={pathnameHierarchy}>
              {target.disabled ? (
                <p className='max-w-[200px] text-accent-primary  truncate whitespace-pre'>
                  {target.title}
                </p>
              ) : (
                <Link
                  className='max-w-[200px] text-accent-primary truncate whitespace-pre'
                  to={pathnameHierarchy}
                >
                  {target.title}
                </Link>
              )}
              {index !== pathname.length - 1 && <p className='px-1'>/</p>}
            </Fragment>
          )
        );
      })}
    </div>
  ) : (
    <div className='mt-1 mb-3 w-60 h-3 rounded skeleton-shimmer' />
  );
};

export default BreadCrumbs;
