import { IS_LOGGED_IN } from '@constants/authentication';

import { setLocalStorageItem } from './storage';

const logoutUser = () => {
  setLocalStorageItem(IS_LOGGED_IN, false);
  window.location.reload();
};

export { logoutUser };
