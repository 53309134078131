import {
  EntityName,
  GlobalPermissionType,
  PermissionsForUser,
  ProjectPermissionType,
} from '@customTypes/permission';

export const parseAndGetGlobalPermissions = (
  globalPermissionsForUser: PermissionsForUser[] = []
): GlobalPermissionType => {
  const globalPermissions = {} as any;
  globalPermissionsForUser.forEach((permissions: PermissionsForUser) => {
    globalPermissions[permissions.entityName] = permissions;
  });
  return {
    globalProjectPermissions: globalPermissions[EntityName.PROJECT] || {},
    globalUsersPermissions: globalPermissions[EntityName.USERS] || {},
    globalRolesPermissions: globalPermissions[EntityName.ROLES] || {},
  };
};

export const parseAndGetProjectPermissions = (
  projectPermissionsForUser: PermissionsForUser[] = []
): ProjectPermissionType => {
  const projectPermissions = {} as any;
  projectPermissionsForUser.forEach((permissions: PermissionsForUser) => {
    projectPermissions[permissions.entityName] = permissions;
  });
  return {
    testRunPermissions: projectPermissions[EntityName.TESTRUN] || {},
    milestonePermissions: projectPermissions[EntityName.MILESTONE] || {},
    testSuitePermissions: projectPermissions[EntityName.TESTSUITE] || {},
  };
};
