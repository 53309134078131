export interface Role {
  id: string;
  name: string;
  systemGenerated: boolean;
}

export enum ProjectEntityName {
  TEST_SUITE = 'TESTSUITE',
  TEST_RUN = 'TESTRUN',
  MILESTONE = 'MILESTONE',
}

export enum GlobalEntityName {
  PROJECT = 'PROJECT',
  USERS = 'USERS',
  ROLES = 'ROLES',
}

export interface RolePermissionsSummary {
  roleId: string;
  roleName: string;
  entityId: string;
  entityName: ProjectEntityName;
  entityRead: boolean;
  entityCreate: boolean;
  entityDelete: boolean;
  entityUpdate: boolean;
}

export interface CreateRoleInput {
  name: string;
}

export interface CreateRole {
  id: string;
}

export type EntityPermissionMap = Map<
  ProjectEntityName | GlobalEntityName,
  {
    id: string;
    permissions: boolean[];
  }
>;

export type UpdateRolePermissionsInput = {
  roleId: string;
  updateRolePermissionsInput: {
    entityId: string;
    entityCreate?: boolean;
    entityDelete?: boolean;
    entityRead?: boolean;
    entityUpdate?: boolean;
  };
};

export interface UpdateRolePermissionResponse {
  updateRolePermissions: Partial<RolePermissionsSummary>[];
}

export interface ProjectEntity {
  id: string;
  name: ProjectEntityName;
}

export interface GlobalEntity {
  id: string;
  name: GlobalEntityName;
}

export enum EntityPermission {
  ENTITY_READ = 'entityRead',
  ENTITY_CREATE = 'entityCreate',
  ENTITY_UPDATE = 'entityUpdate',
  ENTITY_DELETE = 'entityDelete',
}

export type EntityName = ProjectEntityName | GlobalEntityName;

export type Entities = GlobalEntity[] | ProjectEntity[];

export type EntityNames = ProjectEntityName[] | GlobalEntityName[];

export enum RoleType {
  GLOBAL = 'GLOBAL',
  PROJECT = 'PROJECT',
}

export type DeleteRoleResponse = {
  deleteRole: { id: boolean };
};
