import { Link } from 'react-router-dom';

import { useI18n } from '@hooks';
import { UserAuthFailedIcon } from '@images';

const LoginFailed = (): JSX.Element => {
  const { t } = useI18n(['login']);

  return (
    <div className='flex flex-col items-center space-y-4'>
      <img
        src={UserAuthFailedIcon}
        alt='Could not login'
        className='w-24 h-24'
      />
      <p>{t('somethingWentWrong')}</p>
      <Link className='p-3 text-white bg-blue-500 rounded-md' to='/login'>
        {t('pleaseTryAgain')}
      </Link>
    </div>
  );
};
export default LoginFailed;
