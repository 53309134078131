import debounce from 'lodash/debounce';

const getDebouncedCallback = (callback: (...args: any) => any, wait = 500) =>
  debounce(callback, wait);

const isArrayEqual = (array1: string[], array2: string[]) =>
  JSON.stringify(array1.sort()) === JSON.stringify(array2.sort());

const tryParse = (item: string) => {
  try {
    return JSON.parse(item || '');
  } catch {
    return item;
  }
};

const isNumber = (input: any) => /^[0-9]+$/.test(input);

const downloadFile = async (
  downloadUrl: string,
  fileName: string,
  onFail?: () => void
) => {
  try {
    const response = await fetch(downloadUrl);
    const blob = await response.blob();

    const fileURL = window.URL.createObjectURL(blob);
    const alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = fileName;
    alink.click();
  } catch (error) {
    onFail?.();
  }
};

function getLastUrlSegment(url: string) {
  return new URL(url).pathname.split('/').filter(Boolean).pop();
}

export {
  getDebouncedCallback,
  isArrayEqual,
  tryParse,
  isNumber,
  downloadFile,
  getLastUrlSegment,
};
