import { useSearchParams } from 'react-router-dom';

import { AUTH_FAIL_CODE } from '@constants/authentication';

import ContactAdmin from './components/ContactAdmin';
import LoginFailed from './components/LoginFailed';

const AuthenticationFailed = (): JSX.Element => {
  const [queryParams] = useSearchParams();
  const authCode: AUTH_FAIL_CODE =
    (queryParams.get('code') as AUTH_FAIL_CODE) ||
    AUTH_FAIL_CODE.INVALID_GOOGLE_USER_INFO;

  const getAuthFailedComponent = (
    authFailCode: AUTH_FAIL_CODE | null
  ): JSX.Element => {
    switch (authFailCode) {
      case AUTH_FAIL_CODE.TENANT_ACCESS_DENIED:
      case AUTH_FAIL_CODE.USER_NOT_FOUND:
        return <ContactAdmin />;
      case AUTH_FAIL_CODE.INVALID_GOOGLE_USER_INFO:
      default:
        return <LoginFailed />;
    }
  };
  return (
    <div className="flex justify-center items-center w-screen h-screen bg-[url('../assets/images/LoginBackground.svg')]">
      <div className='flex flex-col justify-center items-center p-10 bg-white rounded-lg shadow-[0_2px_10px_rgba(0,0,0,0.1)]'>
        {getAuthFailedComponent(authCode)}
      </div>
    </div>
  );
};
export default AuthenticationFailed;
