import { TagTypes } from '@constants/common';
import {
  DeleteTestCaseFromTestRun,
  DeleteTestCaseFromTestRunResponse,
  GetAttachmentDownloadUrlInput,
} from '@containers/test-suite/types';
import { Page } from '@customTypes/common';
import api from '@services/api';
import {
  CreateTestRunInput,
  CreateTestRunResponse,
  GetTestCaseExecutionHistoryPayload,
  GetAllTestrunExecutionsByTestrunIdInput,
  GetAllTestrunExecutionsByTestrunIdResponse,
  GetTestRunInput,
  GetTestRunResponse,
  TestCaseHistory,
  GetTestRunWithQueryParamsInput,
  GetTestRunWithQueryParamsResponse,
  TestRun,
  TestRunWithQueryParams,
  SearchTestrunsResponse,
  TestrunExecution,
  TestcaseExecution,
  GetTestcaseExecutionsByTestrunExecIdInput,
  GetTestcaseExecutionsByTestrunExecIdResponse,
  updateTestrunByIdInput,
  updateTestrunByIdResponse,
  UpdateTestcaseExecutionStatusInput,
  UpdateTestcaseExecutionStatusResponse,
  TestrunByIdInput,
  TestrunByIdResponse,
  SearchTestrunsInput,
  TestRunById,
  DeleteTestrunByIdResponse,
  UpdateTestrunExecutionStatusResponse,
  UpdateTestrunExecutionStatus,
  UpdateTestrunExecutionStatusInput,
  TestRunDetailed,
  GetTestcaseStatusChangeAttachmentUploadUrlInput,
  TestcaseStatusChangeFileUploadUrlResponse,
  TestcaseStatusChangeFileDownloadUrlResponse,
  TestRunExecutionData,
  GetTestrunExecutionsDataResponse,
  ProjectUser,
} from '../types';
import {
  createTestRunMutation,
  getTestCaseExecutionHistoryQuery,
  getAllTestrunExecutionsByTestrunIdQuery,
  getTestcaseExecutionsByTestrunExecIdQuery,
  getTestRunsQuery,
  getTestRunsWithQueryParamsQuery,
  searchTestrunsQuery,
  updateTestcaseExecutionStatusMutation,
  updateTestrunByIdMutation,
  testrunByIdQuery,
  deleteTestRunMutation,
  updateTestrunExecutionStatusMutation,
  getTestRunDetailedByIdQuery,
  getTestcaseStatusChangeAttachmentUploadUrlQuery,
  getTestcaseStatusChangeAttachmentDownloadUrlQuery,
  deleteTestcaseFromTestrunMutation,
  getTestrunExecutionDataQuery,
  getUsersInProjectQuery,
} from './queries';

const testRunsApis = api.injectEndpoints({
  endpoints: (builder) => ({
    createTestRun: builder.mutation<string, CreateTestRunInput>({
      query: (createTestRunInput) => ({
        document: createTestRunMutation,
        variables: {
          createTestRunInput,
        },
      }),
      extraOptions: {
        successMessageKey: 'test-runs/addTestRun:addTestRun',
      },
      invalidatesTags: [
        TagTypes.TEST_RUNS.TEST_RUNS,
        TagTypes.TEST_RUNS.TEST_RUN_WITH_SEARCH_PARAMS,
        TagTypes.PROJECTS.PROJECTS,
        TagTypes.MILESTONE.MILESTONE,
        TagTypes.MILESTONE.MILESTONES,
        TagTypes.TEST_CASE.FAILED_TEST_CASE_DETAILS,
        TagTypes.TEST_CASE.TEST_CASE_EXECUTION_DETAILS,
      ],
      transformResponse: (response: CreateTestRunResponse) =>
        response?.createTestrun.id,
    }),
    getTestRuns: builder.query<Page<TestRun>, GetTestRunInput>({
      query: (variables) => ({
        document: getTestRunsQuery,
        variables,
      }),
      providesTags: [TagTypes.TEST_RUNS.TEST_RUNS],
      transformResponse: (response: GetTestRunResponse) =>
        response?.allTestrunsByProjectId,
    }),
    getTestCaseExecutionHistory: builder.query<
      TestCaseHistory[],
      GetTestCaseExecutionHistoryPayload
    >({
      query: (variables) => ({
        document: getTestCaseExecutionHistoryQuery,
        variables,
      }),
      providesTags: [TagTypes.TEST_RUNS.TEST_CASE_HISTORY],
      transformResponse: (response: {
        getTestcaseStatusHistoryByTestrunExecId: TestCaseHistory[];
      }) => response?.getTestcaseStatusHistoryByTestrunExecId,
    }),
    getTestRunWithSearchParams: builder.query<
      Page<TestRunWithQueryParams>,
      GetTestRunWithQueryParamsInput
    >({
      query: (variables) => ({
        document: getTestRunsWithQueryParamsQuery,
        variables,
      }),
      transformResponse: (response: GetTestRunWithQueryParamsResponse) =>
        response?.allTestrunsByProjectId,
      providesTags: [
        TagTypes.MILESTONE.MILESTONES,
        TagTypes.TEST_RUNS.TEST_RUN_WITH_SEARCH_PARAMS,
      ],
    }),
    searchTestruns: builder.query<
      TestRunWithQueryParams[],
      SearchTestrunsInput
    >({
      query: (variables) => ({
        document: searchTestrunsQuery,
        variables,
      }),
      transformResponse: (response: SearchTestrunsResponse) =>
        response?.searchTestruns,
    }),
    getAllTestrunExecutionsByTestrunId: builder.query<
      Page<TestrunExecution>,
      GetAllTestrunExecutionsByTestrunIdInput
    >({
      query: (variables) => ({
        document: getAllTestrunExecutionsByTestrunIdQuery,
        variables,
      }),
      transformResponse: (
        response: GetAllTestrunExecutionsByTestrunIdResponse
      ) => response.getAllTestrunExecutionsByTestrunId,
      providesTags: [TagTypes.TEST_RUNS.ALL_TESTRUN_EXECUTIONS_BY_TESTRUN_ID],
    }),
    getTestcaseExecutionsByTestrunExecId: builder.query<
      TestcaseExecution[],
      GetTestcaseExecutionsByTestrunExecIdInput
    >({
      query: (variables) => ({
        document: getTestcaseExecutionsByTestrunExecIdQuery,
        variables,
      }),
      providesTags: [TagTypes.TEST_RUNS.TESTCASE_EXECUTIONS_BY_TESTRUN_EXEC_ID],
      transformResponse: (
        response: GetTestcaseExecutionsByTestrunExecIdResponse
      ) => response.getTestcaseExecutionsByTestrunExecId,
    }),
    updateTestrunById: builder.mutation<
      Pick<TestRun, 'id'>,
      updateTestrunByIdInput
    >({
      query: (variables) => ({
        document: updateTestrunByIdMutation,
        variables,
      }),
      invalidatesTags: [
        TagTypes.TEST_RUNS.TESTCASE_EXECUTIONS_BY_TESTRUN_EXEC_ID,
        TagTypes.MILESTONE.MILESTONE,
        TagTypes.MILESTONE.MILESTONES,
        TagTypes.TEST_RUNS.TEST_RUNS,
        TagTypes.TEST_RUNS.TEST_RUN_DETAILED,
        TagTypes.TEST_RUNS.TEST_RUN_WITH_SEARCH_PARAMS,
        TagTypes.TEST_RUNS.TEST_RUN_DETAILED,
        TagTypes.TEST_RUNS.TEST_RUN_BY_ID,
        TagTypes.TEST_RUNS.ALL_TESTRUN_EXECUTIONS_BY_TESTRUN_ID,
        TagTypes.TEST_CASE.FAILED_TEST_CASE_DETAILS,
        TagTypes.TEST_CASE.TEST_CASE_EXECUTION_DETAILS,
      ],
      transformResponse: (response: updateTestrunByIdResponse) =>
        response.updateTestrunById,
    }),
    updateTestcaseExecutionStatus: builder.mutation<
      Pick<TestcaseExecution, 'id'>,
      UpdateTestcaseExecutionStatusInput
    >({
      query: (variables) => ({
        document: updateTestcaseExecutionStatusMutation,
        variables,
      }),
      invalidatesTags: [
        TagTypes.TEST_RUNS.TEST_CASE_HISTORY,
        TagTypes.TEST_RUNS.TESTCASE_EXECUTIONS_BY_TESTRUN_EXEC_ID,
        TagTypes.MILESTONE.MILESTONE,
        TagTypes.TEST_RUNS.TEST_RUNS,
        TagTypes.TEST_RUNS.TEST_RUN_BY_ID,
        TagTypes.TEST_RUNS.ALL_TESTRUN_EXECUTIONS_BY_TESTRUN_ID,
        TagTypes.TEST_CASE.FAILED_TEST_CASE_DETAILS,
        TagTypes.TEST_CASE.TEST_CASE_EXECUTION_DETAILS,
      ],
      transformResponse: (response: UpdateTestcaseExecutionStatusResponse) =>
        response.updateTestcaseExecutionStatus,
    }),
    testrunById: builder.query<TestRunById, TestrunByIdInput>({
      query: (variables) => ({
        document: testrunByIdQuery,
        variables,
      }),
      transformResponse: (response: TestrunByIdResponse) =>
        response.testrunById,
      providesTags: [TagTypes.TEST_RUNS.TEST_RUN_BY_ID],
    }),
    deleteTestrun: builder.mutation<boolean, string>({
      query: (id) => ({
        document: deleteTestRunMutation,
        variables: {
          id,
        },
      }),
      extraOptions: {
        successMessageKey: 'test-runs/addTestRun:deletedSuccessfully',
        failureMessageKey: 'test-runs/addTestRun:deletionFailed',
      },
      invalidatesTags: [
        TagTypes.TEST_RUNS.TEST_RUNS,
        TagTypes.MILESTONE.MILESTONE,
        TagTypes.MILESTONE.MILESTONES,
        TagTypes.TEST_RUNS.TEST_RUN_WITH_SEARCH_PARAMS,
        TagTypes.PROJECTS.PROJECTS,
        TagTypes.TEST_CASE.FAILED_TEST_CASE_DETAILS,
        TagTypes.TEST_CASE.TEST_CASE_EXECUTION_DETAILS,
      ],
      transformResponse: (response: DeleteTestrunByIdResponse) =>
        response?.deleteTestrunById,
    }),
    updateTestrunExecutionStatus: builder.mutation<
      UpdateTestrunExecutionStatus,
      UpdateTestrunExecutionStatusInput
    >({
      query: (variables) => ({
        document: updateTestrunExecutionStatusMutation,
        variables,
      }),
      transformResponse: (response: UpdateTestrunExecutionStatusResponse) =>
        response.updateTestrunExecutionStatus,
      invalidatesTags: [
        TagTypes.TEST_RUNS.TEST_RUNS,
        TagTypes.TEST_RUNS.ALL_TESTRUN_EXECUTIONS_BY_TESTRUN_ID,
        TagTypes.MILESTONE.MILESTONE,
        TagTypes.MILESTONE.MILESTONES,
        TagTypes.PROJECTS.PROJECTS,
        TagTypes.TEST_CASE.FAILED_TEST_CASE_DETAILS,
        TagTypes.TEST_CASE.TEST_CASE_EXECUTION_DETAILS,
      ],
      extraOptions: {
        successMessageKey: 'test-runs/testRunOverview:statusUpdationSuccess',
        failureMessageKey: 'test-runs/testRunOverview:statusUpdationFailed',
      },
    }),
    getTestRunDetailedById: builder.query<TestRunDetailed, string>({
      query: (id) => ({
        document: getTestRunDetailedByIdQuery,
        variables: {
          id,
        },
      }),
      providesTags: [
        TagTypes.MILESTONE.MILESTONES,
        TagTypes.TEST_RUNS.TEST_RUN_DETAILED,
      ],
      transformResponse: (response: { testrunById: TestRunDetailed }) =>
        response.testrunById,
    }),
    getTestcaseStatusChangeAttachmentUploadUrl: builder.query<
      TestcaseStatusChangeFileUploadUrlResponse,
      GetTestcaseStatusChangeAttachmentUploadUrlInput
    >({
      query: (variables) => ({
        document: getTestcaseStatusChangeAttachmentUploadUrlQuery,
        variables,
      }),
    }),
    getTestcaseStatusChangeAttachmentDownloadUrl: builder.query<
      TestcaseStatusChangeFileDownloadUrlResponse,
      GetAttachmentDownloadUrlInput
    >({
      query: (variables) => ({
        document: getTestcaseStatusChangeAttachmentDownloadUrlQuery,
        variables,
      }),
    }),
    deleteTestcaseFromTestrun: builder.mutation<
      DeleteTestCaseFromTestRunResponse,
      DeleteTestCaseFromTestRun
    >({
      query: (variables) => ({
        document: deleteTestcaseFromTestrunMutation,
        variables,
      }),
      invalidatesTags: [
        TagTypes.TEST_RUNS.ALL_TESTRUN_EXECUTIONS_BY_TESTRUN_ID,
        TagTypes.TEST_RUNS.TEST_RUNS,
        TagTypes.TEST_RUNS.TEST_RUN_BY_ID,
        TagTypes.TEST_CASE.FAILED_TEST_CASE_DETAILS,
        TagTypes.TEST_CASE.TEST_CASE_EXECUTION_DETAILS,
      ],
    }),
    getTestRunExecutionData: builder.query<
      Page<TestRunExecutionData>,
      GetAllTestrunExecutionsByTestrunIdInput
    >({
      query: (variables) => ({
        document: getTestrunExecutionDataQuery,
        variables,
      }),
      transformResponse: (response: GetTestrunExecutionsDataResponse) =>
        response.getAllTestrunExecutionsByTestrunId,
      providesTags: [TagTypes.TEST_RUNS.ALL_TESTRUN_EXECUTIONS_BY_TESTRUN_ID],
    }),
    getUsersInProject: builder.query<ProjectUser[], { projectId: string }>({
      query: (variables) => ({
        document: getUsersInProjectQuery,
        variables,
      }),
      transformResponse: (response: { getUsersInProject: ProjectUser[] }) =>
        response.getUsersInProject,
      providesTags: [TagTypes.TEST_RUNS.PROJECT_USERS],
    }),
  }),
});

export const {
  useCreateTestRunMutation,
  useGetTestRunsQuery,
  useGetTestCaseExecutionHistoryQuery,
  useGetTestRunWithSearchParamsQuery,
  useGetAllTestrunExecutionsByTestrunIdQuery,
  useLazyGetTestcaseExecutionsByTestrunExecIdQuery,
  useUpdateTestrunByIdMutation,
  useUpdateTestcaseExecutionStatusMutation,
  useLazySearchTestrunsQuery,
  useTestrunByIdQuery,
  useLazyTestrunByIdQuery,
  useDeleteTestrunMutation,
  useUpdateTestrunExecutionStatusMutation,
  useGetTestRunDetailedByIdQuery,
  useLazyGetTestRunDetailedByIdQuery,
  useLazyGetTestcaseStatusChangeAttachmentUploadUrlQuery,
  useLazyGetTestcaseStatusChangeAttachmentDownloadUrlQuery,
  useDeleteTestcaseFromTestrunMutation,
  useLazyGetTestRunExecutionDataQuery,
  useGetUsersInProjectQuery,
} = testRunsApis;
