import { ReactNode } from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { TooltipDirections } from '@constants/components';

type TooltipProps = {
  child: ReactNode;
  content: string;
  position?: TooltipDirections;
  delayDuration?: number;
};

const Tooltip = ({
  child,
  content,
  delayDuration = 0,
  position = TooltipDirections.TOP,
}: TooltipProps) => (
  <TooltipPrimitive.Provider>
    <TooltipPrimitive.Root delayDuration={delayDuration}>
      <TooltipPrimitive.Trigger asChild>{child}</TooltipPrimitive.Trigger>
      <TooltipPrimitive.Content
        className='py-1 px-2 text-[14px] font-normal text-black-gray bg-white rounded-lg drop-shadow-[0_2px_10px_rgba(0,0,0,0.1)]'
        side={position}
      >
        <p className='overflow-auto max-w-[400px] max-h-[150px] break-all white-scroll'>
          {content}
        </p>
        <TooltipPrimitive.Arrow className='w-[11px] h-[9.5px] fill-white' />
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Root>
  </TooltipPrimitive.Provider>
);

export default Tooltip;
