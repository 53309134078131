import { useEffect, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { Root, Content, Overlay, Portal, Title } from '@radix-ui/react-dialog';

import { Cross } from '@icons';

interface BaseDialogProps {
  open: boolean;
  children: ReactNode;
  contentClassName?: string;
  onClose?: VoidFunction;
}

const BaseDialog = ({
  open,
  children,
  contentClassName,
  onClose,
}: BaseDialogProps) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.code === 'Escape' && onClose) {
        onClose();
      }
    };
    if (open && onClose) {
      document.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [open, onClose]);

  return (
    <Root open={open}>
      <Portal>
        <Overlay className='fixed inset-0 z-10 bg-black opacity-20' />
        <Content
          className={twMerge(
            'fixed top-1/2 left-1/2 z-20 max-w-3xl max-h-[85vh] rounded-lg focus:outline-none -translate-x-1/2 -translate-y-1/2 overflow-y-auto',
            contentClassName
          )}
        >
          {children}
        </Content>
      </Portal>
    </Root>
  );
};

interface DefaultDialogProps extends BaseDialogProps {
  title: string;
  titleClassName?: string;
  showHeader?: boolean;
}

const DefaultDialog = ({
  open,
  children,
  title,
  titleClassName,
  contentClassName,
  onClose,
  showHeader = true,
}: DefaultDialogProps) => (
  <BaseDialog
    open={open}
    contentClassName={twMerge('bg-light-greyish-blue', contentClassName)}
    onClose={onClose}
  >
    {showHeader && (
      <div className='flex justify-between items-center py-5 pr-7 pl-9 border-b border-b-grey-border '>
        <Title
          className={twMerge(
            'mr-5 text-2xl font-semibold text-black-primary truncate w-[calc(100%-24px)]',
            titleClassName
          )}
        >
          {title}
        </Title>
        <button type='button' onClick={onClose}>
          <Cross />
        </button>
      </div>
    )}
    <div className='px-9 pt-5 pb-7 '>{children}</div>
  </BaseDialog>
);

export { BaseDialog };

export default DefaultDialog;
