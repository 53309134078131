export enum EntityName {
  PROJECT = 'PROJECT',
  USERS = 'USERS',
  TESTRUN = 'TESTRUN',
  TESTCASE = 'TESTCASE',
  MILESTONE = 'MILESTONE',
  TESTSUITE = 'TESTSUITE',
  ROLES = 'ROLES',
}

export interface PermissionsForUser {
  entityName: EntityName;
  entityRead: boolean;
  entityCreate: boolean;
  entityUpdate: boolean;
  entityDelete: boolean;
}

export interface PermissionsForUserResponse {
  getGlobalPermissionsForUser: PermissionsForUser[];
}

export interface GlobalPermissionType {
  globalProjectPermissions: PermissionsForUser;
  globalUsersPermissions: PermissionsForUser;
  globalRolesPermissions: PermissionsForUser;
}

export interface ProjectPermissionType {
  testRunPermissions: PermissionsForUser;
  milestonePermissions: PermissionsForUser;
  testSuitePermissions: PermissionsForUser;
}
