import {
  forwardRef,
  Fragment,
  ReactNode,
  Ref,
  SyntheticEvent,
  useMemo,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { Listbox, Transition } from '@headlessui/react';

import { CaretDown } from '@icons';
import { useI18n } from '@hooks';

interface SelectProps {
  options: { label: string | ReactNode; value: string }[];
  triggerStyles?: string;
  caretStyles?: string;
  contentStyles?: string;
  optionStyles?: string;
  placeholder?: string;
  placeholderTextStyle?: string;
  onSelect?: (value: string) => void;
  errorMessage?: string;
  defaultValue?: string;
  disabled?: boolean;
  disableEventPropagation?: boolean;
}

const Select = (
  {
    options,
    contentStyles,
    caretStyles,
    triggerStyles,
    optionStyles,
    placeholder,
    placeholderTextStyle,
    onSelect,
    errorMessage,
    defaultValue,
    disabled = false,
    disableEventPropagation = false,
  }: SelectProps,
  _: Ref<ReactNode>
) => {
  const { t } = useI18n(['common']);

  const selectedItem = useMemo(
    () => options.find((option) => option.value === defaultValue),
    [defaultValue, options]
  );
  const handleSelectorWrapperClick = (e: SyntheticEvent) => {
    e.stopPropagation();
  };
  return (
    <Listbox value={defaultValue} onChange={onSelect} disabled={disabled}>
      <div className='relative'>
        <Listbox.Button
          className={twMerge(
            `flex justify-between items-center  focus:outline-none ${
              errorMessage ? ' border-red-primary' : 'border-light-lavender '
            } py-[10px] px-[15px]  text-sm text-light-slate-grey bg-white rounded-md border [&data-placeholder]:text-black`,
            triggerStyles
          )}
          onClick={
            disableEventPropagation ? handleSelectorWrapperClick : undefined
          }
        >
          <span
            className={twMerge(
              'block text-sm font-normal truncate',
              !defaultValue && placeholderTextStyle
            )}
          >
            {selectedItem ? selectedItem.label : placeholder ?? t('select')}
          </span>
          <CaretDown className={twMerge('text-dark-gray', caretStyles)} />
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Listbox.Options
            className={twMerge(
              'overflow-auto absolute z-10 mt-1 w-full max-h-60 text-base bg-white rounded focus:outline-none shadow-md',
              contentStyles
            )}
          >
            {options?.map(({ label, value }, index) => (
              <Listbox.Option
                key={value}
                className={({ active }) =>
                  twMerge(
                    `relative p-[10px] text-left hover:cursor-pointer focus:outline-none focus:bg-gray-50 text-light-slate-grey text-sm`,
                    index !== options.length - 1
                      ? 'border-b-[1px] border-b-light-lavender'
                      : '',
                    active ? 'bg-gray-50' : 'text-gray-900',
                    optionStyles
                  )
                }
                value={value}
              >
                <span className=' flex flex-row items-center text-sm font-normal text-light-slate-grey'>
                  {label}
                </span>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default forwardRef(Select);
