import { useState } from 'react';

import { NotificationTypes } from '@constants/components';
import { entityPermissions } from '@constants/members';
import {
  Entities,
  EntityName,
  EntityPermissionMap,
  Role,
  RolePermissionsSummary,
} from '@customTypes/members';
import showNotification from '@utils/showNotification';

import {
  useLazyGetRolePermissionsSummaryQuery,
  useUpdateRolePermissionMutation,
} from '@containers/members/store/api';
import useI18n from './useI18n';

/**
 * Hook exposes 3 states to manage project/global role settings pages.
 * Also exposes an inti method and few user action methods.
 *
 * For UI, Hook create a mapping between entities and permissions of a particular role.
 * Also update permissions of a particular role through exposed user action methods.
 *
 * States:-
 * entityPermissionMap -> Map between entities (project/global entities)
 *                        and permissions (read/write/delete/update) of a role
 *
 * currentSelectedRole -> Current user selected role(global/project role)
 *                        (initially it is set to first role from roles list)
 *
 * initialLoading -> Set to true while initial fetching
 */

const useRolePermission = () => {
  const [entityPermissionMap, setEntityPermissionMap] =
    useState<EntityPermissionMap>();
  const [currentSelectedRole, setCurrentSelectedRole] = useState<string>();
  const [initialLoading, setInitialLoading] = useState(false);

  const { t } = useI18n(['members/roles', 'sideNavigation', 'common']);

  const [getRolePermissionSummary] = useLazyGetRolePermissionsSummaryQuery();

  const [updateRolePermission] = useUpdateRolePermissionMutation();

  const updatePermission = ({
    entityName,
    permissionIndex,
    updatedCheck,
  }: {
    entityName: EntityName;
    permissionIndex: number;
    updatedCheck: boolean;
  }) => {
    const permissionMap = new Map(entityPermissionMap);
    const entityPermissionData = permissionMap.get(entityName);

    if (entityPermissionData) {
      permissionMap.set(entityName, {
        ...entityPermissionData,
        permissions: entityPermissionData.permissions.map((isChecked, index) =>
          permissionIndex === index ? updatedCheck : isChecked
        ),
      });
    }
    setEntityPermissionMap(permissionMap);
  };

  const createEntityPermissionMap = async (
    rolePermissions: RolePermissionsSummary[],
    entities: Entities
  ) => {
    const permissionMap: EntityPermissionMap = new Map();

    rolePermissions?.forEach((entityPermission) => {
      permissionMap.set(entityPermission.entityName, {
        id: entityPermission.entityId,
        permissions: [
          !!entityPermission.entityRead,
          !!entityPermission.entityCreate,
          !!entityPermission.entityUpdate,
          !!entityPermission.entityDelete,
        ],
      });
    });

    // Permissions for some project entities may not be created for this role
    // But we need to show all entities in UI
    // So need to fill remaining entities in permissionMap with all permissions as false
    entities?.forEach((entity) => {
      const entityData = permissionMap.get(entity.name);
      if (!entityData) {
        permissionMap.set(entity.name, {
          id: entity.id,
          permissions: [false, false, false, false],
        });
      }
    });

    setEntityPermissionMap(permissionMap);
  };

  const initSelectedRoleAndEntityPermissionMap = async (
    roles: Role[],
    entities: Entities
  ) => {
    if (roles?.length && !currentSelectedRole) {
      setInitialLoading(true);
      try {
        setCurrentSelectedRole(roles[0]?.id);
        const rolePermissionResponse = await getRolePermissionSummary(
          roles[0]?.id
        );
        if (rolePermissionResponse.data) {
          createEntityPermissionMap(rolePermissionResponse.data, entities);
        }
      } catch (error) {
        showNotification({
          message: t('common:somethingWrong'),
          type: NotificationTypes.ERROR,
        });
      }
      setInitialLoading(false);
    }
  };

  const onRoleClick = async (roleId: string, entities: Entities) => {
    try {
      setCurrentSelectedRole(roleId);
      const rolePermissionResponse = await getRolePermissionSummary(roleId);
      if (rolePermissionResponse.data) {
        createEntityPermissionMap(rolePermissionResponse.data, entities);
      }
    } catch (error) {
      showNotification({
        message: t('common:somethingWrong'),
        type: NotificationTypes.ERROR,
      });
    }
  };

  const onToggleCheckBox = async (
    entityName: EntityName,
    permissionIndex: number,
    updatedCheck: boolean
  ) => {
    const entityId = entityPermissionMap?.get(entityName)?.id;
    try {
      if (currentSelectedRole && entityId) {
        const rolePermissionResponse = await updateRolePermission({
          roleId: currentSelectedRole,
          updateRolePermissionsInput: {
            entityId,
            [entityPermissions[permissionIndex]]: updatedCheck,
          },
        }).unwrap();
        if (rolePermissionResponse?.length) {
          updatePermission({ entityName, permissionIndex, updatedCheck });
        }
      }
    } catch (error) {
      showNotification({
        message: t('permissionUpdateFailed'),
        type: NotificationTypes.ERROR,
      });
    }
  };

  const updateSelectedRole = (roleId: string) => {
    setCurrentSelectedRole(roleId);
  };

  return {
    entityPermissionMap,
    currentSelectedRole,
    initialLoading,
    onRoleClick,
    onToggleCheckBox,
    updateSelectedRole,
    initSelectedRoleAndEntityPermissionMap,
  };
};

export default useRolePermission;
