import { forwardRef, Ref, ChangeEventHandler } from 'react';
import { twMerge } from 'tailwind-merge';

export interface DefaultInputProps {
  id: string;
  label?: string;
  placeholder?: string;
  labelClassName?: string;
  inputClassName?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const DefaultInput = forwardRef(
  (
    {
      id,
      label,
      placeholder = '',
      labelClassName = '',
      inputClassName = '',
      onChange,
      ...props
    }: DefaultInputProps,
    ref: Ref<HTMLInputElement>
  ) => (
    <>
      {!!label && (
        <label
          htmlFor={id}
          className={twMerge(
            'inline-block mb-1.5 text-sm text-blue-magenta-faded',
            labelClassName
          )}
        >
          {label}
        </label>
      )}
      <input
        id={id}
        ref={ref}
        placeholder={placeholder}
        onChange={onChange}
        className={twMerge(
          'p-3 w-full rounded border border-light-lavender',
          inputClassName
        )}
        {...props}
      />
    </>
  )
);

DefaultInput.displayName = 'DefaultInput';

export default DefaultInput;
