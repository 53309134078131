import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

export const options = {
  maintainAspectRatio: false,
  maxBarThickness: 50,
  skipNull: true,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
};

const BarChart = ({
  data,
  customOptions,
}: {
  data: any;
  customOptions?: any;
}) => <Bar options={customOptions ?? options} data={data} />;

export default BarChart;
