import AuthenticationFailed from '@containers/auth-failed/AuthenticationFailed';
import { lazyWithRetry } from '@utils/navigation';
import { Navigate, Route, Routes } from 'react-router-dom';

import RoutePaths from './RoutesPath';

const LoginPage = lazyWithRetry(() => import('@pages/LoginPage'));

function PublicLayout() {
  return (
    <Routes>
      <Route path={RoutePaths.LOGIN} element={<LoginPage />} />
      <Route path={RoutePaths.AUTH_FAILED} element={<AuthenticationFailed />} />
      <Route
        path={RoutePaths.ALL}
        element={<Navigate to={RoutePaths.LOGIN} replace />}
      />
    </Routes>
  );
}

export default PublicLayout;
