import { gql } from 'graphql-request';

export const getAllGlobalRolesQuery = gql`
  query getAllGlobalRoles {
    getAllGlobalRoles {
      id
      name
      systemGenerated
    }
  }
`;

export const getGlobalEntitiesQuery = gql`
  query getGlobalEntities {
    getGlobalEntities {
      id
      name
    }
  }
`;

export const getAllProjectRolesQuery = gql`
  query getAllProjectRoles {
    getAllProjectRoles {
      id
      name
      systemGenerated
    }
  }
`;

export const getProjectEntitiesQuery = gql`
  query getProjectEntities {
    getProjectEntities {
      id
      name
    }
  }
`;

export const createProjectRoleMutation = gql`
  mutation createProjectRole($createRoleInput: CreateRoleInput!) {
    createProjectRole(createRoleInput: $createRoleInput) {
      id
    }
  }
`;

export const createGlobalRoleMutation = gql`
  mutation createGlobalRole($createRoleInput: CreateRoleInput!) {
    createGlobalRole(createRoleInput: $createRoleInput) {
      id
    }
  }
`;

export const getRolePermissionsSummaryQuery = gql`
  query getRolePermissionsSummary($roleId: UUID!) {
    getRolePermissionsSummary(roleId: $roleId) {
      roleId
      roleName
      entityId
      entityName
      entityRead
      entityCreate
      entityDelete
      entityUpdate
    }
  }
`;

export const updateRolePermissionsMutation = gql`
  mutation updateRolePermissions(
    $roleId: UUID!
    $updateRolePermissionsInput: [UpdateRolePermissionsInput]!
  ) {
    updateRolePermissions(
      roleId: $roleId
      updateRolePermissionsInput: $updateRolePermissionsInput
    ) {
      roleId
      entityId
      entityRead
      entityCreate
      entityDelete
      entityUpdate
    }
  }
`;

export const deleteRoleMutation = gql`
  mutation deleteRole($id: UUID!) {
    deleteRole(id: $id)
  }
`;
