import { useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import Link from '@ckeditor/ckeditor5-link/src/link';
import ListProperties from '@ckeditor/ckeditor5-list/src/listproperties';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

import { CkEditorToolbar } from '@constants/common';
import { FileInput } from '@containers/test-suite/types';

import Attachments from '../attachments/Attachments';

class Upload extends Plugin {
  // eslint-disable-next-line class-methods-use-this
  init() {
    // eslint-disable-next-line prefer-destructuring
    const editor = this.editor as any;
    editor.ui.componentFactory.add('fileupload', () => {
      const button = new ButtonView();
      button.set({
        label: 'Upload',
        withText: true,
      });
      button.on('execute', () => {
        // eslint-disable-next-line no-underscore-dangle
        editor.config._config.onFileUpload();
      });
      return button;
    });
  }
}

interface CustomCKEditorProps {
  data: string;
  placeholder?: string;
  handleChange?: (htmlString: string) => void;
  uploadAttachmentClick?: (
    file: File | undefined,
    onUploadComplete: () => void
  ) => void;
  removeAttachment?: (index: number) => void;
  attachments?: FileInput[] | null;
  readOnly?: boolean;
  showToolbar?: boolean;
  onAttachmentDownload?: (key: string, fileName: string) => void;
  toolbar?: CkEditorToolbar[];
}

const CustomCKEditorProps = ({
  data,
  placeholder,
  handleChange,
  uploadAttachmentClick,
  removeAttachment,
  attachments,
  readOnly = false,
  showToolbar = true,
  onAttachmentDownload,
  toolbar,
}: CustomCKEditorProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const editorConfiguration = {
    plugins: [
      Essentials,
      Bold,
      Italic,
      Paragraph,
      Link,
      ListProperties,
      Underline,
      Table,
      TableToolbar,
      Upload,
    ],
    toolbar: showToolbar
      ? toolbar ?? [
          CkEditorToolbar.BOLD,
          CkEditorToolbar.ITALIC,
          CkEditorToolbar.UNDERLINE,
          CkEditorToolbar.LINK,
          '|',
          CkEditorToolbar.BULLETED_LIST,
          CkEditorToolbar.NUMBERED_LIST,
          '|',
          CkEditorToolbar.INSERT_TABLE,
          CkEditorToolbar.FILE_UPLOAD,
        ]
      : [],
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
    },
    onFileUpload: () => fileInputRef.current?.click(),
    placeholder: placeholder || '',
    isReadOnly: readOnly,
  };

  return (
    <div
      className={twMerge(
        readOnly ? 'disabled_editor' : '',
        !onAttachmentDownload && 'border-b-[1.5px] border-gray-300'
      )}
    >
      <CKEditor
        editor={ClassicEditor}
        config={editorConfiguration}
        data={data ?? ''}
        onReady={(editor: any) => {
          if (readOnly) {
            editor.enableReadOnlyMode('disable');
          }
        }}
        onChange={(event, editor) => {
          handleChange?.(editor.getData());
        }}
      />

      {onAttachmentDownload ? (
        <Attachments
          showCloseButton={!readOnly}
          attachments={attachments}
          onAttachmentDownload={onAttachmentDownload}
          removeAttachment={removeAttachment}
          uploadAttachmentClick={uploadAttachmentClick}
          fileInputRef={fileInputRef}
          emptyStateStyle='h-0'
          itemsContainerStyle='px-1 pb-1'
          containerStyle='mt-[-1px] w-full border-[1.5px] border-gray-300 border-t-transparent'
        />
      ) : null}
    </div>
  );
};

export default CustomCKEditorProps;
