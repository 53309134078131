import { combineReducers } from 'redux';

import { createSlice } from '@reduxjs/toolkit';
import notifications from '@containers/notification/reducer';
import testSuite from '@containers/test-suite/store/reducer';
import { ColumnsSortPayload, CommonState } from '@customTypes/common';

// eslint-disable-next-line import/no-cycle
import store from './store';

const initialState: CommonState = {
  columnSorts: {},
};

const commonReducerSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    updateCurrentSorting: (
      state,
      { payload }: { payload: ColumnsSortPayload }
    ) => {
      state.columnSorts[payload.pageId] = payload.sortData;
    },
  },
});

const common = commonReducerSlice.reducer;
export const { updateCurrentSorting } = commonReducerSlice.actions;

const rootReducer = combineReducers({
  common,
  notifications,
  testSuite,
});

export type RootState = ReturnType<typeof store.getState>;

export default rootReducer;
