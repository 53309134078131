import { useDropzone, FileRejection, DropEvent } from 'react-dropzone';
import { twMerge } from 'tailwind-merge';

import { useI18n } from '@hooks';
import { AlertIcon, UploadedFileIcon, UploadIcon } from '@icons';
import { UploadStatus } from '@constants/importTestCase';
import { isUploadError } from '@utils/importTestCase';

interface FileUploadProps {
  onDrop: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  className?: string;
  multiple?: boolean;
  acceptedFiles?: { [key: string]: string[] };
  uploadStatus: UploadStatus;
  fileName?: string;
}

const FileUpload = (props: FileUploadProps) => {
  const { onDrop, multiple, className, acceptedFiles, fileName, uploadStatus } =
    props;

  const { t } = useI18n(['testcases/importTestcase']);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    accept: acceptedFiles,
  });

  const getIcon = () => {
    switch (uploadStatus) {
      case UploadStatus.UPLOADED:
        return <UploadedFileIcon color='#7257F0' />;
      case UploadStatus.FILE_EMPTY:
      case UploadStatus.NO_VALID_TEST_CASES:
        return <UploadedFileIcon color='#D74760' />;
      default:
        return <UploadIcon />;
    }
  };

  const isError = isUploadError(uploadStatus);

  return (
    <div
      {...getRootProps()}
      className={twMerge(
        'flex flex-col  justify-center items-center w-[600px] h-[174px] text-center bg-[#F6F6F9] rounded-md border-[1px] border-dashed border-accent-primary cursor-pointer',
        className
      )}
    >
      <input {...getInputProps()} />
      {getIcon()}
      {fileName && (
        <p
          className={`mt-1 mb-2 text-xs ${
            isError ? 'text-[#D74760]' : 'text-[#605E5E]'
          }`}
        >
          {fileName}
        </p>
      )}
      {isError && (
        <p className='flex my-2 text-xs text-[#D74760]'>
          <AlertIcon className='mr-1 w-[17px] h-[15px] text-[#D22D4A]' />
          <span className='mr-[2px] font-bold'>{t('error')}: </span>
          {t(`uploadError.${uploadStatus}`)}
        </p>
      )}
      <p className='mt-2 text-xs text-[#605E5E]'>
        <span className='text-[#7257F0]'>
          {t('dragAndDropAreaPlaceholderTextPart1')}
        </span>
        {t('dragAndDropAreaPlaceholderTextPart2')}
      </p>
    </div>
  );
};

export default FileUpload;
