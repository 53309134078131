import { twMerge } from 'tailwind-merge';

interface SpinnerProps {
  className?: string;
}

function Spinner({ className }: SpinnerProps) {
  return (
    <div
      className={twMerge(
        'w-5 h-5 mx-auto rounded-full border-[3px] border-white border-t-accent-primary spinner-animation',
        className
      )}
    />
  );
}

function FullPageSpinner({
  useScreenDimensions = false,
}: {
  useScreenDimensions?: boolean;
}) {
  return (
    <div
      className={twMerge(
        'flex justify-center items-center',
        useScreenDimensions ? 'h-screen w-screen' : 'h-full w-full'
      )}
    >
      <Spinner className='w-10 h-10' />
    </div>
  );
}

export default Spinner;

export { FullPageSpinner };
