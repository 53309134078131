import { TimeUnit } from '@constants/test-suite/addTestCase';
import { CreateTestCaseStepInput } from '@containers/test-suite/types';

export enum ImportTestcaseFileItemFieldsEnum {
  SectionHierarchy = 'Section Hierarchy',
  Title = 'Title*',
  Feature = 'TC Feature',
  Type = 'TC Type',
  Tag = 'TC Tag',
  Priority = 'TC Priority',
  Template = 'TC Template',
  Automation = 'TC Automation',
  Effort = 'TC Effort(Min)',
  Precondition = 'TC Pre-Conditions',
  Steps = 'TC Steps',
  ExpectedResults = 'TC Expected Results',
}

export enum ImportedTesCaseInternalError {
  EMPTY_NAME = 'EMPTY_NAME',
  NAME_LENGTH_EXCEEDED = 'NAME_LENGTH_EXCEEDED',
  TAG_LENGTH_EXCEEDED = 'TAG_LENGTH_EXCEEDED',
  AUTOMATION_LENGTH_EXCEEDED = 'AUTOMATION_LENGTH_EXCEEDED',
  FOLDER_NAME_LENGTH_EXCEEDED = 'FOLDER_NAME_LENGTH_EXCEEDED',
  FOLDER_NAME_AS_PROJECT_NAME = 'FOLDER_NAME_AS_PROJECT_NAME',
  DUPLICATE_ENTRY_IN_FILE = 'DUPLICATE_ENTRY_IN_FILE',
}

export enum ImportTestcaseBackendFieldsEnum {
  Name = 'name',
  FolderHierarchy = 'folderHierarchy',
  Feature = 'feature',
  Type = 'type',
  Tag = 'tag',
  Template = 'template',
  Automation = 'automation',
  Precondition = 'precondition',
  Priority = 'priority',
  EstimatedTime = 'estimatedTime',
  EstimatedTimeUnit = 'estimatedTimeUnit',
  Steps = 'testcaseSteps',
}

export type TestCaseStepForTestCaseImport = Partial<
  Pick<CreateTestCaseStepInput, 'step' | 'expected' | 'position'>
>;

export interface CreateTestcasesInput {
  [ImportTestcaseBackendFieldsEnum.Name]: string;
  [ImportTestcaseBackendFieldsEnum.FolderHierarchy]?: string[];
  [ImportTestcaseBackendFieldsEnum.Feature]?: string;
  [ImportTestcaseBackendFieldsEnum.Type]?: string;
  [ImportTestcaseBackendFieldsEnum.Tag]?: string;
  [ImportTestcaseBackendFieldsEnum.Template]?: string;
  [ImportTestcaseBackendFieldsEnum.Automation]?: string;
  [ImportTestcaseBackendFieldsEnum.Precondition]?: string;
  [ImportTestcaseBackendFieldsEnum.Priority]?: string;
  [ImportTestcaseBackendFieldsEnum.EstimatedTime]?: number;
  [ImportTestcaseBackendFieldsEnum.EstimatedTimeUnit]?: TimeUnit;
  [ImportTestcaseBackendFieldsEnum.Steps]?: TestCaseStepForTestCaseImport[];
}

export enum ImportedTestCaseWarning {
  FOLDER_DEPTH_EXCEEDED = 'FOLDER_DEPTH_EXCEEDED',
}

export enum CreateTestcasesErrorCode {
  ENTITY_ALREADY_EXISTS = 'ENTITY_ALREADY_EXISTS',
  BATCH_FAILED = 'BATCH_FAILED',
}

export type ImportedTestCase = CreateTestcasesInput & {
  internalErrors?: ImportedTesCaseInternalError[] | null;
  errorCode?: CreateTestcasesErrorCode;
  internalWarnings?: ImportedTestCaseWarning[] | null;
  checked: boolean;
  id: string;
};

export interface CreateTestcasesInputType {
  createTestcasesInputs: CreateTestcasesInput[];
  projectId: string;
  folderId: string;
  importJobId: string;
}

export interface CreatedTestcase {
  id: string;
  name: string;
}

export type FailedTestcase = CreateTestcasesInput & {
  errorCode: CreateTestcasesErrorCode;
};

export interface CreateTestcases {
  createTestcases: CreatedTestcase[];
  failedTestcases: FailedTestcase[];
}

export interface CreateTestcasesResponse {
  createTestcases: CreateTestcases;
}

export enum ImportTestCaseFileType {
  CSV = 'CSV',
  XLS = 'XLS',
  XLSX = 'XLSX',
}

export enum ImportTestcaseTableColumn {
  NAME = 'name',
  ACTION = 'action',
  SELECT = 'select',
}

export enum ErrorField {
  ErrorReason = 'Error reason',
}

export type SeparatedTestCases = {
  validTestCases: ImportedTestCase[];
  failedTestCases: ImportedTestCase[];
  testCasesWithWarning: ImportedTestCase[];
};

export enum ImportErrorDialogMessageType {
  ERROR,
  WARNING,
}

export type ImportErrorDialogMessage = {
  message: string;
  type: ImportErrorDialogMessageType;
};
