export enum ButtonTypes {
  FILLED = 'FILLED',
  OUTLINED = 'OUTLINED',
  DEFAULT = 'DEFAULT',
  NO_BORDER = 'NO_BORDER',
}

export enum DropdownActions {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export enum NotificationTypes {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum TooltipDirections {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum TextAreaElements {
  PARAGRAPH = 'paragraph',
  BULLET_LIST = 'bullet',
}

export const PAGE_SIZE = 10;

export enum QueryParamTypes {
  PAGE = 'page',
}

export enum CheckBoxStates {
  CHECKED = 'CHECKED',
  UNCHECKED = 'UNCHECKED',
  INDETERMINATE = 'INDETERMINATE',
}

export enum TestcaseStatus {
  UNTESTED = 'UNTESTED',
  PASSED = 'PASSED',
  FAILED = 'FAILED',
  BLOCKED = 'BLOCKED',
  RETEST = 'RETEST',
}

export const TestCaseStatusMapping = new Map([
  [
    TestcaseStatus.UNTESTED,
    {
      label: 'testCaseStatus.untested',
      bgColor: 'bg-light-purple',
      textColor: 'text-dark-gray',
      caretColor: 'stroke-dark-gray',
    },
  ],
  [
    TestcaseStatus.PASSED,
    {
      label: 'testCaseStatus.passed',
      bgColor: 'bg-test-case-passed',
      textColor: 'text-white',
      caretColor: 'stroke-white',
    },
  ],
  [
    TestcaseStatus.FAILED,
    {
      label: 'testCaseStatus.failed',
      bgColor: 'bg-test-case-failed',
      textColor: 'text-white',
      caretColor: 'stroke-white',
    },
  ],
  [
    TestcaseStatus.BLOCKED,
    {
      label: 'testCaseStatus.blocked',
      bgColor: 'bg-test-case-blocked',
      textColor: 'text-white',
      caretColor: 'stroke-white',
    },
  ],
  [
    TestcaseStatus.RETEST,
    {
      label: 'testCaseStatus.retest',
      bgColor: 'bg-test-case-retest',
      textColor: 'text-dark-gray',
      caretColor: 'stroke-dark-gray',
    },
  ],
]);

export enum StatusCardProgressBar {
  TEST_RUN_PROGRESS_BAR = 'TEST_RUN_PROGRESS_BAR',
  MILESTONE_PROGRESS_BAR = 'MILESTONE_PROGRESS_BAR',
}

export enum StatusCardType {
  MILESTONE = 'MILESTONE',
  TESTRUN = 'TESTRUN',
}
