import { $getRoot, EditorState, LexicalEditor } from 'lexical';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { ListNode, ListItemNode } from '@lexical/list';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { twMerge } from 'tailwind-merge';
import { $generateHtmlFromNodes } from '@lexical/html';
import { ErrorOption } from 'react-hook-form';
import { useCallback, useRef } from 'react';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import theme from './theme';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import InitialStatePlugin from './plugins/InitialStatePlugin';
import GetEditorStatePlugin from './plugins/GetEditorStatePlugin';

type TextEditorProps = {
  className?: string;
  initialState?: string;
  handleChange?: (htmlString: string) => void;
  handleError?: (error: ErrorOption) => void;
  placeholder?: string;
  errorMessage?: string;
  editorId?: string;
  readOnly?: boolean;
  showToolbar?: boolean;
};

const TextEditor = ({
  className,
  initialState,
  handleChange = () => null,
  handleError = () => null,
  placeholder = '',
  errorMessage = '',
  editorId = '',
  readOnly = false,
  showToolbar = true,
}: TextEditorProps) => {
  const onError = (error: ErrorOption) => {
    handleError(error);
  };
  const editorRef = useRef<any>(null);

  const initialConfig = {
    namespace: 'MyEditor',
    theme,
    nodes: [ListNode, ListItemNode, AutoLinkNode, LinkNode],
    onError,
    readOnly,
  };

  const onChange = (_editorState: EditorState, newEditor: LexicalEditor) => {
    newEditor.update(() => {
      const htmlString = $generateHtmlFromNodes(newEditor, null);
      handleChange(htmlString);
    });
  };

  const setEditorRef = useCallback((editor: LexicalEditor) => {
    editorRef.current = editor;
  }, []);

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <div
        className={twMerge(
          `relative min-h-[150px] rounded-[4px] border ${
            errorMessage ? `border-red-primary` : `border-light-lavender`
          }`,
          className
        )}
        id={editorId}
      >
        <InitialStatePlugin initialState={initialState} />
        {showToolbar && <ToolbarPlugin editorId={editorId} />}
        <div className='relative'>
          <RichTextPlugin
            contentEditable={
              <ContentEditable className='relative py-[15px] px-[10px] text-sm text-light-slate-grey outline-none  caret-[#9999]' />
            }
            placeholder={
              <div
                role='presentation'
                onClick={() => {
                  const editor = editorRef?.current;
                  if (editor) {
                    editor.update(() => {
                      $getRoot().selectStart();
                    });
                  }
                }}
                className='absolute top-[15px] left-[10px] text-sm text-[#9999]  truncate cursor-auto'
              >
                {placeholder}
              </div>
            }
          />
          <LinkPlugin />
          <AutoLinkPlugin />
          <ListPlugin />
          <OnChangePlugin onChange={onChange} />
          <GetEditorStatePlugin setEditor={setEditorRef} />
        </div>
      </div>
      {errorMessage && (
        <div className='mt-[4px] text-xs text-red-primary'>{errorMessage}</div>
      )}
    </LexicalComposer>
  );
};

TextEditor.displayName = 'TextEditor';
export default TextEditor;
