import { useState } from 'react';

import { NotificationTypes } from '@constants/components';
import { useLazyGetTestcaseAttachmentDownloadUrlQuery } from '@containers/test-suite/store/api';
import { downloadFile } from '@utils/common';
import showNotification from '@utils/showNotification';
import useI18n from './useI18n';

const useTestCaseAttachmentDownload = () => {
  const { t } = useI18n(['testSuite/addTestCase']);

  const [isDownloading, setIsDownloading] = useState(false);

  const [getTestcaseAttachmentDownloadUrl] =
    useLazyGetTestcaseAttachmentDownloadUrlQuery();

  const downloadAttachment = async (key: string, fileName: string) => {
    const onFail = () => {
      showNotification({
        message: t('downloadFailed'),
        type: NotificationTypes.ERROR,
      });
    };

    try {
      setIsDownloading(true);
      const downloadUrl = await getTestcaseAttachmentDownloadUrl({
        contentType: '',
        key,
      });

      setIsDownloading(false);

      if (downloadUrl.data?.getTestcaseAttachmentDownloadUrl.url) {
        return downloadFile(
          downloadUrl.data?.getTestcaseAttachmentDownloadUrl.url,
          fileName,
          onFail
        );
      }

      return onFail();
    } catch (error) {
      setIsDownloading(false);
      return onFail();
    }
  };

  return {
    downloadAttachment,
    isDownloading,
  };
};

export default useTestCaseAttachmentDownload;
