import { SessionStorageKeys } from '@constants/common';
import { tryParse } from './common';

const getLocalStorageItem = (key: string) => {
  const item = localStorage.getItem(key);
  return item ? tryParse(item) : item;
};

const setLocalStorageItem = (key: string, value: unknown) => {
  localStorage.setItem(
    key,
    typeof value === 'object' ? JSON.stringify(value) : `${value}`
  );
};

const getSessionStorageItem = (key: SessionStorageKeys) => {
  const item = sessionStorage.getItem(key);
  return item ? tryParse(item) : item;
};

const setSessionStorageItem = (key: SessionStorageKeys, value: unknown) => {
  sessionStorage.setItem(
    key,
    typeof value === 'object' ? JSON.stringify(value) : `${value}`
  );
};

export {
  getLocalStorageItem,
  setLocalStorageItem,
  getSessionStorageItem,
  setSessionStorageItem,
};
