import { useEffect, FC } from 'react';

import { useAppDispatch } from '@store/store';
import { NotificationTypes } from '@constants/components';
import { FailIcon, SuccessIcon } from '@icons';

import { NotifierProps } from './types';

const Notifier: FC<NotifierProps> = (props) => {
  const { id, notification, hideNotifier } = props;

  const { message, type } = notification;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      setTimeout(() => {
        dispatch(hideNotifier(id));
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div
      className={`flex relative flex-row items-center p-4 mt-2 min-w-[200px] text-[12px] sm:text-sm text-white ${
        type === NotificationTypes.ERROR ? `bg-red-primary` : `bg-[#54A754]`
      } rounded-md`}
    >
      {type === NotificationTypes.SUCCESS ? (
        <SuccessIcon className='w-[30px] h-[30px]' />
      ) : (
        <FailIcon className='w-[30px] h-[30px]' />
      )}
      <div className='flex flex-wrap mx-4 min-w-[200px] truncate break-all'>
        {message}
      </div>
    </div>
  );
};

export default Notifier;
