import { forwardRef, Ref, ChangeEventHandler } from 'react';
import { twMerge } from 'tailwind-merge';

import { DefaultInputProps } from './DefaultInput';

interface DefaultTextareaProps
  extends Omit<DefaultInputProps, 'inputClassName' | 'onChange'> {
  textareaClassName?: string;
  rows?: number;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
}

const DefaultTextarea = forwardRef(
  (
    {
      id,
      label,
      labelClassName,
      placeholder,
      textareaClassName,
      rows = 3,
      onChange,
      ...props
    }: DefaultTextareaProps,
    ref: Ref<HTMLTextAreaElement>
  ) => (
    <>
      {!!label && (
        <label
          htmlFor={id}
          className={twMerge(
            'inline-block mb-1.5 text-sm text-blue-magenta-faded',
            labelClassName
          )}
        >
          {label}
        </label>
      )}
      <textarea
        id={id}
        ref={ref}
        placeholder={placeholder}
        onChange={onChange}
        className={twMerge(
          'p-3 w-full rounded border border-light-lavender',
          textareaClassName
        )}
        rows={rows}
        {...props}
      />
    </>
  )
);

DefaultTextarea.displayName = 'DefaultTextarea';

export default DefaultTextarea;
