/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, RefObject } from 'react';

type useClickOutsideProps = {
  ref: RefObject<HTMLElement>;
  handler: () => void;
};

const useClickOutside = (props: useClickOutsideProps) => {
  const { ref, handler } = props;
  const listener = (event: any) => {
    if (!ref.current || ref.current.contains(event.target)) {
      return;
    }
    handler();
  };
  useEffect(() => {
    document.addEventListener('click', listener, true);

    return () => {
      document.removeEventListener('click', listener, true);
    };
  });
};

export default useClickOutside;
