import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import { $getRoot, $setSelection } from 'lexical';
import { $generateNodesFromDOM } from '@lexical/html';

const InitialStatePlugin = ({ initialState }: { initialState?: string }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (initialState) {
      editor.update(() => {
        const parser = new DOMParser();
        const dom = parser.parseFromString(initialState, 'text/html');
        // Once you have the DOM instance it's easy to generate LexicalNodes.
        const nodes = $generateNodesFromDOM(editor, dom);
        // Select the root
        const root = $getRoot();
        root.clear();

        nodes.map((node) => root.append(node));
        $setSelection(null);
      });
    } else {
      editor.update(() => {
        $setSelection(null);
      });
    }
    return () => {
      editor.update(() => {
        const root = $getRoot();
        const children = root.getChildren();
        children.map((child) => child.remove());
      });
    };
  }, [editor, initialState]);
  return null;
};

export default InitialStatePlugin;
