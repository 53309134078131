import { useSelector } from 'react-redux';

import { Notifier } from '@components';
import { RootState } from '@store/reducer';
import { hideNotifier } from './reducer';

const Notification = () => {
  const { notifications } = useSelector(
    (state: RootState) => state.rootReducer.notifications
  );
  const messages = [...notifications];

  return (
    <div className='overflow-y-auto fixed bottom-4 left-[50%] z-30 max-h-screen translate-x-[-50%]'>
      {notifications.length > 0 &&
        messages.map((message, index) => (
          <Notifier
            key={message.id}
            id={message.id}
            notification={{ ...message, position: index }}
            hideNotifier={hideNotifier}
          />
        ))}
    </div>
  );
};

export default Notification;
