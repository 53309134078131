import { useTranslation } from 'react-i18next';

const useI18n = (nameSpaces: Array<string>) => {
  const { t, i18n } = useTranslation(nameSpaces);
  return {
    t,
    i18n,
  };
};

export default useI18n;
