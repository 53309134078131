import { ButtonTypes } from '@constants/components';
import { useI18n } from '@hooks';
import { Title, Description } from '@radix-ui/react-dialog';
import { twMerge } from 'tailwind-merge';
import { ReactNode } from 'react';
import { BaseDialog } from './Dialog';
import Button from '../button/Button';

type AlertDialogProps = {
  open: boolean;
  title: string;
  message: string | JSX.Element;
  solidButtonLabel?: string;
  transparentButtonLabel?: string;
  showLoaderInConfirmButton?: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
  children?: ReactNode;
  contentClassName?: string;
  descriptionStyle?: string;
  isSolidDisabled?: boolean;
  isTransparentDisabled?: boolean;
};
const AlertDialog = (props: AlertDialogProps) => {
  const {
    open,
    title,
    message,
    handleConfirm,
    handleCancel,
    showLoaderInConfirmButton = false,
    children,
    contentClassName,
    descriptionStyle,
    solidButtonLabel,
    transparentButtonLabel,
    isSolidDisabled,
    isTransparentDisabled,
  } = props;
  const { t } = useI18n(['common']);

  return (
    <BaseDialog
      open={open}
      onClose={handleCancel}
      contentClassName={twMerge(
        'bg-light-greyish-blue max-w-[440px]',
        contentClassName
      )}
    >
      <div className='py-6 px-8'>
        <Title className='mb-1.5 text-base font-medium text-black-primary'>
          {title}
        </Title>
        <Description
          className={twMerge(
            'py-1 text-blue-magenta-faded break-normal',
            descriptionStyle
          )}
        >
          {message}
        </Description>
        {children}

        <div className='flex mt-4'>
          <Button
            label={solidButtonLabel ?? t('confirm')}
            handleClick={handleConfirm}
            type={ButtonTypes.FILLED}
            disabled={isSolidDisabled}
            className='py-2.5 px-6 mr-4 text-sm'
            showLoader={showLoaderInConfirmButton}
          />
          <Button
            label={transparentButtonLabel ?? t('cancel')}
            handleClick={handleCancel}
            type={ButtonTypes.OUTLINED}
            disabled={isTransparentDisabled}
            className='py-2.5 px-6 text-sm'
          />
        </div>
      </div>
    </BaseDialog>
  );
};

export default AlertDialog;
