import { gql } from 'graphql-request';

export const getSignInUrlQuery = gql`
  query getSocialSignInUrl(
    $successRedirectUri: String!
    $failedRedirectUri: String
  ) {
    getSocialSigninUrl(
      socialSigninProvider: GOOGLE
      successRedirectUri: $successRedirectUri
      failedRedirectUri: $failedRedirectUri
    ) {
      url
    }
  }
`;

export const getUserQuery = gql`
  query getUser {
    user {
      id
      name
      email
      profilePicture
      userTenants {
        role {
          id
          name
        }
        tenant {
          id
          name
        }
      }
    }
  }
`;

export const logoutMutation = gql`
  mutation logout {
    logout
  }
`;
