import { gql } from 'graphql-request';

export const globalSearchQuery = gql`
  query globalSearch($searchTerm: String!) {
    globalSearch(searchTerm: $searchTerm) {
      projectId
      projectName
      name
      entityName
      entityId
    }
  }
`;

export const getTMSPropertiesQuery = gql`
  query getTMSProperties {
    getTMSProperties {
      testcaseImportTemplate {
        CsvFileUrl
        XlsxFileUrl
        XlsFileUrl
      }
    }
  }
`;

export const getGlobalPermissionsForUserQuery = gql`
  query getGlobalPermissionsForUser {
    getGlobalPermissionsForUser {
      entityName
      entityRead
      entityCreate
      entityUpdate
      entityDelete
    }
  }
`;
