import { useCallback, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { KeyboardEvent } from '@constants/common';
import { TextboxWithCardsProps } from '@customTypes/common';
import { useI18n } from '@hooks';

import StringCards from '../common/StringCards';

const TextboxWithStringCards = ({
  label,
  placeholder,
  inputClassName,
  labelClassName,
  regexValidator,
  errorMessage = '',
  required = false,
  onInputStringsChange,
  inputString = '',
  disabled = false,
  unique = true,
}: TextboxWithCardsProps) => {
  const { t } = useI18n(['common']);

  const [selectedStrings, setSelectedStrings] = useState<string[]>([]);
  const [stringValue, setStringValue] = useState(inputString);
  const [errorString, setErrorString] = useState('');

  const getValidAndInvalidStrings = useCallback(() => {
    let validStrings: string[] = [];
    const invalidStrings: string[] = [];
    const strings = stringValue.split(/[, ]+/);
    if (regexValidator) {
      strings.forEach((str: string) => {
        if (str.trim().length) {
          const arr = regexValidator.test(str) ? validStrings : invalidStrings;
          arr.push(str);
        }
      });
    } else {
      validStrings = strings;
    }
    return { validStrings, invalidStrings };
  }, [stringValue, regexValidator]);

  const processStrings = useCallback(() => {
    if (required && !stringValue && selectedStrings.length === 0) {
      setErrorString(t('errors.required'));
      onInputStringsChange([]);
    } else if (stringValue) {
      const { validStrings, invalidStrings } = getValidAndInvalidStrings();
      let mergedStrings = [...selectedStrings, ...validStrings];
      mergedStrings = unique
        ? Array.from(new Set(mergedStrings))
        : mergedStrings;
      setSelectedStrings(mergedStrings);
      setStringValue(invalidStrings.join(','));
      if (invalidStrings.length) {
        setErrorString(errorMessage);
        onInputStringsChange([]);
      } else {
        setErrorString('');
        onInputStringsChange(mergedStrings);
      }
    } else {
      setErrorString('');
      onInputStringsChange(selectedStrings);
    }
  }, [
    required,
    stringValue,
    selectedStrings,
    errorMessage,
    getValidAndInvalidStrings,
    onInputStringsChange,
    unique,
    t,
  ]);

  const onCardCloseClick = (id: string) => {
    const filteredStrings = selectedStrings.filter((str) => str !== id);
    setSelectedStrings(filteredStrings);
    if (errorString) {
      onInputStringsChange([]);
    } else {
      onInputStringsChange(filteredStrings);
    }
  };

  return (
    <div>
      {label && (
        <label
          htmlFor={label}
          className={twMerge(
            'inline-block mb-1.5 text-sm text-blue-magenta-faded',
            labelClassName
          )}
        >
          {label}
        </label>
      )}
      <input
        id={label}
        placeholder={placeholder}
        value={stringValue}
        onBlur={(event) => {
          event.stopPropagation();
          processStrings();
        }}
        onKeyDown={(event) => {
          event.stopPropagation();
          if (event.key === KeyboardEvent.ENTER) {
            processStrings();
          }
        }}
        onChange={(event) => {
          event.stopPropagation();
          setStringValue(event.target.value?.toLowerCase());
        }}
        className={twMerge(
          'p-3 w-full rounded border border-light-lavender disabled:bg-[#EBEDED] disabled:text-[#A3A5B0]',
          inputClassName
        )}
        disabled={disabled}
      />
      {errorString && (
        <p className='mb-2 text-xs text-red-primary'>{errorString}</p>
      )}

      <StringCards
        selectedItems={selectedStrings.map((string) => ({
          id: string,
          title: string,
        }))}
        onCardCloseClick={onCardCloseClick}
      />
    </div>
  );
};

export default TextboxWithStringCards;
