/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes';
import { useAppDispatch } from '@store/store';
import {
  PAGE_SIZE,
  NotificationTypes,
  QueryParamTypes,
} from '@constants/components';
import showNotification from '@utils/showNotification';
import { RootState, updateCurrentSorting } from '@store/reducer';
import { SortData } from '@customTypes/common';

import useI18n from './useI18n';

const calculateMaxPages = (total: number, size: number) =>
  Math.ceil(total / size);

export interface IListQueryResponse {
  content: any[];
  totalPages: number;
  totalElements: number;
  pageable: {
    pageNumber: number;
  };
  last: boolean;
}

const useFetchPaginationData = (
  useGetDataListQuery: UseQuery<any>,
  { size = PAGE_SIZE, ...queryParameters },
  includePaginationInput = false,
  columnSortFieldMap: any = {},
  pageId = ''
) => {
  const dispatch = useAppDispatch();

  const { t } = useI18n(['common']);
  const [queryParams, setQueryParams] = useSearchParams();

  const { columnSorts } = useSelector(
    (state: RootState) => state.rootReducer.common
  );

  const offset = queryParams.get(QueryParamTypes.PAGE);
  const localPage = offset ? parseInt(offset, 10) : 0;

  const sortData: SortData = columnSorts[pageId] || ({} as SortData);

  const { data, refetch, error, isLoading, isFetching } = useGetDataListQuery({
    page: localPage,
    size,
    ...queryParameters,
    ...(includePaginationInput && {
      paginationInput: {
        size,
        page: localPage,
      },
    }),
    ...(sortData.field
      ? {
          sortBy: columnSortFieldMap[sortData.field],
          sortDirection: sortData.sortOrder,
        }
      : {}),
  });
  const {
    content = [],
    totalElements = 0,
    last = true,
  } = (data as IListQueryResponse) || {};

  useEffect(() => {
    if (error) {
      showNotification({
        message:
          (error as ErrorResponse)?.message ?? t('common:somethingWrong'),
        type: NotificationTypes.ERROR,
      });
    }
  }, [dispatch, error, t]);

  const maxPages = useMemo<number>(
    () => calculateMaxPages(totalElements, PAGE_SIZE),
    [totalElements]
  );

  const refresh = useCallback(() => {
    queryParams.delete(QueryParamTypes.PAGE);
    setQueryParams(queryParams);
    dispatch(updateCurrentSorting({ pageId, sortData: undefined }));
    setTimeout(() => {
      refetch();
    }, 0);
  }, [refetch]);

  const readMore = () => {
    if (localPage < maxPages) {
      setQueryParams({ page: `${localPage + 1}` });
    }
  };

  const readPrev = () => {
    if (localPage > 0) {
      if (localPage === 1) {
        queryParams.delete(QueryParamTypes.PAGE);
        setQueryParams(queryParams);
      } else {
        setQueryParams({ page: `${localPage - 1}` });
      }
    }
  };

  return {
    content,
    localPage,
    hasMore: !last,
    readMore,
    refresh,
    refetch,
    isLoading,
    isFetching,
    readPrev,
    maxPages,
    totalElements,
  };
};

export default useFetchPaginationData;
