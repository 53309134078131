/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes';
import { useAppDispatch } from '@store/store';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { PAGE_SIZE, NotificationTypes } from '@constants/components';
import showNotification from '@utils/showNotification';
import useI18n from './useI18n';

const calculateMaxPages = (total: number, size: number) =>
  Math.ceil(total / size);

export interface IListQueryResponse {
  content: any[];
  totalPages: number;
  totalElements: number;
  pageable: {
    pageNumber: number;
  };
  last: boolean;
}

const useInfiniteScroll = (
  useGetDataListQuery: UseQuery<any>,
  { size = PAGE_SIZE, ...queryParameters }
) => {
  const { t } = useI18n(['common']);
  const [localPage, setLocalPage] = useState(0);
  const [combinedData, setCombinedData] = useState<any[]>([]);
  const dispatch = useAppDispatch();

  const { data, refetch, error, isLoading, isFetching, isError } =
    useGetDataListQuery({
      paginationInput: {
        size,
        page: localPage,
      },
      size,
      ...queryParameters,
    });
  const {
    content,
    totalElements,
    pageable = {
      pageNumber: 0,
    },
    last = true,
  } = (data as IListQueryResponse) || {};

  useEffect(() => {
    if (error) {
      showNotification({
        message:
          (error as ErrorResponse)?.message ?? t('common:somethingWrong'),
        type: NotificationTypes.ERROR,
      });
    }
  }, [dispatch, error, t]);

  useEffect(() => {
    if (Array.isArray(content)) {
      if (localPage === 0) setCombinedData(content);
      else if (localPage === pageable.pageNumber) {
        setCombinedData((previousData: any) => [...previousData, ...content]);
      }
    }
  }, [content, localPage, pageable.pageNumber]);

  const maxPages = useMemo<number>(
    () => calculateMaxPages(totalElements, PAGE_SIZE),
    [totalElements]
  );

  const refresh = useCallback(() => {
    setLocalPage(0);
    refetch();
  }, [refetch]);

  const readMore = () => {
    if (localPage < maxPages && localPage === pageable.pageNumber) {
      setLocalPage(localPage + 1);
    }
  };

  // Using this boolean since there is a render cycle between getting the data and setting the combined data where isLoading and isFetching is false
  const isCombinedDataProcessing = (() => {
    if (isError) return false;
    if (last) return combinedData.length !== totalElements;
    return combinedData.length !== PAGE_SIZE * (localPage + 1);
  })();

  return {
    combinedData,
    localPage,
    hasMore: !last,
    readMore,
    refresh,
    isLoading: isLoading || isCombinedDataProcessing,
    isFetching: isFetching || isCombinedDataProcessing,
    totalElements,
  };
};

export default useInfiniteScroll;
