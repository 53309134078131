const RoutePaths = {
  ALL: '*',
  LOGIN: '/login',
  AUTH_FAILED: '/auth-failed',
  PROJECTS: {
    ROOT: '/projects',
    OVERVIEW: '/projects/:projectId/overview',
  },
  MILESTONES: {
    ROOT: '/projects/:projectId/milestones',
    ADD: '/projects/:projectId/milestones/add',
    EDIT: '/projects/:projectId/milestones/:milestoneId/edit',
    OVERVIEW: '/projects/:projectId/milestones/:milestoneId',
  },
  TEST_SUITE: {
    ROOT: '/projects/:projectId/test-suite',
    DETAILS: '/projects/:projectId/test-suite/:testCaseId',
    ADD: '/projects/:projectId/test-suite/add',
    EDIT: '/projects/:projectId/test-suite/:testCaseId/edit',
    IMPORT: '/projects/:projectId/test-suite/import',
    IMPORT_HISTORY: '/projects/:projectId/test-suite/importHistory',
  },
  TEST_RUNS: {
    ROOT: '/projects/:projectId/test-runs',
    ADD: '/projects/:projectId/test-runs/add',
    OVERVIEW: '/projects/:projectId/test-runs/:testRunId',
    EDIT: '/projects/:projectId/test-runs/:testRunId/edit',
  },
  DELIVERABLES: {
    ROOT: '/projects/:projectId/deliverables',
  },
  MEMBERS: {
    ROOT: '/members',
    USERS: '/members/users',
    ROLES: '/members/roles',
  },
  SETTINGS: '/settings',
};

export default RoutePaths;
