import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { FORMAT_TEXT_COMMAND, $getSelection, $isRangeSelection } from 'lexical';
import {
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
} from '@lexical/list';
import { mergeRegister, $getNearestNodeOfType } from '@lexical/utils';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { useCallback, useEffect, useState } from 'react';
import { AttachLink, Bold, BulletList, Italics, Underline } from '@icons';
import { createPortal } from 'react-dom';
import { TextAreaElements } from '@constants/components';
import Button from '../../button/Button';
import FloatingLinkEditor, {
  getSelectedNode,
} from '../components/FloatingLinkEditor';

const Toolbar = ({ editorId }: { editorId: string }) => {
  const [editor] = useLexicalComposerContext();
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderlined, setIsUnderlined] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [type, setType] = useState<TextAreaElements>(
    TextAreaElements.PARAGRAPH
  );

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderlined(selection.hasFormat('underline'));

      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode
          );
          const listType = parentList
            ? parentList.getListType()
            : element.getListType();
          if (listType === TextAreaElements.BULLET_LIST)
            setType(TextAreaElements.BULLET_LIST);
        } else {
          const elementType = element.getType();
          if (elementType === TextAreaElements.PARAGRAPH) setType(elementType);
        }
      }
    }
  }, [editor]);

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar();
          });
        })
      ),

    [updateToolbar, editor]
  );

  const el = document.getElementById(editorId);

  return (
    <div className='flex p-2 border-b border-light-lavender'>
      <Button
        handleClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
        }}
        className={`${
          isBold ? 'bg-light-lavender' : 'bg-transparent'
        } border-none p-2 hover:cursor-pointer rounded-sm mr-1`}
      >
        <Bold />
      </Button>
      <Button
        handleClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
        }}
        className={`${
          isItalic ? 'bg-light-lavender' : 'bg-transparent'
        } border-none p-2 hover:cursor-pointer rounded-sm mr-1`}
      >
        <Italics />
      </Button>
      <Button
        handleClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
        }}
        className={`${
          isUnderlined ? 'bg-light-lavender' : 'bg-transparent'
        } border-none p-2 hover:cursor-pointer rounded-sm mr-1`}
      >
        <Underline />
      </Button>
      <Button
        handleClick={() => {
          if (type !== TextAreaElements.BULLET_LIST) {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
          } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
          }
        }}
        className={`${
          type === TextAreaElements.BULLET_LIST
            ? 'bg-light-lavender'
            : 'bg-transparent'
        } border-none p-2 hover:cursor-pointer rounded-sm mr-1`}
      >
        <BulletList />
      </Button>
      <Button
        handleClick={insertLink}
        className={`${
          isLink ? 'bg-light-lavender' : 'bg-transparent'
        } border-none p-2 hover:cursor-pointer rounded-sm mr-1 hidden`}
      >
        <AttachLink />
      </Button>
      {isLink && el && createPortal(<FloatingLinkEditor editor={editor} />, el)}
    </div>
  );
};

export default Toolbar;
